import { useEffect, useState } from "react";
import { CanvaPage, LibraryImage, PixabayImage } from "../types";

export const usePixabaySearch = (
  profile_picture: any,
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
  uploadFiles: (files: File[]) => void,
  activeTab: "search" | "library" | "canvas",
  defaultSearch: string | null,
  query: string,
  selectedCanvaDesign: any | null,
  setQuery: React.Dispatch<React.SetStateAction<string>>,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setImages: React.Dispatch<React.SetStateAction<any[]>>,
  selectedCanvaPages: CanvaPage[],
  insertLogo: boolean,
  selectedImage: PixabayImage | LibraryImage | null,
  logoPosition:
    | "center"
    | "top-left"
    | "top-right"
    | "top-center"
    | "bottom-left"
    | "bottom-right"
    | "bottom-center"
    | "center-right"
    | "center-left",
  logoShape: "circle" | "rounded-square"
) => {
  const urlToFile = async (
    url: string,
    filename: string,
    mimeType: string
  ): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  };

  const getPublicIdFromUrl = (url: string) => {
    const regex = /\/upload\/(?:v\d+\/)?(.+)\.\w+$/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const uploadAndGetPublicId = async (file: File) => {
    const timestamp = Date.now().toString();
    const signatureResponse = await fetch(
      `/api/sign_cloudinary_upload?timestamp=${timestamp}&return_delete_token=true`
    );
    const { signature } = await signatureResponse.json();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", "883898914556696");
    formData.append("timestamp", timestamp);
    formData.append("return_delete_token", "true");
    formData.append("signature", signature);
    const uploadResponse = await fetch(
      `https://api.cloudinary.com/v1_1/dcmekntwa/upload`,
      {
        method: "POST",
        body: formData,
      }
    );
    const uploadResult = await uploadResponse.json();
    return uploadResult.public_id;
  };

  const insertLogoOnMedium = async (
    baseFile: File,
    options?: { video?: boolean }
  ) => {
    const resource_type = options?.video ? "video" : "image";
    const basePublicId = await uploadAndGetPublicId(baseFile);
    let logoPublicId =
      getPublicIdFromUrl(profile_picture) ||
      (await uploadAndGetPublicId(
        await urlToFile(profile_picture, "logo.png", "image/png")
      ));
    const gravityMap: Record<string, string> = {
      "top-left": "north_west",
      "top-right": "north_east",
      "top-center": "north",
      "bottom-left": "south_west",
      "bottom-right": "south_east",
      "bottom-center": "south",
      center: "center",
      "center-right": "east",
      "center-left": "west",
    };
    const radiusMap: Record<string, string> = {
      "rounded-square": "r_20",
      circle: "r_max",
    };
    const gravity = gravityMap[logoPosition];
    const radius = radiusMap[logoShape];
    const transformedUrl = `https://res.cloudinary.com/dcmekntwa/${resource_type}/upload/b_white,c_fill,r_20,fl_layer_apply,l_${logoPublicId},${radius},w_200,g_${gravity},x_10,y_10/${basePublicId}`;
    const response = await fetch(transformedUrl);
    const blob = await response.blob();
    return new File([blob], "combined_image");
  };

  const uploadSelectedImage = async () => {
    setIsSaving(true);
    try {
      // cas : design canva multi-pages
      if (selectedCanvaDesign && selectedCanvaPages.length > 1) {
        const finalFiles: File[] = [];
        for (const page of selectedCanvaPages) {
          const pageFile = await urlToFile(
            page.thumbnail.url,
            `canva_page_${page.page_index}.png`,
            "image/png"
          );
          let finalImageFile = pageFile;
          if (insertLogo) {
            finalImageFile = await insertLogoOnMedium(pageFile);
          }
          finalFiles.push(finalImageFile);
        }
        uploadFiles(finalFiles);
        return;
      }

      // image de la “library”
      if (!selectedImage) return;

      if ("public_id" in selectedImage) {
        console.log("selectedImage", selectedImage);
        // HACK: we need to replace http with https for ios
        const response = await fetch(
          selectedImage.url.replace("http://", "https://")
        );
        const blob = await response.blob();
        let baseFile = new File([blob], selectedImage.filename, {
          type: `image/${selectedImage.format}`,
        });
        if (insertLogo) {
          baseFile = await insertLogoOnMedium(baseFile, {
            video: selectedImage.resource_type == "video",
          });
        }
        uploadFiles([baseFile]);
        return;
      }

      // image de Pixabay
      if ("largeImageURL" in selectedImage) {
        let baseFile = await urlToFile(
          selectedImage.largeImageURL,
          "image.jpg",
          "image/jpeg"
        );
        if (insertLogo) {
          baseFile = await insertLogoOnMedium(baseFile);
        }
        uploadFiles([baseFile]);
        return;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const searchImages = async () => {
    const inputElement = document.querySelector(
      'input[name="search"]'
    ) as HTMLInputElement | null;
    const searchValue = inputElement?.value || defaultSearch;
    const apiKey = "44481271-e0dcc26e9803ee1deb1260df5";
    const url = `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(
      searchValue || ""
    )}&image_type=photo&per_page=20&page=1`;

    try {
      setLoading(true);
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.hits && data.hits.length > 0) {
        setImages(data.hits);
      } else {
        console.log("Aucune image trouvée pour la recherche :", searchValue); // Si aucune image n'est trouvée
      }

      setPage(1); // Réinitialisation de la page de la recherche
    } catch (error) {
      console.error("Error fetching Pixabay images", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreImages = async () => {
    const apiKey = "44481271-e0dcc26e9803ee1deb1260df5";
    const nextPage = page + 1;
    const url = `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(
      query
    )}&image_type=photo&per_page=20&page=${nextPage}`;
    try {
      setLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      setImages((prevImages) => [...prevImages, ...data.hits]);
      setPage(nextPage);
    } catch (error) {
      console.error("Error fetching more Pixabay images", error);
    } finally {
      setLoading(false);
    }
  };

  const getPositionClasses = (position: string) => {
    switch (position) {
      case "top-left":
        return "top-2 left-2";
      case "top-right":
        return "top-2 right-2";
      case "top-center":
        return "top-2 left-1/2 transform -translate-x-1/2";
      case "bottom-left":
        return "bottom-2 left-2";
      case "bottom-right":
        return "bottom-2 right-2";
      case "bottom-center":
        return "bottom-2 left-1/2 transform -translate-x-1/2";
      case "center":
        return "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2";
      case "center-right":
        return "top-1/2 right-0 transform -translate-y-1/2 -translate-x-1/2";
      case "center-left":
        return "top-1/2 left-0 transform -translate-y-1/2 translate-x-1/2";
      default:
        return "top-2 left-2";
    }
  };

  const getShapeClasses = (shape: string) => {
    switch (shape) {
      case "rounded-square":
        return "rounded-sm";
      case "circle":
        return "rounded-full";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (activeTab === "search") {
      if (defaultSearch) {
        setQuery(defaultSearch);
      }
      searchImages();
    }
  }, [activeTab]);

  return {
    uploadSelectedImage,
    searchImages,
    loadMoreImages,
    getPositionClasses,
    getShapeClasses,
  };
};
