import React from "react";
import { inputValues } from "../types";

type Props = {
  json: Object & { [key: string]: inputValues } | null;
}
const DynamicFormGenerator = ({ json }: Props) => {
  const renderInput = (key: string, value: inputValues, parentKey = null) => {
    const name = parentKey ? `${parentKey}[${key}]` : key as string;
    const type = typeof value;

    if (type === 'string') {
      return <input className="hidden" type="text" name={name} value={value as string} readOnly />;
    }
    if (type === 'number') {
      return <input className="hidden" type="number" name={name} value={value as number} readOnly />;
    }
    if (type === 'boolean') {
      return <input className="hidden" type="checkbox" name={name} checked={value as boolean} readOnly />;
    }
    if (Array.isArray(value)) {
      return (
        <div key={key}>
          {value.map((item, index) => (
            <div key={`${name}-${index}`}>
              {renderInput(`${name}[]`, item)}
            </div>
          ))}
        </div>
      );
    }
    if (type === 'object') {
      return renderNestedObject(value as Object, name);
    }
    return null;
  };

  const renderNestedObject = (nestedObject: any, parentKey: any) => {
    return (
      <div>
        {nestedObject && Object.keys(nestedObject).map((key: string) => (
          <div key={key} className="ml-4">
            {renderInput(key, nestedObject[key], parentKey)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {json && Object.keys(json).map((key) => (
        <div key={key}>
          <label>{key}</label>
          {renderInput(key, json[key])}
        </div>
      ))}
    </>
  )
}

export const HiddenInput = ({ name, value }: { name: string, value: inputValues }) => {
  const type = typeof value;

  if (type === 'string') {
    return <input className="hidden" type="text" name={name} value={value as string} readOnly />;
  }
  if (type === 'number') {
    return <input className="hidden" type="number" name={name} value={value as number} readOnly />;
  }
  if (type === 'boolean') {
    // On veut un false (0) ici si non coché, pas juste l'absence de params
    // donc on met un hidden field à 0, si le second est checked ça le remplace
    return <>
      <input className="hidden" type="hidden" name={name} value="0" readOnly />
      <input className="hidden" type="checkbox" name={name} value="1" checked={value as boolean} readOnly />
    </>
  }
  return null;
};

export default DynamicFormGenerator;
