import { CloudArrowDownIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import { getCSRFToken } from "./utils";
import {  useIntl , FormattedMessage } from "react-intl";


const DanimLoaderButton = () => {
  const authenticity_token = getCSRFToken();

  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchVids();
  }, []);

  const fetchVids = async () => {
    setLoading(true);
    const response = await fetch("/danim/get_videos", {
      method: "POST",
      headers: {
        "X-CSRF-Token": authenticity_token,
      },
    });
    if (response.status === 200) {
      setVideos(await response.json());
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const intl = useIntl()

  return (
    <div>
      <div className="relative flex justify-center w-full mb-2 text-sm">
        <small className="text-center text-gray-700">
          {!loading &&
            (videos.length > 0 ? (
              <>
                {videos.length === 1
                  ? `${videos.length} vidéo trouvée, retrouvez-la `
                  : `${videos.length} vidéos trouvées, retrouvez-les `}
                <a className="text-brand_main underline" href="/company_contents">
                  {" "}
                  <FormattedMessage id="danim_loader_button.in_your_library" defaultMessage="dans votre bibliothèque" />
                </a>
                .
              </>
            ) : (
              <FormattedMessage id="danim_loader_button.no_new_video" defaultMessage="Pas de nouvelle vidéo. Créez-en dans l'interface ci-dessous, puis récupérez-les dans votre bibliothèque via le bouton de gauche." />
            ))}
        </small>
        <div className="absolute flex flex-col h-12 space-y-2 -bottom-16 left-16">
        <button
          type="button"
          onClick={fetchVids}
          disabled={loading}
          className="w-52 shadow-sm rounded-md bg-indigo-600 py-2.5 px-2.5 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span className="mb-2 ">
            {!loading ? (
              <>
                <CloudArrowDownIcon className="inline w-6 h-6 mr-2" />
                <FormattedMessage id="danim_loader_button.get_videos" defaultMessage="Récupérer les vidéos" />
              </>
            ) : (
              <>
                <Spinner className="inline w-6 mr-2 text-white animate-spin" />
                <FormattedMessage id="danim_loader_button.get_videos_in_progess" defaultMessage="Récupération en cours" />
              </>
            )}
          </span>
        </button>
      </div>
      </div>
      
      
    </div>
  );
};
export default DanimLoaderButton;
