import { useEffect, useState } from "react";
import { isSmallDevices } from "../utils/various";

const isServer = typeof window === 'undefined';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isSmallDevices);
  const handleWindowSizeChange = () => {
    setIsMobile(isSmallDevices);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};