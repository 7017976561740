import { useEffect, useState } from "react";
import { getCSRFToken } from "../components/utils";

interface ChatGPTResponse {
  text: string; // Ajuste selon la structure exacte de la réponse de l'API
}

interface UseChatGPTResult {
  response: ChatGPTResponse | null;
  isWriting: boolean;
  error: string | null;
  fetchChatGPTResponse: (prompt: string) => Promise<void>;
}

const useChatGPT = ({ apiRoute }: { apiRoute: string }): UseChatGPTResult => {
  const [value, setValue] = useState<string>("");
  const [response, setResponse] = useState<ChatGPTResponse>({ text: "" });
  const [isWriting, setIsWriting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const simulateTyping = (fullText: string, index = 0, callback: () => void) => {
    if (index < fullText.length) {
      // On découpe le texte tous les 10 caractères
      const nextChars = fullText.slice(index, index + 10);
      setResponse(prev => ({ text: prev?.text + nextChars }));
      setTimeout(() => {
        simulateTyping(fullText, index + 10, callback);
      }, 100);
    } else {
      callback();
      setResponse({ text: "" });
    }
  };
  const fetchChatGPTResponse = async (prompt: string): Promise<void> => {
    setIsWriting(true);
    setError(null);

    try {
      const res = await fetch(apiRoute, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCSRFToken(),
        },
        body: JSON.stringify({
          prompt: prompt,
        }),
      });

      if (!res.ok) {
        throw new Error('Erreur lors de la récupération des données');
      }
      const data: ChatGPTResponse = await res.json();
      // setResponse(data);
      setValue(data.answer);
    } catch (err: any) {
      setError(err.message || 'Une erreur est survenue');
    } finally {
      setIsWriting(true);
    }
  };

  // Si la requête a aboutie, on installe la valeur de réponse dans Value (texte entier)
  useEffect(() => {
    if (value) {
      simulateTyping(value, 0, () => setIsWriting(false));
    }
  }, [value])

  // Une fois que la réponse a été donnée et que on "n'écrit" plus, on réinitialise
  useEffect(() => {
    if (!isWriting) {
      setResponse({ text: "" });
      setValue("");
    }
  }, [isWriting])


  return { response, isWriting, error, fetchChatGPTResponse };
};

export default useChatGPT;
