import React, { ForwardedRef, MutableRefObject, useRef } from "react";
import {
  CameraIcon,
  PlayIcon,
  LinkIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import {
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form/dist/types";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  register: UseFormRegister<any>;
  selected: string;
  onSelectCallback?: (e:any) => void;
  inputName: string;
  videoPresent?: boolean; //validationProps
  imagesPresent?: boolean; //validationProps
};

const FocusSelector = ({
  inputName,
  register,
  selected,
  onSelectCallback,
  videoPresent,
  imagesPresent,
}: Props) => {
  const intl = useIntl()
  const options = {
    image: {
      // use intl => hook et non component
      label: intl.formatMessage({id:"focus_selector.image", defaultMessage:"Image"}),
      IconComponent: CameraIcon,
    },
    video: { label: intl.formatMessage({id:"focus_selector.video", defaultMessage:"Video"}), IconComponent: PlayIcon },
    link: { label: intl.formatMessage({id:"focus_selector.lien", defaultMessage:"Lien"}), IconComponent: LinkIcon },
    images: { label: intl.formatMessage({id:"focus_selector.multi_images", defaultMessage:"Multi-images"}), IconComponent: RectangleGroupIcon },
  };

  return (
    <div className="space-x-4 flex  flex-wrap">
      {Object.entries(options).map((kv) => {
        const [value, { IconComponent, label }] = kv;
        const { onChange, ...registredProps } = register(inputName, {
          required: "Vous devez sélectionner un format.",
          validate: (value: string | undefined) => {
            if (value === "video" && !videoPresent) {
              return "En selectionnant l'expérience vidéo, vous devez uploader une vidéo.";
            } else if (value !== "video" && videoPresent) {
              return "Vous ne pouvez pas poster de vidéo en dehors de l'expérience vidéo";
            } else if (value === "images" && !imagesPresent) {
              return "Vous devez ajouter des images pour l'expérience multi-images";
            }
            return true;
          },
        });
        const handleChange = (e: any) => {
          onChange(e);
          onSelectCallback && onSelectCallback(e);
        };
        return (
          <label key={value}>
            <input
              className="hidden"
              type="radio"
              value={value}
              onChange={handleChange}
              {...registredProps}
            />
            <div className="my-2 flex items-center relative">
              <div
                // onClick={() => setFocus(value)}
                className={`relative border border-gray-300 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none ${
                  selected == value
                    ? "text-brand_main border-brand_lighter ring ring-brand_lighter ring-opacity-50"
                    : "text-gray-500"
                }`}
              >
                <IconComponent className="h-6 w-6 mr-1" />
                {label}
              </div>
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default FocusSelector;
