import React from "react";

const ImagePlaceholder = ({ animationProgress, color = "bg-green-400", index }: { animationProgress: number, color?: string, index?: number }) => {
  return (
    <div className="w-24 h-24 relative bg-transparent rounded-md overflow-hidden flex items-center justify-center" key={`placeholder_image_${index}`}>
  {/* Mask */}
    <div className="w-[94px] h-[94px] bg-gray-200 z-[3] rounded-[5px] border-2 border-white" />
    {/* Top border */}
    <div
      className={`absolute inset-0 h-[6px] ${color} rounded-sm transition-all duration-300 ease-linear`}
      style={{
        width: `${animationProgress >= 25 ? 100 : (animationProgress / 25) * 100}%`,
      }}
    />
    {/* Right border */}
    <div
      className={`absolute top-0 right-0 w-[8px] ${color} rounded-sm transition-all duration-300 ease-linear`}
      style={{
        height: `${animationProgress >= 50 ? 100 : animationProgress > 25 ? ((animationProgress - 25) / 25) * 100 : 0}%`,
      }}
    />
    {/* Bottom border */}
    <div
      className={`absolute bottom-0 right-0 h-[8px] ${color} rounded-sm transition-all duration-300 ease-linear`}
      style={{
        width: `${animationProgress >= 75 ? 100 : animationProgress > 50 ? ((animationProgress - 50) / 25) * 100 : 0}%`,
      }}
    />
    {/* Left border */}
    <div
      className={`absolute bottom-0 left-0 w-[8px] ${color} rounded-sm transition-all duration-300 ease-linear`}
      style={{
        height: `${animationProgress === 100 ? 100 : animationProgress > 75 ? ((animationProgress - 75) / 25) * 100 : 0}%`,
      }}
    />
  </div>
  );
}

export default ImagePlaceholder;