import { Controller } from "@hotwired/stimulus"
console.log('Hello, StimulusOOOO!')

export default class extends Controller {

  connect() {
    console.log("Hello, Body Controller!")
    this.initializeStonlyWidget();
  }

  initializeStonlyWidget() {
    const scriptSrc = 'https://stonly.com/js/widget/v2/stonly-widget.js';

    if (document.head.innerHTML.includes(scriptSrc)) {
      const existingScripts = document.querySelectorAll(`script[src^="${scriptSrc}"]`);
      existingScripts.forEach(script => script.remove());
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = scriptSrc + '?v=' + Date.now();
    document.head.appendChild(script);
    script.onload = () => {
      if (typeof window.StonlyWidget === 'function') {
        window.StonlyWidget('init', { widgetId: window.STONLY_WID });
      }
    };
  }
}
