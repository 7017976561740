import React, { InputHTMLAttributes, TextareaHTMLAttributes, useEffect } from 'react'
import FormField from './FormField'


interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  title?: string,
  label?: string,
  hint?: string,
  error?: string,
  readonly?: boolean
} 


const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({title, label, hint, error, readonly, disabled, ...rest}, ref) => {

  return (
    <FormField
    title={title}
    hint={hint}
    disabled={disabled}
    error={error}
  >
    <textarea
    ref={ref}
      disabled={readonly}
      className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${
        error
          ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
          : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
      }`}
      {...rest}
    />
  </FormField>  )
});

export default TextArea