import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOM from "react-dom";
import DanimLoaderButton from "../components/DanimLoaderButton";
import { IntlProvider } from "react-intl";
import fr from "../../../config/locales/js-lang/fr.json";
import en from "../../../config/locales/js-lang/en.json"; // on mettra le compile plus tard

export default class extends Controller {
  // get props() {
  //   return JSON.parse(this.data.get("props"));
  // }
  get props() {
    return JSON.parse(this.data.get("props"));
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element);
  }

  initialize() {}

  connect() {
    const messages = { fr, en };

    ReactDOM.render(
      <IntlProvider
        locale={this.props.locale}
        messages={this.props.locale ? messages[this.props.locale] : fr}
      >
        <DanimLoaderButton {...this.props} />
      </IntlProvider>,
      this.element
    );
  }
}
