import React from "react"

export const ContentMediumIcon = ({ className }: { className: string }) => {
  return (
    <svg className={className} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.6666 21.1633C36.6633 23.6133 36.645 25.6933 36.5066 27.4017C36.345 29.385 36.0133 31.0417 35.2733 32.4167C34.9504 33.0195 34.5404 33.5716 34.0566 34.055C32.6683 35.4433 30.9016 36.0717 28.6616 36.3717C26.4733 36.6667 23.6666 36.6667 20.0883 36.6667H19.9116C16.3316 36.6667 13.5283 36.6667 11.3383 36.3717C9.09998 36.0717 7.33165 35.4433 5.94498 34.055C4.71498 32.825 4.07831 31.295 3.74165 29.3967C3.40831 27.53 3.34831 25.21 3.33665 22.3283C3.33331 21.595 3.33331 20.82 3.33331 20V19.91C3.33331 16.33 3.33331 13.5267 3.62831 11.3367C3.92831 9.09833 4.55665 7.33 5.94498 5.94333C7.33165 4.555 9.09998 3.92667 11.3383 3.62667C13.285 3.365 15.7883 3.335 18.8366 3.33167C19.1452 3.33167 19.4411 3.45423 19.6592 3.6724C19.8774 3.89057 20 4.18647 20 4.495C20 4.80354 19.8774 5.09943 19.6592 5.3176C19.4411 5.53577 19.1452 5.65833 18.8366 5.65833C15.745 5.66167 13.4466 5.68833 11.6483 5.93C9.66665 6.19667 8.47165 6.70333 7.58831 7.58667C6.70498 8.47 6.19998 9.66667 5.93331 11.65C5.66165 13.6667 5.65831 16.3133 5.65831 20V21.3067L7.21165 19.95C7.89253 19.3543 8.77435 19.0396 9.67856 19.0696C10.5828 19.0996 11.4418 19.4721 12.0816 20.1117L18.7316 26.7617C19.2476 27.2779 19.9291 27.5956 20.6563 27.6587C21.3834 27.7218 22.1095 27.5263 22.7066 27.1067L23.17 26.7817C24.0313 26.1763 25.0725 25.8811 26.1234 25.9444C27.1742 26.0076 28.1725 26.4256 28.955 27.13L33.3433 31.08C33.785 30.1517 34.0483 28.9333 34.1883 27.2133C34.32 25.5933 34.3383 23.6267 34.34 21.1633C34.34 20.8548 34.4625 20.5589 34.6807 20.3407C34.8989 20.1226 35.1948 20 35.5033 20C35.8118 20 36.1077 20.1226 36.3259 20.3407C36.5441 20.5589 36.6666 20.8548 36.6666 21.1633Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.1666 18.3333C25.6316 18.3333 23.8633 18.3333 22.765 17.235C21.6666 16.1367 21.6666 14.3683 21.6666 10.8333C21.6666 7.29833 21.6666 5.53 22.765 4.43167C23.8633 3.33333 25.6316 3.33333 29.1666 3.33333C32.7016 3.33333 34.47 3.33333 35.5683 4.43167C36.6666 5.53 36.6666 7.29833 36.6666 10.8333C36.6666 14.3683 36.6666 16.1367 35.5683 17.235C34.47 18.3333 32.7016 18.3333 29.1666 18.3333ZM32.55 9.11667L30.05 6.61667C29.8156 6.38258 29.4979 6.2511 29.1666 6.2511C28.8354 6.2511 28.5177 6.38258 28.2833 6.61667L25.7833 9.11667C25.5625 9.35363 25.4423 9.66704 25.448 9.99087C25.4537 10.3147 25.5849 10.6237 25.8139 10.8527C26.0429 11.0817 26.3519 11.2129 26.6758 11.2186C26.9996 11.2243 27.313 11.1041 27.55 10.8833L27.9166 10.5167V14.1667C27.9166 14.4982 28.0483 14.8161 28.2827 15.0505C28.5172 15.285 28.8351 15.4167 29.1666 15.4167C29.4981 15.4167 29.8161 15.285 30.0505 15.0505C30.2849 14.8161 30.4166 14.4982 30.4166 14.1667V10.5167L30.7833 10.8833C30.8977 11.0061 31.0357 11.1046 31.1891 11.173C31.3424 11.2413 31.5079 11.278 31.6758 11.281C31.8436 11.2839 32.0103 11.2531 32.166 11.1902C32.3216 11.1273 32.463 11.0338 32.5817 10.9151C32.7004 10.7964 32.794 10.655 32.8568 10.4993C32.9197 10.3437 32.9506 10.177 32.9476 10.0091C32.9446 9.84129 32.9079 9.67577 32.8396 9.52244C32.7713 9.3691 32.6728 9.2311 32.55 9.11667Z" />
    </svg>
  )
}

export const FileIcon = ({ className = "" }: { className: string }) => {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="none"
      viewBox="0 0 48 48"
      aria-hidden="true"
    >
      <path
        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
