import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["container", "closeButton"];

  connect() {
    this.opened = false;
    useClickOutside(this)
  }

  open() {
    if (this.opened) return
    this.opened = true
    this.containerTarget.classList.remove("translate-x-full");
    this.closeButtonTarget.classList.remove("hidden");
  }

  close() {
    if (!this.opened) return
    this.opened = false
    this.containerTarget.classList.add("translate-x-full");
    this.closeButtonTarget.classList.add("hidden")
  }

  clickOutside(event) {
    if (this.opened) {
      this.close()
      event.stopPropagation()
    }
  }
}
