import { CanvaPage } from "../types";

export const useCanva = (
    canvaSessionState: any,
    setFoldersLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCanvaSessionState: React.Dispatch<any>,
    setRefreshTokenRevokedMessage: React.Dispatch<React.SetStateAction<string | null>>,
    setFolders: React.Dispatch<React.SetStateAction<any[]>>,
    setSelectedFolder: React.Dispatch<React.SetStateAction<number | null>>,
    setFolderContinuation: React.Dispatch<React.SetStateAction<string | null>>,
    setSelectedCanvaPages: React.Dispatch<React.SetStateAction<CanvaPage[]>>,
    setSelectedFolderItems: React.Dispatch<React.SetStateAction<any[]>>
) => {

    const refreshAccessToken = async () => {
        try {      
          const response = await fetch("/canva/refresh");
          if (response.ok) {
            const data = await response.json();         
            setCanvaSessionState((prev: any) => ({ ...prev, access_token: data.access_token }));        
          } else {
            console.error("Failed to refresh Canva access token");
            setRefreshTokenRevokedMessage("Votre session Canva a expiré. Veuillez reconnecter votre compte.");
          }
        } catch (error) {
          console.error("Error refreshing access token:", error);
        }
      };
    

    const fetchRootFolders = async () => {
        setFoldersLoading(true);
        try {      
          const response = await fetch(
            "https://api.canva.com/rest/v1/folders/root/items?item_types=folder",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${canvaSessionState.access_token}`,
              },
            }
          );
          if (response.status === 401) {
            await refreshAccessToken();
          }
          const data = await response.json();
          setFolders(data.items || []);
      
          if (data.items && data.items.length > 0) {
            const firstItem = data.items[0];
            if (firstItem.type === "folder" && firstItem.folder?.id) {
              fetchFolderItems(firstItem.folder.id);
              setSelectedFolder(firstItem.folder.id);
            }
          }
      
          setFolderContinuation(data.continuation || null);
        } catch (error) {
          console.error("Error fetching root folders:", error);
        } finally {
          setFoldersLoading(false);
        }
      };
      const fetchFolderItems = async (folderId: string) => {
        try {      
          const response = await fetch(
            `https://api.canva.com/rest/v1/folders/${folderId}/items?item_types=design`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${canvaSessionState.access_token}`,
              },
            }
          );
          if (response.status === 401) {
            await refreshAccessToken();
          }
          const data = await response.json();
          setSelectedFolderItems(data.items || []);
          setFolderContinuation(data.continuation || null);
        } catch (error) {
          console.error("Error fetching folder items:", error);
        } 
      };
      const fetchDesignPages = async (designId: string) => {
        try {
          const response = await fetch(
            `https://api.canva.com/rest/v1/designs/${designId}/pages?limit=50`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${canvaSessionState.access_token}`,
              },
            }
          );
          const data = await response.json();
          setSelectedCanvaPages(data.items || []);
        } catch (error) {
          console.error("Error fetching design pages:", error);
        }
      };
    
    return {
        fetchRootFolders,
        fetchFolderItems,
        fetchDesignPages
    }
}