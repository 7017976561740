import { Controller } from "@hotwired/stimulus";
import { createRoot, Root } from "react-dom/client";
import { formatMediaHolders } from "../components/utils";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { formatMedia } from "../components/utils";
import fr from "../../../config/locales/js-lang/fr.json";
import ContentMediaUploader from "../components/ContentMediaUploader";


// Ce controller sert à utiliser l'uploader react hors des formulaires React
// TODO: à un moment il faudra refacto

export default class extends Controller {

  get props() {    
    return JSON.parse(this.data.get("props"))
  }

  disconnect() {
    this.root?.unmount();
  }

  connect() {

    this.root = createRoot(this.element);
    this.logo = JSON.parse(this.data.get("logo"));
    this.root.render(
      <CloudinaryStimulusWrapper baseMedia={this.props.media} fieldName={this.props.field_name} logo={this.logo} />
    );
  }
}

const CloudinaryStimulusWrapper = ({ baseMedia, fieldName, logo }) => {
  const [media, setMedia] = useState(() => {
    const initialMedia = baseMedia ? [...baseMedia] : [];
    if (logo) {
      initialMedia.push({
        content_medium: {
          serialized_file: logo,
        },
      });
    }
    return initialMedia;
  });  
  const formattedMedia = formatMediaHolders(media);  
  
  useEffect(() => {
    // When media changes, update the hidden fields
    const hiddenFieldsDiv = document.getElementById(`hidden-fields-${fieldName}`);
    if (hiddenFieldsDiv) {
      hiddenFieldsDiv.innerHTML = ''; 
      
      media.forEach((med: any, i: number) => {
        const secureUrl = med.content_medium?.serialized_file?.secure_url;
        if (secureUrl) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = `${fieldName}`;
          input.value = secureUrl;
          hiddenFieldsDiv.appendChild(input);
        }
      });
    }
  }, [media, fieldName]);   

  return (
    <div>
      <div id={`hidden-fields-${fieldName}`}></div>
      <IntlProvider locale="fr" messages={fr}>
        <ContentMediaUploader media={media} setMedia={setMedia} />
      </IntlProvider>
    </div>
  );
};
