import React, { ReactNode, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { request, gql, GraphQLClient } from "graphql-request";
import ChannelSelector from "./ChannelSelector";
import ContentMediaUploader from "./ContentMediaUploader";
import FacebookPreview from "./FacebookPreview";
import FocusSelector from "./FocusSelector";
import InsertableTextInput from "./InsertableTextInput";
import Select from "./Select";
import TagSelector from "./TagSelector";
import { getCSRFToken, formatMediaHolders } from "./utils";
import { BoltIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import {
  Channel,
  ContentMedium,
  isPersisted,
  PostGenerator,
  TagGroup,
  MediaHolder
} from "../types";
import DatePicker from "./DatePicker";
import FormField from "./FormField";
import UrlScraper from "./UrlScraper";
import FormLayout from "./FormLayout";
import Input from "./Input";
import TextArea from "./TextArea";
import Spinner from "./Spinner";
import { FormattedMessage, useIntl } from 'react-intl';
import ChoiceButton from "./ChoiceButton";
import { useOpenAI } from "../hook/useOpenAI";
import { useUserChoice } from "../hook/useUserChoice";
import OpenAiForm from "./OpenAiForm";


type Props = {
  post_generator: PostGenerator;
  tag_groups: TagGroup[];
  example_account: {
    displayed_name: string;
    picture_url: string;
    company: {
      email: string;
      company: string;
      phone: string;
      hashtag: string;
      website: string;
      full_name: string;
      client_code: string;
    };
  };
  channels: Channel[];
  content_dispenser?: { id: number };
  read_only?: boolean;
  periodicity_options: Record<string, string>;
  current_company: any;
};

export default function PostGeneratorForm(props: Props) {
  const minDate = new Date();
  const maxDate = new Date();
  const defaultStartingAt = new Date();
  minDate.setMinutes(minDate.getMinutes() + 5);
  maxDate.setMonth(maxDate.getMonth() + 2);
  defaultStartingAt.setHours(defaultStartingAt.getHours() + 1);
  const [submitted, setSubmitted] = useState(false)
  const [prompt, setPrompt] = useState<string>("");
  const [showInputMessage, setShowInputMessage] = useState<boolean | null>(false);
  const [loading, setLoading] = useState(false);
  const { content, ...post_generator } = props.post_generator;
  const { body = { body_media: [] }, ...content_attributes } = content;
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      post_generator: {
        ...post_generator,
        starting_at: post_generator.starting_at
          ? new Date(post_generator.starting_at)
          : null,
        channel_ids: post_generator.channel_ids.map((c) => c.toString()), // select only giving strings
        content_attributes,
      },
    },
  });

  // content_media.map((cm, i) => ({...cm, position: i})) pour ajouter les positions de force, mais ptet pas besoin
  const [media, setMedia] = useState(
    body.body_media
      .filter((m: MediaHolder) => m.content_medium.serialized_file)
      .sort(
        (a: MediaHolder, b: MediaHolder) =>
          (a.position || 0) - (b.position || 0)
        // Si undefined on met la valeur max (media.length)
      ) || []
  );

  const ctaOptions = [
    { label: "Commander", value: "order_now" },
    { label: "Acheter", value: "shop_now" },
    { label: "En savoir plus", value: "learn_more" },
    { label: "S'inscrire", value: "sign_up" },
    { label: "Appeler", value: "call_now" },
    { label: "Nous contacter", value: "contact_us" },
  ];

  const periodicityOptions = Object.entries(props.periodicity_options).map(
    ([key, value]) => ({ label: value, value: key })
  ).filter(opt => opt.value !== 'publish_now');

  const readonly = Boolean(props.read_only);

  const graphQLClient = new GraphQLClient('/graphql/', { headers: { "X-CSRF-Token": getCSRFToken() } })

  const onSubmit = async (data: any) => {
    setSubmitted(true)



    const bodyMedia = formatMediaHolders(media)


    const mutation = gql`
    mutation ($postGenerator: PostGenerator${post_generator.id ? 'Update' : ''}Input!) {
      ${post_generator.id ? 'update' : 'create'}PostGenerator(input: {postGenerator:$postGenerator}) {
        postGenerator {
          content {
            body {
              id
              message
              contentMedia {
                medium
              }
            }
          }
        }
      }
    }`

    const variables = {
      postGenerator: {
        content: {
          id: data.post_generator.content_attributes.id || undefined,
          ...(data.post_generator.content_attributes.tag_ids ? { tagIds: data.post_generator.content_attributes.tag_ids } : {}),
          focus: data.post_generator.content_attributes.focus,
          expiresAt: data.post_generator.content_attributes.expires_at,
          title: data.post_generator.content_attributes.title,
          reconstructedUrl: data.post_generator.content_attributes.reconstructed_url,
          body: {
            //...("id" in body && { id: body.id }),
            message: data.post_generator.content_attributes.teaser,
            linkCta: data.post_generator.content_attributes.cta,
            linkUrl: data.post_generator.content_attributes.reconstructed_url,
            linkDescription: data.post_generator.content_attributes.description,
            bodyMediaAttributes: bodyMedia
          }
        },
        periodicity: data.post_generator.periodicity,
        startingAt: data.post_generator.starting_at,
        channelIds: data.post_generator.channel_ids,
        ...(props.content_dispenser && !data.post_generator.id ? { author: { id: props.content_dispenser.id, type: "ContentDispenser" } } : {}),
        ...(data.post_generator.id ? { id: data.post_generator.id } : {}),
      }
    }

    const response: any = await graphQLClient.request(mutation, variables)
    if (!response.errors) {
      window.Turbo?.visit(
        props.content_dispenser
          ? `/${intl.locale}/content_dispensers/${props.content_dispenser.id}/post_generators`
          : `/${intl.locale}/posts?display=calendar`
      );
    }
    setSubmitted(false)
  };

  const duplicateWithOwnership = async () => {
    const { id } = content;
    if (id) {
      const response = await fetch(`/contents/${id}/duplicate_with_ownership`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": getCSRFToken(),
        },
      });
      if (response.ok) {
        const newContent = await response.json();
        window.Turbo?.visit(
          `/post_generators/new?instantiate_from[type]=content&instantiate_from[id]=${newContent.id}`
        );
      }
    }
  };

  const focus = watch("post_generator.content_attributes.focus");
  const channel_ids = watch("post_generator.channel_ids");
  const teaser = watch("post_generator.content_attributes.teaser");
  const description = watch("post_generator.content_attributes.description");
  const reconstructed_url = watch(
    "post_generator.content_attributes.reconstructed_url"
  );
  const previewCta = ctaOptions.find(
    (cta) => cta.value === watch("post_generator.content_attributes.cta")
  )?.label;

  const videoPresent =
    media.filter((m) => m.content_medium.serialized_file.resource_type === "video").length > 0;
  const imagesPresent =
    media.filter((m) => m.content_medium.serialized_file.resource_type === "image").length > 0;

  const intl = useIntl()

  const [selectedOption, setSelectedOption] = useState('periodicity_planification');
  const [previousPeriodicity, setPreviousPeriodicity] = useState(post_generator.periodicity)
  const [previousStartingAt, setPreviousStartingAt] = useState(post_generator.starting_at)


  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const generateFocusHint = () => {
    switch (focus) {
      case "images":
        return "Limites au nombre d'images -> Facebook/Instagram: 10, Linkedin: 9, Pinterest: 5, Twitter: 4, Google: 1."
      case "image":
        return "⚠️ Image obligatoire pour publier sur Instagram et Pinterest"
      default:
        return "";
    }
  }

  const { SubmitOpenAI, disabledInput, error } = useOpenAI(
    prompt,
    loading,
    setLoading,
    media.map((m) => m.content_medium),
    setValue,
    setShowInputMessage,
    getValues,
    "post_generator.content_attributes.teaser",
    `/body/openai_call`
  );

  const { handleAIButtonClick, handleManualButtonClick } = useUserChoice(setShowInputMessage);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        title={intl.formatMessage({ id: "post_generator_form.publication", defaultMessage: "Publication" })}
        description={intl.formatMessage({ id: "post_generator_form.edit_content", defaultMessage: "Éditez la forme et le contenu de votre nouvelle campagne" })}
        leftSideElements={
          <>
            {props.read_only && (
              <div>
                <h3 className="flex items-baseline text-sm font-semibold leading-6 text-yellow-700">
                  <ExclamationTriangleIcon className="w-3 h-3 mr-1" />
                  Important
                </h3>
                <div className="p-2 text-sm text-yellow-700 bg-yellow-100 border-l-4 border-yellow-500">
                  <p>
                    <FormattedMessage id="post_generator_form.not_editable" defaultMessage="Le contenu de votre publication n'est pas éditable car il ne
                    vous appartient pas. Si vous désirez le changer, vous pouvez
                    le dupliquer." />
                  </p>
                  <div className="mt-4 text-right">
                    <button
                      type="button"
                      className="px-4 py-2 font-semibold text-yellow-700 bg-transparent border border-yellow-500 rounded hover:bg-yellow-700 hover:text-white hover:border-transparent"
                      onClick={duplicateWithOwnership}
                    >
                      <FormattedMessage id="post_generator_form.duplicate" defaultMessage="Dupliquer" />
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div>
              <FacebookPreview
                focus={focus}
                teaser={teaser}
                cta={previewCta}
                description={description}
                reconstructed_url={reconstructed_url}
                exampleAccount={props.example_account}
                media={media.filter((m) => !(isPersisted(m) && m.destroy)).map((m) => m.content_medium)}
              />
            </div>
            {!content_attributes.id && (
              <div>
                <UrlScraper
                  creationUrl={`/${props.content_dispenser
                    ? `content_dispensers/${props.content_dispenser.id}/`
                    : ""
                    }contents`}
                  redirectionCallback={(id) =>
                    `/${props.content_dispenser
                      ? `content_dispensers/${props.content_dispenser.id}/`
                      : ""
                    }post_generators/new?instantiate_from[type]=content&instantiate_from[id]=${id}`
                  }
                />
              </div>
            )}
            <div className="hidden lg:block">
              <h3 className="flex items-baseline text-sm font-semibold leading-6 text-gray-700">
                <BoltIcon className="w-3 h-3 mr-1" />
                <FormattedMessage id="post_generator_form.tips" defaultMessage="Astuce" />
              </h3>
              <p className="text-sm text-gray-500">
                <FormattedMessage id="post_generator_form.key_words" defaultMessage="Utilisez les mots clés" />{" "}
                {[
                  "$NOM",
                  "$ENTREPRISE",
                  "$SITE",
                  "$MAIL",
                  "$TEL",
                  "$HASHTAG",
                  "$CODE"
                ].map((v) => (
                  <span key={v}>
                    <span className="font-medium text-gray-700">{v}</span>
                    {", "}
                  </span>
                ))}
                <FormattedMessage id="post_generator_form.custom_your_publications" defaultMessage="pour personnaliser vos publications avec les valeurs correspondantes chez chaque affilié." />
              </p>
            </div>
          </>
        }
      >
        <FormField
          title={intl.formatMessage({ id: "post_generator_form.format", defaultMessage: "Format" })}
          hint={intl.formatMessage({ id: "post_generator_form.hint", defaultMessage: "Décide de l'élément sur lequel l'accent sera mis. Selon les plateformes, cela affectera la présentation de votre campagne. Seul le format vidéo accepte les vidéos." })}
        >
          <FocusSelector
            inputName="post_generator.content_attributes.focus"
            register={register}
            selected={focus}
            videoPresent={videoPresent}
            imagesPresent={imagesPresent}
            onSelectCallback={async () =>
              isSubmitted
                ? await trigger(
                  "post_generator.content_attributes.reconstructed_url"
                )
                : null
            }
          />
        </FormField>

        <Input
          title={intl.formatMessage({ id: "post_generator_form.title", defaultMessage: "Titre" })}
          hint={intl.formatMessage({ id: "post_generator_form.title_hint", defaultMessage: "Le titre de votre publication." })}
          readonly={readonly}
          {...register("post_generator.content_attributes.title", {
            required: intl.formatMessage({ id: "post_generator_form.title_cant_be_blank", defaultMessage: "Le titre ne peut pas être vide" }),
          })}
          error={errors?.post_generator?.content_attributes?.title?.message}
        />
        <div>
          <div>
            <ChoiceButton
              showInputMessage={showInputMessage}
              handleAiButtonClick={handleAIButtonClick}
              handleManualButtonClick={handleManualButtonClick}
            />
          </div>
        </div>
        {
          showInputMessage === true ?
            (
              <div>
                <OpenAiForm
                  prompt={prompt}
                  message="Entrez le sujet de votre post pour générer du texte, donner du contexte sur le message que vous souhaitez générer (limite: 30 par mois)"
                  placeholder="Exemple: 'Promouvoir un nouveau partenariat avec une entreprise'"
                  setPrompt={setPrompt}
                  loading={disabledInput}
                  error={error}
                  Submit={SubmitOpenAI}
                />
              </div>
            ) : (
              <FormField
                title={intl.formatMessage({ id: "post_generator_form.message", defaultMessage: "Message" })}
                hint={intl.formatMessage({ id: "post_generator_form.associated_text", defaultMessage: "Le texte associé à votre contenu." })}
                error={
                  errors?.post_generator?.content_attributes?.teaser?.message as
                  | string
                  | undefined
                }
              >
                <InsertableTextInput
                  disabled={readonly}
                  {...register("post_generator.content_attributes.teaser", {
                    required: intl.formatMessage({ id: "post_generator_form.message_cant_be_blank", defaultMessage: "Le message ne peut pas être vide" }),

                  })}
                  onInsertionCallback={() =>
                    isSubmitted
                      ? trigger("post_generator.content_attributes.teaser")
                      : null
                  }
                  rows={5}
                  inputType="textarea"
                  emojis={true}
                  setValue={setValue}
                  insertionVariables={[
                    "$NOM",
                    "$ENTREPRISE",
                    "$SITE",
                    "$MAIL",
                    "$TEL",
                    "$HASHTAG",
                    "$CODE",
                    "$PROMOCODE",
                    "$SPONSORLINK",
                  ]}
                  className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.post_generator?.content_attributes?.teaser
                    ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                    : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                    }`}
                />
              </FormField>
            )
        }


        {/*
        <TextArea
          title={intl.formatMessage({ id: "post_generator_form.short_description", defaultMessage: "Courte description" })}
          rows={2}
          readonly={readonly}
          {...register("post_generator.content_attributes.description")}
          error={
            errors?.post_generator?.content_attributes?.description?.message
          }
        />
        */}

        <FormField
          title={intl.formatMessage({ id: "post_generator_form.media", defaultMessage: "Média" })}
          error={errors?.post_generator?.content_attributes?.focus?.message}
          hint={generateFocusHint()}
        >
          {/* On prend les erreurs de focus car elles concernent les media */}
          <ContentMediaUploader
            inputProps={{
              multiple: true,
              accept: focus === "video" ? "video/*" : "image/*",
            }}
            innerInfos={focus === "video" ? <FormattedMessage id="post_generator_form.video_format" defaultMessage="MP4, WEBM" /> : <FormattedMessage id="post_generator_form.file_format" defaultMessage="PNG, JPG jusqu'à 10MB" />}
            disabled={readonly}
            setMedia={(m) => {
              setMedia(m);
              // On trigger le focus pour revalider les changements,
              // en async sinon pas le temps de recevoir l'info du media
              setTimeout(() => {
                trigger("post_generator.content_attributes.focus");
              }, 500);
            }}
            media={media}
            error={errors?.post_generator?.content_attributes?.focus?.message}
          />
        </FormField>

        <FormField
          title={intl.formatMessage({ id: "post_generator_form.link", defaultMessage: "Lien" })}
          hint={intl.formatMessage({ id: "post_generator_form.warning_google", defaultMessage: "⚠️ Impossible de publier un contenu sur Google si vous indiquez le numéro de téléphone" })}
          error={
            errors?.post_generator?.content_attributes?.reconstructed_url
              ?.message as string | undefined
          }
        >
          <InsertableTextInput
            disabled={readonly}
            {...register(
              "post_generator.content_attributes.reconstructed_url",
              {
                validate: (value: string | undefined) => {
                  if (
                    !value &&
                    getValues("post_generator.content_attributes.focus") ===
                    "link"
                  ) {
                    return "En selectionnant l'expérience lien, vous devez indiquer un lien.";
                  } else {
                    return true;
                  }
                },
              }
            )}
            onInsertionCallback={() =>
              isSubmitted
                ? trigger("post_generator.content_attributes.reconstructed_url")
                : null
            }
            emojis={false}
            setValue={setValue}
            insertionVariables={["$SITE", "$CODE", "$TEL", "$SPONSORLINK"]}
            className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.post_generator?.content_attributes?.reconstructed_url
              ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
              }`}
          />
        </FormField>

        {props.tag_groups && props.tag_groups.length > 0 && (
          <FormField
            title="Tags"
          // hint="⚠️ Impossible de publier un contenu sur Google si vous indiquez le numéro de téléphone"
          >
            <Controller
              name="post_generator.content_attributes.tag_ids"
              control={control}
              render={({ field }) => (
                <TagSelector
                  onChange={(option) => field.onChange(option)}
                  tagGroups={props.tag_groups}
                  selected={field.value}
                />
              )}
            />
          </FormField>
        )}
        <FormField
          title={intl.formatMessage({ id: "post_generator_form.action_btn", defaultMessage: "Bouton d'action" })}
        >
          <Controller
            name="post_generator.content_attributes.cta"
            control={control}
            // defaultValue={post_generator&.content_attributes&.cta || null}
            render={({ field }) => (
              <Select
                onChange={(option) => field.onChange(option)}
                list={ctaOptions}
                value={field.value ? field.value : ""}
                disabled={focus !== "link"}
              />
            )}
          />
        </FormField>
        {props.content_dispenser && (
          <FormField
            title="Expiration"
            hint="Laisser vide si le contenu ne périme pas."
          >
            <Controller
              name="post_generator.content_attributes.expires_at"
              control={control}
              render={({ field: { onChange } }) => (
                <DatePicker
                  onChange={onChange}
                  error={
                    errors?.post_generator?.content_attributes?.expires_at
                      ?.message
                  }
                  options={{ minDate }}
                  defaultValue={
                    content.expires_at
                      ? new Date(content.expires_at).toLocaleString("fr")
                      : undefined
                  }
                />
              )}
            />
          </FormField>
        )}
      </FormLayout>
      <FormLayout
        title={intl.formatMessage({ id: "post_generator_form.social_networks", defaultMessage: "Réseaux sociaux" })}
        description={intl.formatMessage({ id: "post_generator_form.netwokrs_hint", defaultMessage: "Choisissez les réseaux sociaux sur lesquels vous souhaitez publier votre contenu." })}
      >
        <FormField
          title={intl.formatMessage({ id: "post_generator_form.networks", defaultMessage: "Réseaux" })}
        >
          <ChannelSelector
            inputName="post_generator.channel_ids"
            register={register}
            selected={channel_ids}
            channels={props.channels}
          />
        </FormField>
      </FormLayout>
      <FormLayout
        title={intl.formatMessage({ id: "post_generator_form.programmation", defaultMessage: "Programmation" })}
        description={intl.formatMessage({ id: "post_generator_form.schedule_your_post", defaultMessage: "Choisissez la date, l’heure et la récurrence de votre publication" })}
      >
        <label >
          <div className="flex my-3 space-x-2">
            <input
              type="radio"
              value="periodicity_planification"
              checked={selectedOption === 'periodicity_planification'}
              onChange={(e) => {
                handleOptionChange(e.target.value)
                setValue("post_generator.periodicity", previousPeriodicity)
                setValue("post_generator.starting_at", previousStartingAt
                  ? new Date(previousStartingAt)
                  : null)
              }}
            />
            <div className="flex">
              <h3 className="space-x-2 text-lg font-medium leading-6">
                Programmer la publication
              </h3>
            </div>
          </div>

          <FormField
            title={intl.formatMessage({ id: "post_generator_form.date", defaultMessage: "Date" })}
            hint={intl.formatMessage({ id: "post_generator_form.first_diffusion", defaultMessage: "Date de la première diffusion" })}
            error={errors?.post_generator?.starting_at?.message}
            disabled={selectedOption === 'periodicity_publish_now'}
          >
            <Controller
              name="post_generator.starting_at"
              control={control}
              rules={{ required: intl.formatMessage({ id: "post_generator_form.choose_diffusion_date", defaultMessage: "Vous devez choisir une date de diffusion." }), }}
              render={({ field: { onChange } }) => (
                <DatePicker
                  onChange={(option) => {
                    onChange(option)
                    setPreviousStartingAt(option.toString() ? option.toString() : minDate.toString())
                  }}
                  error={errors?.post_generator?.starting_at?.message}
                  options={{ minDate, maxDate }}
                  defaultValue={
                    post_generator.starting_at
                      ? new Date(post_generator.starting_at).toLocaleString("fr")
                      : undefined
                  }
                  disabled={selectedOption === 'periodicity_publish_now'}
                />
              )}
            />
          </FormField>

          <FormField
            title={intl.formatMessage({ id: "post_generator_form.recurrency", defaultMessage: "Récurrence" })}
            hint={intl.formatMessage({ id: "post_generator_form.scheduling", defaultMessage: "La périodicité est calculée à partir de la date du premier post." })}
            disabled={selectedOption === 'periodicity_publish_now'}
          >
            <Controller
              name="post_generator.periodicity"
              control={control}
              rules={{ required: intl.formatMessage({ id: "error.required", defaultMessage: "Ce champ ne peut être vide." }), }}
              render={({ field }) => (
                <Select
                  onChange={(option) => {
                    field.onChange(option)
                    setPreviousPeriodicity(option ? option : "one_time")
                  }}
                  list={periodicityOptions}
                  value={field.value}
                  disabled={selectedOption === 'periodicity_publish_now'}
                />
              )}
            />
          </FormField>
        </label>
        {/*<div className="flex items-center justify-center space-x-5">
          <hr className="w-full h-1" />
          <p>OU</p>
          <hr className="w-full h-1" />
        </div>*/}
        <label className='my-3'>
          <div className="flex my-3 space-x-2">
            {/*<input
              className="m-1"
              type="radio"
              value="periodicity_publish_now"
              checked={selectedOption === 'periodicity_publish_now'}
              onChange={(e) => {
                handleOptionChange(e.target.value)
                setValue("post_generator.periodicity", "publish_now")
                setValue("post_generator.starting_at", minDate)
              }}
            />*/}
            {/*<div className="flex">
              <h3 className="space-x-2 text-lg font-medium leading-6">
                <FormattedMessage id="post_generator_form.publish_now" defaultMessage="Publier Maintenant" />
              </h3>
            </div>*/}
          </div>
          {/*<FormField
            // title={intl.formatMessage({id:"post_generator_form.publish_now", defaultMessage:"Publier maintenant"})}
            hint={intl.formatMessage({ id: "post_generator_form.publish_now_hint", defaultMessage: "Le post est publié immédiatement sur les réseaux sociaux" })}
          >*/}
          <></>
          {/* <Controller
              name="post_generator.periodicity"
              control={control}
              render={({ field }) => (
                <Select
                onChange={(option) => field.onChange(option)}
                list={periodicityOptions}
                value={field.value}
                />
                )}
              /> */}
          {/*</FormField>*/}
        </label>
        <div>
        </div>
      </FormLayout>

      <div className="flex justify-end">
        <button
          disabled={submitted}
          className="relative rounded-md bg-brand_main disabled:bg-gray-300 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-brand_darker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand_main"
          type="submit"
        >
          <Spinner className={`absolute ${!submitted && "invisible"} w-5 h-5 mb-1 ml-4 text-white animate-spin`} />
          <span className={` ${submitted && "invisible"}`}>
            <FormattedMessage id="post_generator_form.save" defaultMessage="Enregistrer" />
          </span>
        </button>
      </div>
    </form>
  );
}
