import { UseFormSetValue } from "react-hook-form";
import { ContentMedium } from "../types";
import { useEffect, useState } from "react";
import { getCSRFToken } from "../components/utils";

export const useOpenAI = (
  prompt: string,
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  media: ContentMedium[],
  setValue: UseFormSetValue<any>,
  setShowInputMessage: React.Dispatch<React.SetStateAction<boolean | null>>,
  getValues: any,
  fieldPath: string,
  apiRoute: string
) => {
  const imageUrls = media.map(m => m.serialized_file?.secure_url).filter(url => url !== undefined);
  const [responseFromOpenAI, setResponseFromOpenAI] = useState<string>("");
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const simulateTyping = (fullText: string, index = 0, callback: () => void) => {
    if (index < fullText.length) {
      const nextChar = fullText.charAt(index);
      const currentValue = getValues(fieldPath) || "";
      const newValue = currentValue + nextChar;
      setValue(fieldPath, newValue, { shouldValidate: true });

      setTimeout(() => {
        simulateTyping(fullText, index + 1, callback);
      }, 10);
    } else {
      callback();
    }
  };

  const SubmitOpenAI = async () => {
    setLoading(true);
    setDisabledInput(true);
    setError(null);
    try {
      const response = await fetch(apiRoute, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCSRFToken(),
        },
        body: JSON.stringify({
          prompt: prompt,
          media: imageUrls,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setResponseFromOpenAI(data.answer);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Une erreur est survenue lors de l’appel à OpenAI.');
      }
    } catch (error) {
      setError('Erreur de réseau ou problème de serveur.');
      console.error("Erreur lors de l'appel à OpenAI ->", error);
    }
    setShowInputMessage(false);
    setLoading(false);
  };

  useEffect(() => {
    if (responseFromOpenAI) {
      simulateTyping(responseFromOpenAI, 0, () => setDisabledInput(false));
    }
  }, [responseFromOpenAI]);

  useEffect(() => {
    if (error) {
      setDisabledInput(false);
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  }, [error]);

  return { SubmitOpenAI, disabledInput, error };
};
