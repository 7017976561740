import { Cloudinary } from '@cloudinary/url-gen';
import React, { useState, useEffect } from 'react'
import { set } from 'react-hook-form';
import { CloudinaryFile } from '../types/cloudinary_custom_types';
import { ContentMedium } from '../types';


type Props = {
  addMedia: (files: CloudinaryFile[]) => void;
}

type UploadingFileStatus = {
  progress: number,
  status: "waiting" | "errored" | "loading" | "done"
}

const cld = new Cloudinary({
  cloud: {
    cloudName: "dcmekntwa",
  },
});


function useMultiCloudinaryUpload({ addMedia }: Props): ({
  totalProgress: number,
  uploadFiles: (files: File[]) => void,
}) {

  const [uploadingFilesStatus, setUploadingFilesStatus] = useState<Record<string, UploadingFileStatus>>({})

  const updateFileStatus = (
    signature: string,
    { status, progress }: UploadingFileStatus
  ) => setUploadingFilesStatus(prev => (
    { ...prev, [signature]: { status, progress } }
  ))

  const [files, setFiles] = useState<CloudinaryFile[]>([])
    
  const addFile = (file: CloudinaryFile) => {
    setFiles(prev => [...prev, file]);
  };

  useEffect(() => {
    // Quand il ya qqun && plus personne n'est loading, on a fini
    const statuses = Object.values(uploadingFilesStatus)
      .map(({ status }) => status)
    if (statuses.length > 0 && statuses.every(s => ["errored", "done"].includes(s))) {
      addMedia(files)
      setFiles([])
      setUploadingFilesStatus({})
    }
  }, [uploadingFilesStatus])

  const uploadFiles = async (files: File[]) => {    
    files.forEach((file) => {
      const timestamp = Date.now().toString();
      // On passe par le backend pour ne pas exposer le secret (sert à signer)
      fetch(
        `/api/sign_cloudinary_upload?timestamp=${timestamp}&return_delete_token=true`
      ).then(async (signatureData) => {
        const { signature } = await signatureData.json();
        const url = `https://api.cloudinary.com/v1_1/${cld.getConfig().cloud?.cloudName
          }/upload`;
        const fd = new FormData();
        fd.append("file", file);
        fd.append("api_key", "883898914556696");
        fd.append("timestamp", timestamp);
        fd.append("return_delete_token", "true");
        fd.append("signature", signature);
        // on utilise XHR pour avoir accès au progrès de l'upload
        const xhr = new XMLHttpRequest();
        Object.entries({
          progress: (e: any) => updateFileStatus(
            signature,
            { status: "loading", progress: (e.loaded * 100.0) / e.total }
          ),
          loadstart: (_e: any) => updateFileStatus(signature, {
            status: "loading", progress: 0
          }),
          error: (_e: any) => updateFileStatus(signature, {
            status: "errored", progress: 0
          }),
        }).forEach(
          ([k, f]: [string, (e: any) => void]) => xhr.upload.addEventListener(k, f)
        );

        xhr.onreadystatechange = function() {
          if (xhr.readyState == 4 && xhr.status == 200) {
            updateFileStatus(signature, { status: "done", progress: 100 })
            var response = JSON.parse(xhr.responseText);
            const identifier = `${response.resource_type}/${response.type}/v${response.version}/${response.public_id}.${response.format}`;
            addFile({ ...response, identifier });
          }
        };
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.send(fd);
      });
    });
  }
  
  const totalProgress = Object
    .values(uploadingFilesStatus)
    .reduce((acc, { progress }) => acc + progress, 0) / Object.keys(uploadingFilesStatus).length


  return { uploadFiles, totalProgress }
}

export default useMultiCloudinaryUpload
