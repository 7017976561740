import { Controller } from "@hotwired/stimulus"
import { getCSRFToken } from "../components/utils"
import Abraham from "../components/abraham"
import Shepherd from 'shepherd.js';
import Cookies from 'js-cookie'
import { offset, autoPlacement } from '@floating-ui/dom';


declare global {
  interface Window {
    Abraham: Abraham;
    Cookies: Cookies;
  }
}


export default class Onboarder extends Controller {

  defaultTourOptions = {
    defaultStepOptions: {
      classes: "tailwind-shepherd",
      floatingUIOptions: {
        middleware: [
          offset({ mainAxis: 10, crossAxis: 10 }),
          autoPlacement()
        ],
      },
    },
    useModalOverlay: true,
    modalContainer: document.getElementById("modal-container") || undefined,
    stepsContainer: document.getElementById("modal-container") || undefined,
    styleVariables: {
      shepherdElementZIndex: 30,
    }
  }

  tour: Shepherd.Tour & { checkAndStart?: () => void } = new Shepherd.Tour(this.defaultTourOptions)



  get props() {
    const data = this.data.get("props")
    return data ? JSON.parse(data) : {}
  }


  connect() {
    super.connect();
    this.tour.on("complete", () => this.saveTour())
    this.tour.on("cancel", () => this.writeCookie("later"))


    this.tour.checkAndStart = () => {
      console.log("checkAndStart")
      // Don't start the tour if the user dismissed it once this session
      var whereWereWe = this.getCookie();
      if (whereWereWe == 'later') {
        return
      }

      if (whereWereWe) {
        let stepIndex = this.tour.steps.findIndex(step => step.id == whereWereWe)
        let step = this.tour.steps[stepIndex]
        const advanceOnLoad = step?.options.advanceOnLoad

        if (advanceOnLoad) {
          if (advanceOnLoad.ifSelector && !document.querySelector(advanceOnLoad.ifSelector)) {
            // Si on a ajouté un selector et que non présent
            this.tour.start()
            return
          }
          // Sinon on passe à la step suivante
          stepIndex += 1
          step = this.tour.steps[stepIndex]
        }
        if (step.options.attachTo) {
          // il y a une attache et on ne la trouve pas
          const element = document.querySelector(step.options.attachTo.element)
          if (!element?.offsetParent) {
            this.tour.start() // On relance le tour
            return
          }
        }

        this.tour.show(whereWereWe);
        if (advanceOnLoad) {
          if (advanceOnLoad.ifSelector && !document.querySelector(advanceOnLoad.ifSelector)) {
            // Si on a ajouté un selector et que non présent
            return
          }

          // Sinon on passe à la step suivante
          this.tour.next()
        }

      } else {
        const attachTo = this.tour.steps[1].attachTo
        if (attachTo && !document.querySelector(attachTo.element)) {
          return
        }
        this.tour.start()
      }
    }
    this.tour.checkAndStart.bind(this)

    window.Abraham.tours[this.props.tour_name] = this.tour
    //window.Abraham.tours[this.props.tourName].on("cancel", () => this.writeCookie("later"))
    Object.entries(this.props.steps).forEach(([key, step]: [key: any, step: any]) => {
      this.tour.addStep({
        modalOverlayOpeningPadding: 4,
        modalOverlayOpeningRadius: 8,
        when: {
          show: (() => this.writeCookie(this.tour.getCurrentStep()?.id)).bind(this)
        },
        id: `step-${key}`,
        ...(step.title ? { title: step.title } : {}),
        ...(step.text ? { text: step.text } : {}),
        ...(step.attachTo ? {
          attachTo: { element: step.attachTo.element, on: step.attachTo.placement },
          //showOn: () => Boolean(document.querySelector(step.attachTo.element))
        } : {}),
        ...(step.advanceOnLoad ? { advanceOnLoad: step.advanceOnLoad } : {}),
        ...(step.waitForFrame ? {
          beforeShowPromise: this.waitForFrame(step.waitForFrame)
        } : {}),
        buttons: [
          ...(step.buttons ? Object.values(step.buttons).map((button: any) => ({
            text: button.text,
            action: this.tour[button.action],
            classes: button.classes
          })) : [])
        ],
      })
    })


    if (!this.props.tour_completed) {
      window.Abraham.incompleteTours.push(this.props.tour_name)
    }
  }

  waitForFrame(frameId: string) {
    return () => {
      return new Promise<void>((resolve) => {
        const turboFrame = document.querySelector(`#${frameId}`);
        // If the turbo frame is already loaded, resolve immediately
        if (turboFrame && turboFrame.dataset.loaded === "true") {
          resolve();
        } else {
          // Wait for the turbo frame to load
          const onFrameLoad = () => {
            turboFrame?.removeEventListener('turbo:frame-load', onFrameLoad);
            resolve();
          };
          turboFrame?.addEventListener('turbo:frame-load', onFrameLoad);
        }
      });
    }
  }

  async saveTour() {

    // Make AJAX call to save history of tour completion
    const res = await fetch("/abraham_histories/", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', "X-CSRF-Token": getCSRFToken() },
      body: JSON.stringify({
        controller_name: this.props.controller_name,
        action_name: this.props.action_name,
        tour_name: this.props.tour_name
      })
    });
    if (res.ok) {
      this.removeCookie()
    }
  }

  writeCookie(value: string) {
    return window.Cookies.set(
      `${this.props.cookie_prefix}-${this.props.tour_name}`,
      value,
      { domain: this.props.cookie_domain }
    )
  }

  getCookie() {
    return window.Cookies.get(
      `${this.props.cookie_prefix}-${this.props.tour_name}`,
      { domain: this.props.cookie_domain }
    )
  }

  removeCookie() {
    return window.Cookies.remove(
      `${this.props.cookie_prefix}-${this.props.tour_name}`,
      { domain: this.props.cookie_domain }
    )
  }

  disconnect() {
    super.disconnect();
  }

}
