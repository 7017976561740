import React, { useState, useEffect } from "react";
import { Listbox, Popover } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import { TagGroup } from "../types";
import { UseFormRegister } from "react-hook-form/dist/types";


type Props = {
  tagGroups: TagGroup[];
  selected: number[];
  // register: UseFormRegister<any>;
  onChange: (option: number[]) => void;
}

function TagSelector({tagGroups, selected, onChange}:Props) {
  
  return (
    <>
      {tagGroups.map((tagGroup) => (
        <Listbox value={selected} onChange={onChange} multiple>
          <div className=" relative inline-block text-left px-2 border-r border-gray-200">
            <Listbox.Button
              type="button"
              // onClick={() => setActiveGroup(tagGroup.id)}
              className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              {tagGroup.label}
             <ChevronDownIcon className="h-5 w-5 ml-1  text-gray-400 " />
            </Listbox.Button>
          {/* <Popover.Panel class="absolute left-0 z-10 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none overflow-scroll max-h-64"> */}

          <Listbox.Options className="py-4 absolute w-48 left-0 z-10 mt-2 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none overflow-scroll max-h-64 ">
            {tagGroup.tag_sub_groups.map((tsg) => (
              <>
                <div className="flex flex-col items-center">
                  <span className="text-xs text-gray-500">{tsg.label.toUpperCase()}</span>
                  <div className=" w-36 my-2  border-gray-400 border-b"></div>
                </div>
                {tsg.tags.map((tag) => (
                  <Listbox.Option
                    key={tag.id}
                    value={tag.id}
                    className={({ active }) =>
                    `relative cursor-default select-none py-1 pl-10 pr-4 ${
                      active ? 'text-brand_main' : 'text-gray-900'
                    }`
                  }
                  >
                    {({ selected }) => (
                    <>
                    <span
                      className={`text-sm block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {tag.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brand_main">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                  )}
                  </Listbox.Option>
                ))}
              </>
            ))}
          </Listbox.Options>
          </div>

        </Listbox>
      ))}
    </>

  );
}

export default TagSelector;
