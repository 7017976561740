import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import EmojiPicker from "@emoji-mart/react";
import { FieldValues, UseFormSetValue } from "react-hook-form/dist/types";

type Props = {
  rows?: number;
  emojis?: boolean;
  insertionVariables: string[];
  name: string;
  id: string;
  setValue: UseFormSetValue<FieldValues>;
  value: string;
  inputType?: "textarea" | "input";
  disabled?: boolean;
  [key: string]: any;
};

const InsertableTextInput = React.forwardRef(
  (
    {
      rows = 1,
      emojis = false,
      insertionVariables,
      setValue,
      inputType = "input",
      name,
      disabled,
      ...props
    }: Props,
    ref
  ) => {
    const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>(
      null
    );

    // ça a l'air de marcher sans magiquement mais on met quand même :
    const [emojiData, setEmojiData] = useState({});
    useEffect(() => {
      (async () => {
        const response = await fetch(
          "https://cdn.jsdelivr.net/npm/@emoji-mart/data"
        );
        setEmojiData(await response.json());
      })();
    }, []);

    const insert = (inserted: string) => {
      if (inputRef?.current) {
        const { selectionStart, selectionEnd, value } = inputRef.current;
        if (
          typeof selectionStart === "number" &&
          typeof selectionEnd === "number"
        ) {
          // typeOf car 0 est compté comme false
          const newValue = (inputRef.current.value = `${value.slice(
            0,
            selectionStart
          )}${inserted}${value.slice(selectionEnd)}`);
          // On met à jour les 2 champs (pour que l'input suive et que le form soit actualisé)
          inputRef.current.value = newValue;
          setValue(name, inputRef.current.value);

          inputRef?.current?.focus();
          setTimeout(() => {
            // Visiblement nécessaire
            if (inputRef.current) {
              inputRef.current.selectionStart =
                selectionStart + inserted.length;
              inputRef.current.selectionEnd = selectionStart + inserted.length;
              if (props?.onInsertionCallback) {
                props.onInsertionCallback();
              }
            }
          }, 10);
        }
      }
    };

    const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
    const onEmojiSelect = (e: any) => {
      insert(String.fromCodePoint(parseInt(e.unified, 16)));
      setEmojiSelectorOpen(false);
    };

    const refCallback = (el: HTMLInputElement | HTMLTextAreaElement | null) => {
      if (el) {
        // On accroche les 2 refs (inputRef -la notre- et ref - la leur, une fonction)
        (
          inputRef as MutableRefObject<HTMLInputElement | HTMLTextAreaElement>
        ).current = el;
        if (typeof ref === "function") {
          ref(el);
        } else if (ref) {
          ref.current = el;
        }
      }
    };

    return (
      <div className="relative">
        <div className="flex space-x-2 overflow-x-auto">
          {emojis && (
            <div
              onClick={() => {
                !disabled && setEmojiSelectorOpen(true);
              }}
              className={` text-gray-400 ${
                !disabled ? "cursor-pointer hover:text-brand_main" : ""
              }`}
            >
              <FaceSmileIcon className="inline w-5 h-5 mb-2" />
            </div>
          )}
          {insertionVariables.map((variable) => (
            <div
              className={`text-sm font-medium text-gray-400 ${
                !disabled ? "hover:text-brand_main cursor-pointer" : ""
              }`}
              onClick={() => !disabled && insert(variable)}
            >
              {variable}
            </div>
          ))}
        </div>
        {emojis && (
          <div
            className={`${emojiSelectorOpen ? "" : "hidden"} absolute left-0 z-10`}
          >
            <EmojiPicker
              theme="light"
              onClickOutside={() => {
                emojiSelectorOpen ? setEmojiSelectorOpen(false) : null;
              }}
              onEmojiSelect={onEmojiSelect}
            />
          </div>
        )}
        {inputType === "textarea" ? (
          <textarea
            disabled={disabled}
            ref={(el) => refCallback(el)}
            rows={rows}
            name={name}
            className="flex flex-1 w-full border-gray-300 rounded-md shadow-sm form-input focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
            {...props}
          />
        ) : (
          <input
            disabled={disabled}
            ref={(el) => refCallback(el)}
            className="flex flex-1 w-full border-gray-300 rounded-md shadow-sm form-input focus:border-brand_focus focus:ring 
focus:ring-brand_focus focus:ring-opacity-20"
            name={name}
            {...props}
          />
        )}
      </div>
    );
  }
);

export default InsertableTextInput;
