import React from "react";
import DisplayMedia from "../_DisplayMedia";
import CancelSave from "../_CancelSave";
import LogoSettings from "../_LogoSettings";
import { LibraryImage, PixabayImage } from "../../types";

interface PixabayTabProps {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  images: PixabayImage[];
  setImages: React.Dispatch<React.SetStateAction<PixabayImage[]>>;
  loading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  defaultSearch: string | null;
  selectedImage: PixabayImage | LibraryImage | null;
  setSelectedImage: React.Dispatch<
    React.SetStateAction<PixabayImage | LibraryImage | null>
  >;
  insertLogo: boolean;
  setInsertLogo: React.Dispatch<React.SetStateAction<boolean>>;
  logoPosition: string;
  setLogoPosition: React.Dispatch<React.SetStateAction<string>>;
  logoShape: "rounded-square" | "circle";
  setLogoShape: React.Dispatch<
    React.SetStateAction<"rounded-square" | "circle">
  >;
  selectedLogo: string;
  setSelectedLogo: React.Dispatch<React.SetStateAction<string>>;
  isSaving: boolean;
  searchImages: () => Promise<void>;
  loadMoreImages: () => Promise<void>;
  uploadSelectedImage: () => Promise<void>;
  profile_picture: string;
  getPositionClasses: (position: string) => string;
  getShapeClasses: (shape: string) => string;
  onImageSelect: React.Dispatch<React.SetStateAction<boolean>>;
}

const PixabayTab: React.FC<PixabayTabProps> = ({
  query,
  setQuery,
  images,
  loading,
  selectedImage,
  setSelectedImage,
  insertLogo,
  setInsertLogo,
  logoPosition,
  setLogoPosition,
  logoShape,
  setLogoShape,
  selectedLogo,
  setSelectedLogo,
  isSaving,
  searchImages,
  loadMoreImages,
  uploadSelectedImage,
  profile_picture,
  getPositionClasses,
  getShapeClasses,
  onImageSelect,
}) => {
  return (
    <>
      <div className="flex flex-wrap-reverse my-1 items-center justify-between gap-3">
        <div className="flex justify-end w-full sm:w-fit">
          <div className="relative max-w-60 ">
            <input
              type="text"
              name="search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  searchImages();
                }
              }}
              placeholder="Rechercher"
              className="peer form-input flex flex-1 w-full rounded-md shadow-sm pl-5 pr-3 py-1 border border-gray-300 rounded-full focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
            />
            <button
              onClick={searchImages}
              className="
                absolute inset-y-0 right-0 flex items-center justify-center
                w-10 bg-gray-50 border border-gray-300 rounded-r-md
                hover:bg-gray-100 text-gray-400 duration-100 peer-focus:border-brand_focus cursor-pointer
              "
            >
              <i className="fa fa-search duration-100 cursor-pointer"></i>
            </button>
          </div>
        </div>
        <LogoSettings
          profile_picture={profile_picture}
          insertLogo={insertLogo}
          setInsertLogo={setInsertLogo}
          logoPosition={logoPosition}
          setLogoPosition={setLogoPosition}
          selectedLogo={selectedLogo}
          setSelectedLogo={setSelectedLogo}
          logoShape={logoShape}
          setLogoShape={setLogoShape}
          isSaving={isSaving}
        />
      </div>
      {loading && (
        <p className="p-3 text-white text-center mb-4">Chargement en cours</p>
      )}
      <div className="flex-1 overflow-y-auto">
        <div className="my-4 columns-2 sm:columns-3 md:columns-4 space-y-3 gap-3">
          {images.map((image) => (
            <DisplayMedia
              key={image.id}
              image={image}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              insertLogo={insertLogo}
              profile_picture={profile_picture}
              logoPosition={logoPosition}
              logoShape={logoShape}
              getPositionClasses={getPositionClasses}
              getShapeClasses={getShapeClasses}
              pixabay={true}
            />
          ))}
        </div>
        <div className="flex flex-col items-center justify-center ">
          {images.length > 0 && (
            <button
              onClick={loadMoreImages}
              className="font-medium text-gray-700 hover:text-indigo-700"
              disabled={isSaving}
            >
              Afficher plus
            </button>
          )}
        </div>
      </div>
      {images.length > 0 && (
        <CancelSave
          isSaving={isSaving}
          selectedImage={selectedImage}
          onImageSelect={onImageSelect}
          uploadSelectedImage={uploadSelectedImage}
        />
      )}
    </>
  );
};

export default PixabayTab;
