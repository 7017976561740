import React from "react";
import DisplayMedia from "../_DisplayMedia";
import CancelSave from "../_CancelSave";

interface CanvaPage {
  id: string;
  page_index: number;
  thumbnail: {
    width: number;
    height: number;
    url: string;
  };
}

interface CanvaTabProps {
  refreshTokenRevokedMessage: string | null;
  setRefreshTokenRevokedMessage: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  folders: any[];
  foldersLoading: boolean;
  selectedFolder: number | null;
  setSelectedFolder: React.Dispatch<React.SetStateAction<number | null>>;
  selectedFolderItems: any[];
  selectedCanvaDesign: any | null;
  setSelectedCanvaDesign: React.Dispatch<React.SetStateAction<any | null>>;
  selectedCanvaPages: CanvaPage[];
  setSelectedCanvaPages: React.Dispatch<React.SetStateAction<CanvaPage[]>>;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isSaving: boolean;
  selectedImage: any;
  setSelectedImage: React.Dispatch<React.SetStateAction<any>>;
  fetchRootFolders: () => Promise<void>;
  fetchFolderItems: (folderId: string) => Promise<void>;
  fetchDesignPages: (designId: string) => Promise<void>;
  onImageSelect: React.Dispatch<React.SetStateAction<boolean>>;
  uploadSelectedImage: () => Promise<void>;
}

const CanvaTab: React.FC<CanvaTabProps> = ({
  refreshTokenRevokedMessage,
  setRefreshTokenRevokedMessage,
  folders,
  foldersLoading,
  selectedFolder,
  setSelectedFolder,
  selectedFolderItems,
  selectedCanvaDesign,
  setSelectedCanvaDesign,
  selectedCanvaPages,
  setSelectedCanvaPages,
  isExpanded,
  setIsExpanded,
  isSaving,
  selectedImage,
  setSelectedImage,
  fetchRootFolders,
  fetchFolderItems,
  fetchDesignPages,
  onImageSelect,
  uploadSelectedImage,
}) => {
  return (
    <>
      {refreshTokenRevokedMessage ? (
        <div className="flex flex-col gap-4 items-end">
          <div className="w-full p-4 bg-red-100 rounded">
            <p className="text-red-700 text-center">
              {refreshTokenRevokedMessage}
            </p>
          </div>
          <button
            onClick={() => (window.location.href = "/fr/content_dispensers")}
            className="btn-brand-primary w-fit"
          >
            Reconnecter mon compte Canva
          </button>
        </div>
      ) : (
        <>
          {foldersLoading && (
            <p className="text-black text-center mb-4">
              Chargement des dossiers...
            </p>
          )}
          {!foldersLoading && folders.length > 0 && (
            <div className="flex gap-2 p-3 overflow-x-auto no-scrollbar">
              {folders.map((item) => {
                if (item.folder?.id) {
                  return (
                    <button
                      key={item.folder.id}
                      onClick={() => {
                        fetchFolderItems(item.folder.id);
                        setSelectedFolder(item.folder.id);
                      }}
                      className={`px-3 py-2 text-white rounded text-sm whitespace-nowrap ${
                        item.folder.id === selectedFolder
                          ? "bg-brand_main"
                          : "bg-brand_focus"
                      } hover:opacity-90`}
                    >
                      {item.folder.name}
                    </button>
                  );
                }
              })}
            </div>
          )}
          {!foldersLoading && folders.length === 0 && (
            <div className="flex overflow-y-auto w-full flex-1">
              <div className="my-4">
                <span>Aucun design Canva dans ce dossier</span>
              </div>
            </div>
          )}
          {selectedFolderItems && selectedFolderItems.length > 0 ? (
            <div className="flex-1 overflow-y-auto">
              <div className="my-4 columns-2 sm:columns-3 md:columns-4 space-y-3 gap-3">
                {selectedFolderItems.map((folderItem) => {
                  if (
                    folderItem.type === "design" &&
                    folderItem.design?.thumbnail?.url
                  ) {
                    const designImage = {
                      id: folderItem.design.id,
                      largeImageURL: folderItem.design.thumbnail.url,
                      tags: folderItem.design.title || "Sans titre",
                    };

                    return (
                      <DisplayMedia
                        key={folderItem.id}
                        image={designImage}
                        selectedImage={selectedImage}
                        setSelectedImage={(image: any) => {
                          setSelectedCanvaDesign(folderItem.design);
                          setSelectedImage(image);
                          if (
                            folderItem.design.page_count &&
                            folderItem.design.page_count > 1
                          ) {
                            fetchDesignPages(folderItem.design.id);
                          } else {
                            setSelectedCanvaPages([]);
                          }
                        }}
                        insertLogo={false}
                        profile_picture={null}
                        logoPosition={null}
                        logoShape={null}
                        getPositionClasses={() => ""}
                        getShapeClasses={() => ""}
                        pixabay={false}
                      />
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            !foldersLoading &&
            folders.length > 0 && (
              <div className="flex overflow-y-auto w-full h-96 min-h-96 max-h-96">
                <div className="p-12">
                  <span>Aucun design Canva dans ce dossier</span>
                </div>
              </div>
            )
          )}
          {selectedCanvaDesign?.urls?.edit_url && (
            <div className="my-2">
              <a
                href={selectedCanvaDesign.urls.edit_url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-brand-primary"
              >
                Modifier le design
              </a>
            </div>
          )}
          {selectedCanvaPages.length > 1 && (
            <div className="border-t border-gray-300">
              <div className="flex items-center justify-between px-2 py-2">
                <p className="text-sm font-semibold">
                  Pages du design sélectionné :
                </p>
                <button
                  className="text-indigo-500 hover:underline text-sm"
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? "Cacher" : "Afficher"}
                </button>
              </div>
              {isExpanded && (
                <div
                  className="px-2 py-2 max-h-[calc(80vh-200px)] overflow-y-auto"
                  style={{ height: "auto", maxHeight: "calc(70vh - 200px)" }}
                >
                  <div className="flex flex-wrap gap-2 justify-start">
                    {selectedCanvaPages.map((page) => (
                      <div key={page.id} className="relative w-24 h-32">
                        <img
                          src={page.thumbnail.url}
                          alt={`Page ${page.page_index}`}
                          className="w-full h-full object-cover rounded border"
                        />
                        <span className="absolute top-1 left-1 text-xs bg-white px-1 rounded shadow">
                          {page.page_index}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="flex justify-between items-center mt-4">
            <CancelSave
              isSaving={isSaving}
              selectedImage={selectedImage}
              onImageSelect={onImageSelect}
              uploadSelectedImage={uploadSelectedImage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CanvaTab;
