import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

Chart.defaults.elements.line.tension = 0.4
Chart.defaults.interaction.mode = 'index'


export default class extends Controller {
  static targets = ["canvas"]

  get chartData() {
    return JSON.parse(this.data.get("data"))
  }

  get chartType() {
    return this.data.get("type")
  }

  get chartOptions() {
    const options = this.data.get("options")
    return options ? JSON.parse(options) : {}
  }



  connect() {
    new Chart(
      this.canvasTarget,
      {
        ...(this.chartType ? { type: this.chartType } : {}),
        data: this.chartData,
        options: this.chartOptions
      }
    );
  }

}
