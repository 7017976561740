import {Cloudinary } from '@cloudinary/url-gen';
import React, { useState } from 'react'
import { CloudinaryFile } from '../types/cloudinary_custom_types';


type Props = {
  addMedia: (file: CloudinaryFile) => void;
  cld: Cloudinary
}
function useCloudinaryUpload({cld, addMedia}:Props) {

  const [statusText, setStatusText] = useState<string | null>(null)

  const uploadFile = async (file: File) => {
    const timestamp = Date.now().toString();
    // On passe par le backend pour ne pas exposer le secret (sert à signer)
    const signatureData = await fetch(
      `/api/sign_cloudinary_upload?timestamp=${timestamp}&return_delete_token=true`
    );
    const { signature } = await signatureData.json();
    // All parameters added to the method call should be included except: file, cloud_name, resource_type and your api_key.
    const url = `https://api.cloudinary.com/v1_1/${
      cld.getConfig().cloud?.cloudName
    }/upload`;
    // var xhr = new XMLHttpRequest();
    const fd = new FormData();
    fd.append("file", file);
    fd.append("api_key", "883898914556696");
    fd.append("timestamp", timestamp);
    fd.append("return_delete_token", "true");
    fd.append("signature", signature);

    // on utilise XHR pour avoir accès au progrès de l'upload
    const xhr = new XMLHttpRequest();
    const wording = {
      progress: (e: any) =>
        setStatusText(
          `Téléchargement en cours... ${Math.round(
            (e.loaded * 100.0) / e.total
          )}%`
        ),
      loadstart: (e: any) => setStatusText("Début du téléchargement..."),
      error: (e: any) => setStatusText("Echec du téléchargement."),
    };
    Object.entries(wording).forEach(
      ([key, func]: [string, (e: any) => void]) => {
        xhr.upload.addEventListener(key, func);
      }
    );

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // File uploaded successfully
        setStatusText(null);
        var response = JSON.parse(xhr.responseText);
        const identifier = `${response.resource_type}/${response.type}/v${response.version}/${response.public_id}.${response.format}`;
        addMedia({ ...response, identifier });
      }
    };

    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.send(fd);
  };

return {uploadFile, statusText}
}

export default useCloudinaryUpload