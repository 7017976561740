import { Controller } from "@hotwired/stimulus";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import CompanyForm from "../components/CompanyForm";
import SecurityForm from "../components/SecurityForm";
import { IntlProvider } from "react-intl";
import fr from "../../../config/locales/js-lang/fr.json";
import en from "../../../config/locales/js-lang/en.json";

export default class extends Controller {
  get props() {
    console.log(this.data.get("props"));
    return JSON.parse(this.data.get("props"));
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element);
  }

  initialize() {}

  connect() {
    // Create a root.
    // Initial render: Render an element to the root.
    const messages = {fr, en}
    console.log("locale",this.props.locale)
    window.onload = () => {
      console.log(document.body)
      const root = ReactDOMClient.createRoot(this.element);

      root.render(<>
        <IntlProvider
          locale={this.props.locale}
          messages={this.props.locale ? messages[this.props.locale] : fr}
        >
          <CompanyForm {...this.props} />
        </IntlProvider>
        </>);
    };

  }
}
