import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';

export default class Abraham {
  constructor() {
    this.init()
  }

  tours: Record<string, Shepherd.Tour & { checkAndStart: () => void }> = {}

  incompleteTours: Array<string> = []

  startTour: Function = (tourName: string) => {
    if (!Shepherd.activeTour) {
      this.tours[tourName].start();
    }
  };

  startNextIncompleteTour = () => {
    console.log('startNextIncompleteTour')
    console.log(this.incompleteTours.length)
    console.log(this.tours)
    if (this.incompleteTours.length) {
      this.tours[this.incompleteTours[0]].checkAndStart();
    }
  };

  removeSheperdElement = () => {
    // Remove visible product tours
    document.querySelectorAll(".shepherd-element").forEach(function(el) { el.remove() });
    // Clear Abraham data
    this.tours = {};
    this.incompleteTours = [];
  }

  init = () => {
    document.addEventListener("DOMContentLoaded", this.startNextIncompleteTour.bind(this));
    document.addEventListener("turbolinks:load", this.startNextIncompleteTour.bind(this));
    document.addEventListener('turbolinks:before-cache', this.removeSheperdElement.bind(this));
  }

  // En cas de recfacto, embarquer ça dans le tour
  validateStepFromId = (tourId: string, stepId: string, async: boolean = false) => {
    console.log('validateStepFromId', tourId, stepId)
    const tour = this.tours[tourId]
    console.log("step", tour?.getCurrentStep())
    if (tour && tour.getCurrentStep()?.id == stepId) {
      // use setTimeout just to let any dom change happen
      tour.next()
    }
  }
}

