import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";

const CustomCloseButton = ({
  open,
  setOpen,
  onClickEvent,
}: {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickEvent?: () => void;
}) => {
  return (
    <button
      type="button"
      className="absolute top-2 right-2 p-1 rounded-md hover:bg-gray-100 group"
      onClick={() => {
        if (onClickEvent) onClickEvent();
        if (setOpen && open) setOpen(!open);
      }}
    >
      <XMarkIcon className="size-6 text-gray-400 group-hover:text-gray-500" />
    </button>
  );
};

export default CustomCloseButton;
