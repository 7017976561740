import React, { Fragment, SetStateAction, useEffect, useState } from 'react'
import { Dialog, DialogPanel, DialogTitle, DialogBackdrop, Transition, TransitionChild } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

export default function Modal(
  { open, setOpen, children }: {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    children: React.ReactNode
  }
) {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const rootNode = document.getElementById('test-root')
        if (rootNode) rootNode.inert = false
      }, 100)
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      transition
      className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
    >
      <DialogPanel>
        {children}
      </DialogPanel>
    </Dialog>
  )
}
