import React from "react";
import { Channel, ChannelAccount } from "../types";
import { ChannelLogo } from "./ChannelSelector";

type Props = {
  identifier: string;
  ca: ChannelAccount | undefined;
  withOutline?: boolean;
  isActive?: boolean;
  imageSize?: number;
  logoSize?: number;
  withChannelLogo?: boolean;
}

const ChannelAccountImage = ({
  identifier, ca, isActive, withChannelLogo, withOutline = true, imageSize = 10, logoSize = 4
}: Props) => {
  const classImageSize = `w-${imageSize} h-${imageSize}`
  return (
    <div className={`relative ${classImageSize}`}>
      <img
        className={`rounded-full h-full w-full object-cover bg-white border-2 border-white ring ring-white ${withOutline ?
          `outline outline-2 ${isActive ?
            `outline-${identifier}` :
            `opacity-50 outline-gray-300 group-hover:opacity-70 group-hover:outline-${identifier}`
          }` : ""}
        `}
        width={100}
        height={100}
        src={ca?.picture_url}
        title={ca?.displayed_name}
      />
      {withChannelLogo && <div className="absolute -bottom-2 -right-1 bg-slate-50 rounded-md border p-[2px]">
        <ChannelLogo
          identifier={ca?.channel?.identifier as Channel['identifier']}
          active={true}
          size={logoSize}
        />
      </div>}
    </div>
  );
}

export default ChannelAccountImage;
