import React, { useEffect } from "react";
import { EyeIcon, EllipsisHorizontalIcon, PlayIcon } from "@heroicons/react/24/solid";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";
import { FieldValues, UseFormWatch } from "react-hook-form/dist/types";
import { ContentMedium } from "../types";
import { FormattedMessage } from "react-intl";

type Props = {
  exampleAccount: {
    displayed_name: string;
    picture_url: string;
    company: {
      email: string;
      company: string;
      phone: string;
      hashtag: string;
      website: string;
      displayed_name: string;
      client_code: string;
      last_landing_path: string;
      last_newsletter_path: string;
      last_apilink: string;
      promo_code: string;
      sponsor_link: string;
    };
  };
  teaser: string;
  focus: string;
  reconstructed_url: string;
  description: string;
  cta?: string;
  media: ContentMedium[];
  // watch: UseFormWatch<FieldValues>
};
function FacebookPreview({
  exampleAccount,
  teaser,
  focus,
  reconstructed_url,
  description,
  cta,
  media,
}: Props) {
  const parsedTeaser = teaser
    ?.replaceAll("$SITE", exampleAccount.company.website || "")
    .replaceAll("$ENTREPRISE", exampleAccount.company.company || "")
    .replaceAll("$NOM", `${exampleAccount.company.displayed_name}` || "")
    .replaceAll("$MAIL", exampleAccount.company.email || "")
    .replaceAll("$TEL", exampleAccount.company.phone || "")
    .replaceAll("$HASHTAG", exampleAccount.company.hashtag || "")
    .replaceAll("$CODE", exampleAccount.company.client_code || "")
    .replaceAll("$LANDING", exampleAccount.company.last_landing_path || "")
    .replaceAll("$NEWSLETTER", exampleAccount.company.last_newsletter_path || "")
    .replaceAll("$APILINK", exampleAccount.company.last_apilink || "")
    .replaceAll("$PROMOCODE", exampleAccount.company.promo_code || "")
    .replaceAll("$SPONSORLINK", exampleAccount.company.sponsor_link || "");

  const parsedLink = reconstructed_url
    ?.replaceAll("$SITE", exampleAccount.company.website || "")
    .replaceAll("$TEL", exampleAccount.company.phone || "")
    .replaceAll("$CODE", exampleAccount.company.client_code || "")
    .replaceAll("$SPONSORLINK", exampleAccount.company.sponsor_link || "");

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dcmekntwa",
    },
  });

  // const watchFields = watch(["teaser", "description"])
  // console.log("ok", watchFields)

  // useEffect(() => {
  //   console.log("ok", watch)
  //   const subscription = watch((value, { name, type }) => console.log(value, name, type));
  //   return () => subscription.unsubscribe();
  // }, [watch]);
  // console.log("cta", cta);

  const validMedia = media.filter((m: ContentMedium) =>
    focus === "video"
      ? m.serialized_file.resource_type === "video"
      : m.serialized_file.resource_type === "image"
  );

  // console.log(media);

  return (
    <div className="hidden lg:block">
      <h3 className="flex items-baseline text-sm font-semibold leading-6 text-gray-700">
        <EyeIcon className="w-3 h-3 mr-1" />
        <FormattedMessage id="facebook_preview.preview" defaultMessage="Prévisualisation" />
      </h3>
      <div className="p-2 bg-gray-200 rounded-md shadow-inner">
        <div className="w-full h-auto py-1 bg-white rounded-md shadow-md ">
          <div className="flex items-start justify-between w-full p-1 px-2">
            <div className="flex">
              <img
                className="w-10 h-10 mr-3 rounded-full"
                src={exampleAccount.picture_url}
                alt=""
              />
              <div>
                <h3 className="text-sm font-semibold text-gray-700">
                  {exampleAccount.displayed_name}
                </h3>
                <p className="text-xs text-gray-500">
                  <FormattedMessage id="facebook_preview.minutes" defaultMessage="43 min" />
                </p>
              </div>
            </div>
            <div className="flex flex-col h-full ">
              <EllipsisHorizontalIcon className="w-4 mr-1 text-gray-500" />
            </div>
          </div>
          {/* <!--Contenu du message (teaser + éventuel lien) --> */}
          <div className="px-3 pb-3">
            <p className="text-xs text-gray-700 whitespace-pre-wrap break-words">
              {parsedTeaser}
            </p>
            {focus !== "link" && (
              <a className="block text-sm text-brand_main whitespace-pre-wrap break-words">
                {parsedLink}
              </a>
            )}
          </div>
          {/* <%# On affiche une image si des media sont présent %> */}
          {validMedia.length > 0 && (
            <div
              className={`relative ${focus === "images" ? "space-y-1" : ""
                } flex flex-col justify-center`}
            >
              <AdvancedImage
                className={`object-cover w-full ${focus === "image" ? "h-content" : "h-36"}`}
                cldImg={cld
                  .image(validMedia[0].serialized_file.public_id)
                  .setAssetType(validMedia[0].serialized_file.resource_type)
                  .format(
                    validMedia[0].serialized_file.resource_type === "video"
                      ? "jpg"
                      : validMedia[0].serialized_file.format
                  )
                  .resize(thumbnail().width(320))}
              />
              <div
                className="absolute top-0 flex items-center justify-center w-full h-full"
              >
                {validMedia[0] && validMedia[0].serialized_file.resource_type === "video" && <PlayIcon className="w-10 h-10 text-white" />}
              </div>
              <div className="flex space-x-1">
                {validMedia.length > 1 &&
                  focus === "images" &&
                  validMedia.map((medium, idx) => (
                    <>
                      {[1, 2, 3].includes(idx) && (
                        <div
                          className={`relative ${idx === 1 && validMedia.length < 3
                            ? "h-36 w-full"
                            : validMedia.length < 4
                              ? "h-36 w-1/2"
                              : "h-24 w-1/2"
                            }`}
                        >
                          <AdvancedImage
                            className="object-cover w-full h-full"
                            cldImg={cld
                              .image(medium.serialized_file.public_id)
                              .setAssetType(medium.serialized_file.resource_type)
                              .format(medium.serialized_file.format)
                              .resize(thumbnail().width(320))}
                          />
                          {validMedia.length > 4 && idx === 3 && (
                            <>
                              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60 "></div>
                              <div className="absolute top-0 flex flex-col justify-center w-full h-full text-xl font-semibold text-center text-white">
                                <p>{`+ ${validMedia.length - 4}`}</p>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          )}

          {focus === "link" && (
            <div className="flex flex-wrap justify-between p-1 bg-gray-100">
              <div className="w-full m-1 break-words">
                <p
                  className="text-xs text-gray-500 uppercase "
                  x-text="<%=link%>.replaceAll('$SITE','<%= website %>')"
                >
                  {parsedLink}
                </p>
                <p className="text-xs font-medium">
                  {description}
                </p>
              </div>
              <div className="flex justify-end w-full m-1">
                {cta && (
                  <button className="w-1/2 px-2 py-1 bg-gray-200 rounded-md">
                    <p className="text-xs font-bold">{cta}</p>
                  </button>
                )}
              </div>
            </div>
          )}

          <div className="flex items-center w-full h-8 px-3 my-1">
            <div className="z-10 flex items-center justify-center w-4 h-4 bg-indigo-500 rounded-full ">
              <svg
                className="w-2 h-2 text-white fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#b0b0b0"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              >
                <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
              </svg>
            </div>
            <div className="flex items-center justify-center w-4 h-4 -ml-1 bg-red-500 rounded-full">
              <svg
                className="w-2 h-2 text-white fill-current stroke-current"
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#b0b0b0"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              >
                <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
              </svg>
            </div>
            <div className="flex justify-between w-full">
              <p className="ml-3 text-xs text-gray-500">8</p>
              <p className="ml-3 text-xs text-gray-500">
                <FormattedMessage id="facebook_preview.comments" defaultMessage="29 commentaires" />
              </p>
            </div>
          </div>
          <div className="grid w-full grid-cols-3 px-1 my-3">
            <button
              type="button"
              className="flex flex-row items-center justify-center w-full space-x-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#838383"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              >
                <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
              </svg>
              <span className="text-xs font-semibold text-gray-600">
                <FormattedMessage id="facebook_preview.like" defaultMessage="J'aime" />
              </span>
            </button>
            <button
              type="button"
              className="flex flex-row items-center justify-center w-full space-x-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#838383"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
              <span className="text-xs font-semibold text-gray-600">
                <FormattedMessage id="facebook_preview.comment" defaultMessage="Commenter" />
              </span>
            </button>
            <button
              type="button"
              className="flex flex-row items-center justify-center w-full space-x-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#838383"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              >
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
              <span className="text-xs font-semibold text-gray-600">
                <FormattedMessage id="facebook_preview.share" defaultMessage="Partager" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <p className="mt-1 text-sm text-gray-500">
        <FormattedMessage id="facebook_preview.final_result" defaultMessage="Prévisualisation réalisée à partir du modèle de facebook. Le résultat final peut être sujet à des variations." />
      </p>
    </div>
  );
}

export default FacebookPreview;
