import React from "react";
import { CheckIcon } from "@heroicons/react/16/solid";

const DisplayMedia = ({
  image,
  selectedImage,
  setSelectedImage,
  insertLogo,
  profile_picture,
  logoPosition,
  logoShape,
  getPositionClasses,
  getShapeClasses,
  pixabay,
}) => {
  const imageUrl = pixabay ? image.largeImageURL : image.url;

  const altText = pixabay ? image.tags : image.filename;

  const imageId = pixabay ? image.id : image.public_id;

  const type = pixabay ? "image" : image.resource_type;

  const selectedImageId = pixabay
    ? selectedImage?.id
    : selectedImage?.public_id;

  return (
    <div
      key={imageId}
      onClick={() => setSelectedImage(image)}
      className={`relative rounded-lg cursor-pointer border-2
            ${selectedImageId === imageId
          ? "border-green-500 bg-green-500 overflow-visible"
          : "border-transparent"
        }
            `}
    >
      {selectedImage && selectedImageId === imageId && (
        <CheckIcon className="absolute right-0 top-0 m-1 overflow-visible size-6 text-green-500 bg-white rounded-full border border-green-500 p-1" />
      )}
      <div>
        {type == "image" && (
          <img
            src={imageUrl}
            alt={altText}
            className="object-cover rounded-lg"
          />
        )}

        {type == "video" && (
          <div
            className="relative"
            onMouseOver={(e) =>
              e?.currentTarget?.querySelector("video")?.play()
            }
            onMouseOut={(e) =>
              e?.currentTarget?.querySelector("video")?.pause()
            }
          >
            <video src={imageUrl} className="object-cover rounded-lg" muted />
            <div className="absolute inset-0 flex items-center justify-center">
              <svg
                className="w-12 h-12 text-white opacity-80"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        )}

        {insertLogo && profile_picture && (
          <img
            src={profile_picture}
            alt="Logo"
            className={`absolute w-5 h-5 object-cover ${getPositionClasses(
              logoPosition
            )} ${getShapeClasses(logoShape)}`}
          />
        )}
      </div>
    </div>
  );
};

export default DisplayMedia;
