import { Controller } from "@hotwired/stimulus";
import React from "react";
import { createRoot, Root } from "react-dom/client";
import { IntlProvider } from "react-intl";
import fr from "../../../config/locales/js-lang/fr.json";
import en from "../../../config/locales/js-lang/en.json"; // on mettra le compile plus tard

import CompanyContentForm from "../components/CompanyContentForm";

export default class extends Controller {
  root?: Root;
  get props() {
    return JSON.parse(this.data.get("props"))
  }

  disconnect() {
    // unmountComponentAtNode(this.element);
    this.root?.unmount();
  }

  initialize() {}

  connect() {
    // Create a root.
    this.root = createRoot(this.element);
    const messages = {fr, en}
    // Initial render: Render an element to the root.
    this.root.render(
      <IntlProvider
        locale={this.props.locale}
        messages={this.props.locale ? messages[this.props.locale] : fr}
      >
        <CompanyContentForm {...this.props} />
       </IntlProvider>
    );
  }
}
