import { SparklesIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import Spinner from "./Spinner";
import { getCSRFToken } from "./utils";
import { FormattedMessage } from "react-intl";


function UrlScraper({
  redirectionCallback,
  creationUrl
}: {
  creationUrl: string;
  redirectionCallback: (id: string) => string;
}) {
  const [link, setLink] = useState("");

  const [loading, setloading] = useState(false);

  const send = async () => {

    const reconstructed_url = link;
    if (reconstructed_url) {
      setloading(true)

      const response = await fetch(creationUrl, {
        method: "POST",
        body: JSON.stringify({
          content: {
            reconstructed_url,
            title: "Titre Provisoire",
            quick_reply_topic: "real estate",
          },
        }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCSRFToken(),
        },
      });
      if (response.ok) {
        setloading(false)
        const newContent = await response.json();
        console.log(redirectionCallback(newContent.id))

        window.Turbo?.visit(redirectionCallback(newContent.id));
      } else {
        setloading(false)

      }
    }
  };

  return (
    <div>
      <h3 className="flex text-sm items-baseline font-semibold leading-6 text-gray-700">
        <SparklesIcon className="h-3 w-3 mr-1" />
        <FormattedMessage id="url_scrapper.smartlink" defaultMessage="Smartlink" />
      </h3>
      <p className="text-sm text-gray-500">
        <FormattedMessage id="url_scrapper.hint_url" defaultMessage="Renseignez l'URL d'un contenu d'internet (Article de presse, Blog, Vidéo Youtube), nous tenterons d'en extraire les données." />
      </p>
      <div className="mt-2 relative w-full ">
        <input
          onChange={(e) => setLink(e.target.value)}
          className="pr-16 w-full text-sm form-input flex flex-1 rounded-md border-gray-300 shadow-sm focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20 "
          placeholder="https://immobilier.lefigaro.fr/"
        />
        <button
          type="button"
          onClick={send}
          className={`h-full w-16 absolute top-0 right-0 p-2 font-medium text-white bg-brand_main rounded-r-md border border-gray-300 hover:bg-brand_darker duration-300 ${loading ? "ring-4 ring-brand_lighter" : ""} `}
        >
          <span className="mb-2">
            {!loading ? (
              <>
                <FormattedMessage id="url_scrapper.go" defaultMessage="GO!" />
                <SparklesIcon className="inline h-4 w-4" /></>) : <Spinner className="animate-spin mb-1 ml-4 h-5 w-5 text-white" />}
          </span>
        </button>
      </div>
    </div>
  );
}



export default UrlScraper;
