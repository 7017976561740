import { ContentMedium } from "../types";
  type Focus = "link" | "video" | "images" | "image" | "none";

  const computeFocus = (media: ContentMedium[], linkUrl: string|null): {focus: Focus, accept: string} => {
    if (linkUrl) {
      return {focus: "link", accept: "image/*"};
    } else if (media.filter((m) => !m.destroy && m.serialized_file.resource_type === "video")
      .length > 0) {
      return {focus: "video", accept: "video/*"};
    } else if (
      media.filter((m) => !m.destroy && m.serialized_file.resource_type === "image").length >
      1
    ) {
      return {focus: "images", accept: "image/*"};
    } else if (
      media.filter((m) => !m.destroy && m.serialized_file.resource_type === "image").length >
      0
    ) {
      return {focus: "image", accept: "image/*"};
    } else {
      return {focus: "none", accept: "image/*,video/*"};
    }
  }

export default computeFocus;