import CustomPopover from "./Popover";
import Tooltip from "./Tooltip";
import { FaCog } from "react-icons/fa";
import React from "react";

const LogoSettings = ({
  profile_picture,
  insertLogo,
  setInsertLogo,
  logoPosition,
  setLogoPosition,
  selectedLogo,
  setSelectedLogo,
  logoShape,
  setLogoShape,
  isSaving,
}) => {
  const positions = [
    "top-left",
    "top-center",
    "top-right",
    "center-left",
    "center",
    "center-right",
    "bottom-left",
    "bottom-center",
    "bottom-right",
  ] as const;
  const getShapeClasses = (shape) => {
    switch (shape) {
      case "circle":
        return "rounded-full";
      case "rounded-square":
        return "rounded";
      default:
        return "";
    }
  };

  return (
    <div className={"flex justify-end gap-5 grow"}>
      <div className="relative flex items-center my-2 gap-2">
        <span className="text-gray-500 font-medium">Insérer votre logo</span>

        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            id="toggle-switch"
            onClick={() => {
              setInsertLogo(!insertLogo);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault(); // Empêche le comportement par défaut (pour éviter la perte du focus)
                setInsertLogo(!insertLogo);
              }
            }}
            aria-checked={insertLogo}
            role="switch"
            disabled={!profile_picture || isSaving}
          />

          <div
            className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none
                        peer-focus:ring-brand_focus dark:peer-focus:ring-brand_focus rounded-full
                        peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                        peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
                        after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
                        after:h-5 after:w-5 after:transition-all peer-checked:bg-green-300"
          ></div>
        </label>
      </div>

      <div className={"flex justify-center items-center"}>
        <CustomPopover
          button={
            <Tooltip
              text={insertLogo ? "Forme et position" : "Marque désactivée"}
            >
              <button
                type="button"
                className={`flex flex-row justify-center items-center text-brand_main bg-gray-100 rounded-full size-9 ${
                  insertLogo ? "hover:bg-gray-200" : ""
                }`}
                disabled={!profile_picture || !insertLogo}
              >
                <FaCog className={`size-4 text-gray-500 `} />
              </button>
            </Tooltip>
          }
          position="bottom-center"
          panelClasses="drop-shadow"
        >
          <div className="z-10 p-4 bg-white my-auto w-80 rounded-md border z-40">
            {
              <div
                id="shapeSelection"
                className="flex w-full my-1 justify-between"
              >
                <label className="flex flex-col text-md text-gray-700 gap-4">
                  <span>Forme</span>
                  <div id="squareLogo" className="flex">
                    <label
                      className="flex items-center gap-2"
                      key="Votre logo d'entreprise format carré"
                    >
                      <input
                        type="radio"
                        name="logoShape"
                        value="rounded-square"
                        className={"cursor-pointer"}
                        defaultChecked
                        onChange={() => {
                          setSelectedLogo("rounded-square");
                          setLogoShape("rounded-square");
                        }}
                      />
                      <span>Carré</span>
                    </label>
                  </div>

                  <div id="circleLogo" className="flex">
                    <label
                      className="flex items-center gap-2"
                      key="Votre logo d'entreprise format rond"
                    >
                      <input
                        type="radio"
                        name="logoShape"
                        className={"cursor-pointer"}
                        value="circle"
                        onChange={() => {
                          setSelectedLogo("circle");
                          setLogoShape("circle");
                        }}
                      />
                      <span>Rond</span>
                    </label>
                  </div>
                </label>

                <label className={"text-md text-gray-700 gap-4"}>
                  <span>Position</span>
                  <div className="relative w-28 h-28">
                    <div className="grid grid-cols-3 grid-rows-3 gap-x-4 w-full h-full">
                      {/*On utilise la constante positions pour boucler dedans et créer un div unique par élement*/}
                      {positions.map((position) => (
                        <div
                          key={position}
                          className={`flex justify-center items-center cursor-pointer relative group`}
                          onClick={() => setLogoPosition(position)}
                        >
                          <div
                            className=" w-3 h-3
                                                                        rounded-full bg-gray-300 group-hover:bg-gray-500"
                          ></div>
                          {insertLogo &&
                            profile_picture &&
                            logoPosition === position && (
                              <div
                                className={`absolute top-0 left-0 object-scale-down w-8 h-8 `}
                              >
                                <img
                                  src={profile_picture}
                                  alt="Logo"
                                  className={`absolute ${getShapeClasses(
                                    logoShape
                                  )} top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full  object-cover z-40`}
                                />{" "}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                </label>
              </div>
            }
          </div>
        </CustomPopover>
      </div>
    </div>
  );
};

export default LogoSettings;
