import React, { useState } from "react"
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
} from '@floating-ui/react';


export default function Tooltip({ children, text }: { text: string, children: JSX.Element }) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    // If your reference element has its own label (text).
    role: 'tooltip',
    // If your reference element does not have its own label,
    // e.g. an icon.
    // role: 'label',
  });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);
  return (
    <>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          ref: refs.setReference
        })
      )}
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={`min-w-32 max-w-48 w-fit group-hover:block bg-gray-700 text-white text-sm rounded py-2 px-3 bg-opacity-90 z-50`}
          {...getFloatingProps()}
        >
          <p>{text}</p>
        </div>
      )}
    </>
  );
}
