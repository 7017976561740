import { Channel, ChannelAccount } from "../types";

// Il faudra retirer cette fonction qui renvoie un array et c'est pas pratique !
export const sortCA = (channelAccounts: ChannelAccount[]) => {
  // On prend les identifiants uniques des Channels Accounts
  let identifiers = [...new Set(channelAccounts.map(ca => (ca.channel as Channel).identifier))];

  let array: any[] = []
  // On range les ChannelAccounts par identifier
  identifiers.forEach(identifier => {
    const thisCA = {
      [identifier]: channelAccounts.filter(ca => (ca.channel as Channel).identifier === identifier),
    }
    array.push(thisCA)
  })
  // La fonction retourne les channels acocunts rangés par identifiant
  return array
}
export const sortChannelAccounts = (channelAccounts: ChannelAccount[]) => {
  // On prend les identifiants uniques des Channels Accounts
  let identifiers: Channel["identifier"][] = [...new Set(channelAccounts.map(ca => (ca.channel as Channel).identifier))];

  let obj: { [identifier: Channel["identifier"]]: ChannelAccount[]} = {}
  // On range les ChannelAccounts par identifier
  identifiers.forEach(identifier => {
    const thisCA = {
      [identifier]: channelAccounts.filter(ca => (ca.channel as Channel).identifier === identifier),
    }
    Object.assign(obj, thisCA)
  })
  // La fonction retourne les channels acocunts rangés par identifiant
  return obj
}

export const isSmallDevices = window.matchMedia("only screen and (max-width: 600px)").matches;