import React, { useState } from "react";
import Flatpickr from "react-flatpickr";

type Props = {
  onChange: (d:Date) => void;
  disabled?: boolean;
  defaultValue: string | undefined;
  error?: string;
  options?: {[key:string]: any};
}
const CustomPicker = ({ onChange, disabled, defaultValue, error, options, ...props }:Props) => {

  return (
    <>
      <Flatpickr
        defaultValue={defaultValue}
        onChange={(dateArray: Date[]) => onChange(dateArray[0])}
        disabled={disabled}
        className={`cursor-pointer form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${
          error
            ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
        }`}
        options={{
          enableTime: true,
          time_24hr: true,
          dateFormat: "d/m/Y H:i:S",
          ...options
        }}
        {...props}
      />
    </>
  );
};
 export default CustomPicker