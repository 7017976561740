import { useCallback, RefObject, useState } from "react";

type StyleType = "bold" | "italic";

const boldMap: Record<string, string> = {
    'A': '𝐀', 'B': '𝐁', 'C': '𝐂', 'D': '𝐃', 'E': '𝐄', 'F': '𝐅', 'G': '𝐆',
    'H': '𝐇', 'I': '𝐈', 'J': '𝐉', 'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': '𝐍',
    'O': '𝐎', 'P': '𝐏', 'Q': '𝐐', 'R': '𝐑', 'S': '𝐒', 'T': '𝐓', 'U': '𝐔',
    'V': '𝐕', 'W': '𝗪', 'X': '𝐗', 'Y': '𝐘', 'Z': '𝐙',
    'a': '𝐚', 'b': '𝐛', 'c': '𝐜', 'd': '𝐝', 'e': '𝐞', 'f': '𝐟', 'g': '𝐠',
    'h': '𝐡', 'i': '𝐢', 'j': '𝐣', 'k': '𝐤', 'l': '𝐥', 'm': '𝐦', 'n': '𝐧',
    'o': '𝐨', 'p': '𝐩', 'q': '𝐪', 'r': '𝐫', 's': '𝐬', 't': '𝐭', 'u': '𝐮',
    'v': '𝐯', 'w': '𝐰', 'x': '𝐱', 'y': '𝐲', 'z': '𝐳',
    'À': '𝐀̀', 'Á': '𝐀́', 'Â': '𝐀̂', 'Ã': '𝐀̃', 'Ä': '𝐀̈', 'Å': '𝐀̊',
    'à': '𝐚̀', 'á': '𝐚́', 'â': '𝐚̂', 'ã': '𝐚̃', 'ä': '𝐚̈', 'å': '𝐚̊',
    'È': '𝐄̀', 'É': '𝐄́', 'Ê': '𝐄̂', 'Ë': '𝐄̈',
    'è': '𝐞̀', 'é': '𝐞́', 'ê': '𝐞̂', 'ë': '𝐞̈',
    'Ì': '𝐈̀', 'Í': '𝐈́', 'Î': '𝐈̂', 'Ï': '𝐈̈',
    'ì': '𝐢̀', 'í': '𝐢́', 'î': '𝐢̂', 'ï': '𝐢̈',
    'Ò': '𝐎̀', 'Ó': '𝐎́', 'Ô': '𝐎̂', 'Õ': '𝐎̃', 'Ö': '𝐎̈',
    'ò': '𝐨̀', 'ó': '𝐨́', 'ô': '𝐨̂', 'õ': '𝐨̃', 'ö': '𝐨̈',
    'Ù': '𝐔̀', 'Ú': '𝐔́', 'Û': '𝐔̂', 'Ü': '𝐔̈',
    'ù': '𝐮̀', 'ú': '𝐮́', 'û': '𝐮̂', 'ü': '𝐮̈',
    'Ç': '𝐂̧', 'ç': '𝐜̧',
    'Ñ': '𝐍̃', 'ñ': '𝐧̃',
    'ß': '𝐬𝐬',
    '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔', '7': '𝟕', '8': '𝟖', '9': '𝟗',
    '.': '.', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!', '\'': '\'', '"': '"',
    '(': '(', ')': ')', '[': '[', ']': ']', '{': '{', '}': '}',
    '<': '<', '>': '>', '/': '/', '\\': '\\', '|': '|', '-': '-', '_': '_', '=': '=', '+': '+',
    '@': '@', '#': '#', '$': '$', '%': '%', '^': '^', '&': '&', '*': '*', '~': '~',
    ' ': ' ', '\n': '\n'
};  

const italicMap: Record<string, string> = {
  'A': '𝐴', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝐸', 'F': '𝐹', 'G': '𝐺',
  'H': '𝐻', 'I': '𝐼', 'J': '𝐽', 'K': '𝐾', 'L': '𝐿', 'M': '𝑀', 'N': '𝑁',
  'O': '𝑂', 'P': '𝑃', 'Q': '𝑄', 'R': '𝑅', 'S': '𝑆', 'T': '𝑇', 'U': '𝑈',
  'V': '𝑉', 'W': '𝑊', 'X': '𝑋', 'Y': '𝑌', 'Z': '𝑍',
  'a': '𝑎', 'b': '𝑏', 'c': '𝑐', 'd': '𝑑', 'e': '𝑒', 'f': '𝑓', 'g': '𝑔',
  'h': 'ℎ', 'i': '𝑖', 'j': '𝑗', 'k': '𝑘', 'l': '𝑙', 'm': '𝑚', 'n': '𝑛',
  'o': '𝑜', 'p': '𝑝', 'q': '𝑞', 'r': '𝑟', 's': '𝑠', 't': '𝑡', 'u': '𝑢',
  'v': '𝑣', 'w': '𝑤', 'x': '𝑥', 'y': '𝑦', 'z': '𝑧',
  'À': '𝐴̀', 'Á': '𝐴́', 'Â': '𝐴̂', 'Ã': '𝐴̃', 'Ä': '𝐴̈', 'Å': '𝐴̊',
  'à': '𝑎̀', 'á': '𝑎́', 'â': '𝑎̂', 'ã': '𝑎̃', 'ä': '𝑎̈', 'å': '𝑎̊',
  'È': '𝐸̀', 'É': '𝐸́', 'Ê': '𝐸̂', 'Ë': '𝐸̈',
  'è': '𝑒̀', 'é': '𝑒́', 'ê': '𝑒̂', 'ë': '𝑒̈',
  'Ì': '𝐼̀', 'Í': '𝐼́', 'Î': '𝐼̂', 'Ï': '𝐼̈',
  'ì': '𝑖̀', 'í': '𝑖́', 'î': '𝑖̂', 'ï': '𝑖̈',
  'Ò': '𝑂̀', 'Ó': '𝑂́', 'Ô': '𝑂̂', 'Õ': '𝑂̃', 'Ö': '𝑂̈',
  'ò': '𝑜̀', 'ó': '𝑜́', 'ô': '𝑜̂', 'õ': '𝑜̃', 'ö': '𝑜̈',
  'Ù': '𝑈̀', 'Ú': '𝑈́', 'Û': '𝑈̂', 'Ü': '𝑈̈',
  'ù': '𝑢̀', 'ú': '𝑢́', 'û': '𝑢̂', 'ü': '𝑢̈',
  'Ç': '𝐶̧', 'ç': '𝑐̧',
  'Ñ': '𝑁̃', 'ñ': '𝑛̃',
  'ß': '𝑠𝑠',
  '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔', '7': '𝟕', '8': '𝟖', '9': '𝟗',
  '.': '.', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!', '\'': '\'', '"': '"',
  '(': '(', ')': ')', '[': '[', ']': ']', '{': '{', '}': '}',
  '<': '<', '>': '>', '/': '/', '\\': '\\', '|': '|', '-': '-', '_': '_', '=': '=', '+': '+',
  '@': '@', '#': '#', '$': '$', '%': '%', '^': '^', '&': '&', '*': '*', '~': '~',
  ' ': ' ', '\n': '\n'
};

function transformText(text: string, styles: StyleType[]): string {
    let finalStyle: StyleType | null = null;
  
    if (styles.includes("bold")) {
      finalStyle = "bold";
    } else if (styles.includes("italic")) {
      finalStyle = "italic";
    } 
  
    return text.split("").map((char) => {
      let transformedChar = char;
      if (finalStyle === "bold" && boldMap[transformedChar]) {
        transformedChar = boldMap[transformedChar];
      } else if (finalStyle === "italic" && italicMap[transformedChar]) {
        transformedChar = italicMap[transformedChar];
      }
      return transformedChar;
    }).join("");
}

interface UseTextStyles {
  isBold: boolean;
  isItalic: boolean;
  toggleBold: () => void;
  toggleItalic: () => void;
  handleInput: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  applySelectedStyle: (style: StyleType) => void; 
}

export function useTextStyles(
  inputRef: RefObject<HTMLTextAreaElement>,
  value: string,
  onChange: (newValue: string) => void
): UseTextStyles {
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const toggleBold = useCallback(() => {
    setIsBold(prev => !prev);
    setIsItalic(false);
  }, []);
    
  const reverseBoldMap = Object.fromEntries(
    Object.entries(boldMap).map(([normalChar, boldChar]) => [boldChar, normalChar])
  );
  
  const reverseItalicMap = Object.fromEntries(
    Object.entries(italicMap).map(([normalChar, italicChar]) => [italicChar, normalChar])
  );
    
  function isBoldText(text: string) {
    // On considère le texte comme "entièrement gras" si tous les caractères sont dans le reverseBoldMap
    return [...text].every(char => reverseBoldMap[char] !== undefined || char === '\n' || char === ' ');
  }
  
  function isItalicText(text: string) {
    return [...text].every(char => reverseItalicMap[char] !== undefined || char === '\n' || char === ' ');
  }
    
  function revertBoldText(text: string) {
    return [...text].map(char => reverseBoldMap[char] || char).join('');
  }
  
  function revertItalicText(text: string) {
    return [...text].map(char => reverseItalicMap[char] || char).join('');
  }

  const toggleItalic = useCallback(() => {
    setIsItalic(prev => !prev);
    setIsBold(false);
  }, []);

  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const applySelectedStyle = useCallback((style: StyleType) => {
    if (!inputRef.current) return;
    const { selectionStart, selectionEnd } = inputRef.current;
    if (selectionStart === null || selectionEnd === null || selectionStart === selectionEnd) {
      return;
    }
  
    const before = value.slice(0, selectionStart);
    const selected = value.slice(selectionStart, selectionEnd);
    const after = value.slice(selectionEnd);
  
    let newValue;
    if (style === "bold") {
      if (isBoldText(selected)) {
        // Le texte est déjà en gras, on le remet en normal
        const reverted = revertBoldText(selected);
        newValue = before + reverted + after;
      } else {
        // Le texte n'est pas en gras, on le met en gras
        const transformed = transformText(selected, ["bold"]);
        newValue = before + transformed + after;
      }
    } else if (style === "italic") {
      if (isItalicText(selected)) {
        // Le texte est déjà en italique, on le remet en normal
        const reverted = revertItalicText(selected);
        newValue = before + reverted + after;
      } else {
        // Le texte n'est pas en italique, on le met en italique
        const transformed = transformText(selected, ["italic"]);
        newValue = before + transformed + after;
      }
    } else {
      return;
    }
  
    onChange(newValue);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = selectionStart;
        inputRef.current.selectionEnd = selectionStart + (newValue.length - (before.length + after.length));
      }
    }, 0);
  }, [inputRef, value, onChange]);
    
  return {
    isBold,
    isItalic,
    toggleBold,
    toggleItalic,
    handleInput,
    applySelectedStyle
  };
}