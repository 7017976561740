export const useUserChoice = (setShowInputMessage: React.Dispatch<React.SetStateAction<boolean | null>>) => {
    const handleAIButtonClick = () => {
        setShowInputMessage(true);
    };
      
    const handleManualButtonClick = () => {
        setShowInputMessage(false);
    };

    return {
        handleAIButtonClick,
        handleManualButtonClick
    }
}