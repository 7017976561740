import React, { useEffect } from "react";

import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

type Option = { label: string; value: string; disabled?: boolean };
type Props = {
  list: Option[];
  value: string;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  placeHolder?: string;
  error?: string;
};

export default function Select({ list, onChange, value, disabled, placeHolder, error }: Props) {

  // useEffect(() => {
  //   // Pour prendre le compte la possibilité de publier directement un post
  //   // Pour le Select appelé dans PostGeneratorForm.tsx ligne 584
  //   // A REFACTO
  //   if (disabled && value !== "publish_now") {
  //     onChange(null)
  //   }
  // }, [disabled])

  let className = "flex flex-1 w-full border-gray-300 rounded-md shadow-sm form-input"

  if (error) {
    className += " border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
  } else { 
    className += " focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
  }

  return (
    <Listbox disabled={Boolean(disabled)} value={value} onChange={onChange} by="label">
      <div
        className={`relative `}
      >
        <Listbox.Button className={className} autoFocus={Boolean(error)}>
          <span className={`block truncate ${!value && "text-gray-500"} pr-4 `}>{list.find(o => o.value === value)?.label || placeHolder || "Aucun"}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute left-0 z-10 w-full py-2 mt-1 overflow-scroll origin-top-left bg-white border-gray-300 rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none max-h-64">
            {list.map((option) => (
              <Listbox.Option
                key={option.label}
                className={({ active }) =>
                  `relative select-none cursor-pointer pl-3 py-1 ${active ? " bg-indigo-500 text-white" : "bg-white"
                  } ${option.disabled ? "text-gray-300" : "text-gray-900"}`
                }
                value={option.value}
                disabled={option.disabled}
              >
                <span className={`block truncate`}>{option.label}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
        {error && <small className="mt-2 text-sm text-red-500">{error}</small>}
      </div>
    </Listbox>
  );
}
