import React from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

const CustomPopover = ({
  children,
  button,
  position = "bottom",
  panelClasses = "",
}: {
  children: React.ReactNode;
  classes?: string;
  button?: React.ReactNode;
  position?: "top" | "bottom" | "left" | "right" | "bottom-center";
  panelClasses?: string;
}) => {
  return (
    <Popover className="relative">
      <PopoverButton>{button}</PopoverButton>

      <PopoverPanel
        className={`absolute z-10
          ${position == "top" ? "bottom-full mb-2" : ""}
          ${position == "left" ? "top-0 right-full mr-2" : ""}
          ${position == "right" ? "top-0 left-full ml-2" : ""}
          ${position == "bottom" ? "top-full mt-2" : ""}
          ${
            position == "bottom-center"
              ? "top-full right-1/2 transform translate-x-6"
              : ""
          }
              ${panelClasses}`}>
        {children}
      </PopoverPanel>
    </Popover>
  );
};

export default CustomPopover;
