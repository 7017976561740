import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sourceNode", "container"]


  addNode() {
    this.containerTarget.appendChild(this.sourceNodeTarget.cloneNode(true))
  }


}
