import React, { InputHTMLAttributes, useEffect } from "react";
import FormField from "./FormField";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  label?: string;
  hint?: string;
  error?: string;
  readonly?: boolean;
  suffix?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ title, label, hint, error, readonly, disabled, suffix, ...rest }, ref) => {
    return (
      <FormField title={title} hint={hint} disabled={disabled} error={error}>
        <input
          ref={ref}
          disabled={readonly}
          className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${error
            ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
            }`}
          type="text"
          {...rest}
        />
        {suffix &&
          <span className="absolute text-gray-500 top-0 right-0 inline-flex items-center px-3 text-sm bg-gray-200 border border-e-0 border-gray-300 rounded-r-md h-full">
            {suffix}
          </span>}
      </FormField>
    );
  }
);

export default Input;
