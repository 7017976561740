import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ContentMediaUploader from "./ContentMediaUploader";
import FacebookPreview from "./FacebookPreview";
import FocusSelector from "./FocusSelector";
import InsertableTextInput from "./InsertableTextInput";
import Select from "./Select";
import TagSelector from "./TagSelector";
import { getCSRFToken, formatMediaHolders } from "./utils";
import { BoltIcon } from "@heroicons/react/24/solid";
import { Content, isPersisted, TagGroup, MediaHolder } from "../types";
import FormField from "./FormField";
import UrlScraper from "./UrlScraper";
import FormLayout from "./FormLayout";
import DatePicker from "./DatePicker";
import Spinner from "./Spinner";
import OpenAiForm from "./OpenAiForm";
import ChoiceButton from "./ChoiceButton";
import { useIntl, FormattedMessage } from "react-intl";
import { gql, GraphQLClient } from "graphql-request";
import { useOpenAI } from "../hook/useOpenAI";
import { useUserChoice } from "../hook/useUserChoice";

type Props = {
  content: Content;
  tag_groups: TagGroup[];
  example_account: {
    displayed_name: string;
    picture_url: string;
    company: {
      email: string;
      company: string;
      phone: string;
      hashtag: string;
      website: string;
      displayed_name: string;
      client_code: string;
    };
  };
  content_dispenser?: { id: number };
  readonly?: boolean;
};
export default function ContentForm(props: Props) {

  const { body = { body_media: [] }, ...content } = props.content;
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      content: {
        ...content,
        description: props.content.body?.link_description,
      },
    },
  });

  const intl = useIntl()

  const minDate = new Date();
  const [submitted, setSubmitted] = useState(false)
  const [prompt, setPrompt] = useState<string>("")
  const [loading, setLoading] = useState(false)
  const [showInputMessage, setShowInputMessage] = useState<boolean | null>(false);
  const [media, setMedia] = useState(
    body.body_media
      .filter((m: MediaHolder) => m.content_medium.serialized_file)
      .sort(
        (a: MediaHolder, b: MediaHolder) =>
          (a.position || 0) - (b.position || 0)
        // Si undefined on met la valeur max (media.length)
      ) || []
  );

  const ctaOptions = [
    { label: "Commander", value: "order_now" },
    { label: "Acheter", value: "shop_now" },
    { label: "En savoir plus", value: "learn_more" },
    { label: "S'inscrire", value: "sign_up" },
    { label: "Appeler", value: "call_now" },
    { label: "Nous contacter", value: "contact_us" },
  ];

  const graphQLClient = new GraphQLClient('/graphql/', { headers: { "X-CSRF-Token": getCSRFToken() } })

  const onSubmit = async (data: any) => {
    setSubmitted(true)

    const bodyMedia = formatMediaHolders(media)

    const mutation = gql`
    mutation ($content: Content${content.id ? 'Update' : ''}Input!) {
      ${content.id ? 'update' : 'create'}Content(input: {content:$content}) {
        content {
          body {
            id
            message
            contentMedia {
              medium
            }
          }
        }
      }
    }`

    const variables = {
      content: {
        id: data.content.id || undefined,
        ...(props.content_dispenser && !props.content.id ? { author: { id: props.content_dispenser.id, type: "ContentDispenser" } } : {}),
        ...(data.content.tag_ids ? { tagIds: data.content.tag_ids } : {}),
        reconstructedUrl: data.content.reconstructed_url,
        focus: data.content.focus,
        expiresAt: data.content.expires_at,
        title: data.content.title,
        body: {

          linkCta: data.content.cta,
          linkUrl: data.content.reconstructed_url,
          linkDescription: data.content.description,
          //...("id" in body && { id: body.id }),
          message: data.content.teaser,
          bodyMediaAttributes: bodyMedia,
        }
      }
    }
    const response: any = await graphQLClient.request(mutation, variables)
    if (!response.errors) {
      window.Turbo?.visit(
        props.content_dispenser
          ? `/${intl.locale}/content_dispensers/${props.content_dispenser.id}/contents`
          : `/${intl.locale}/posts?display=calendar`
      );
    }
    setSubmitted(false)
  };

  const focus = watch("content.focus");
  const teaser = watch("content.teaser");
  const description = watch("content.description");
  const reconstructed_url = watch("content.reconstructed_url");
  const previewCta = ctaOptions.find(
    (cta) => cta.value === watch("content.cta")
  )?.label;

  const videoPresent =
    media.filter((m) => m.content_medium.serialized_file.resource_type === "video").length > 0;
  const imagesPresent =
    media.filter((m) => m.content_medium.serialized_file.resource_type === "image").length > 0;

  const generateFocusHint = () => {
    switch (focus) {
      case "images":
        return "Limites au nombre d'images -> Facebook/Instagram: 10, Linkedin: 9, Pinterest: 5, Twitter: 4, Google: 1."
      case "image":
        return "⚠️ Image obligatoire pour publier sur Instagram et Pinterest"
      default:
        return "";
    }
  }

  const { SubmitOpenAI, disabledInput, error } = useOpenAI(
    prompt,
    loading,
    setLoading,
    media.map((m) => m.content_medium),
    setValue,
    setShowInputMessage,
    getValues,
    "content.teaser",
    `/body/openai_call`
  );

  const { handleAIButtonClick, handleManualButtonClick } = useUserChoice(setShowInputMessage);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        title={intl.formatMessage({ id: "content_form.publication", defaultMessage: "Publication" })}
        description={intl.formatMessage({ id: "content_form.edit_content", defaultMessage: "Éditez la forme et le contenu de votre nouvelle campagne" })}
        leftSideElements={
          <>
            <FacebookPreview
              focus={focus}
              teaser={teaser}
              cta={previewCta}
              description={description}
              reconstructed_url={reconstructed_url}
              exampleAccount={props.example_account}
              media={media.filter((m) => !(isPersisted(m) && m.destroy)).map(m => m.content_medium)}
            />
            {!content.id && (
              <div>
                <UrlScraper
                  creationUrl={`/${props.content_dispenser
                    ? `content_dispensers/${props.content_dispenser.id}/`
                    : ""
                    }contents`}
                  redirectionCallback={(id) =>
                    `/${props.content_dispenser
                      ? `content_dispensers/${props.content_dispenser.id}/`
                      : ""
                    }contents/${id}/edit`
                  }
                />
              </div>
            )}

            <div className="hidden lg:block">
              <h3 className="flex items-baseline text-sm font-semibold leading-6 text-gray-700">
                <BoltIcon className="w-3 h-3 mr-1" />
                <FormattedMessage id="content_form.tips" defaultMessage="Astuce" />
              </h3>
              <p className="text-sm text-gray-500">
                Utilisez les mots clés{" "}
                {[
                  "$NOM",
                  "$ENTREPRISE",
                  "$SITE",
                  "$MAIL",
                  "$TEL",
                  "$HASHTAG",
                  "$CODE"
                ].map((v) => (
                  <>
                    <span className="font-medium text-gray-700">{v}</span>
                    {", "}
                  </>
                ))}
                <FormattedMessage id="content_form.custom_your_publications" defaultMessage="pour personnaliser vos publications avec les valeurs correspondantes chez chaque affilié." />
              </p>
            </div>
          </>
        }
      >
        <FormField
          title={intl.formatMessage({ id: "content_form.format", defaultMessage: "Format" })}
          hint={intl.formatMessage({ id: "content_form.hint", defaultMessage: "Décide de l'élément sur lequel l'accent sera mis. Selon les plateformes, cela affectera la présentation de votre campagne. Seul le format vidéo accepte les vidéos." })}
        >
          <FocusSelector
            inputName="content.focus"
            register={register}
            selected={focus}
            videoPresent={videoPresent}
            imagesPresent={imagesPresent}
            onSelectCallback={async (e) => {
              if (e.target.value !== "link") {
                setValue("content.cta", null)
              }
              isSubmitted ? await trigger("content.reconstructed_url") : null
            }
            }
          />
        </FormField>

        <FormField
          title={intl.formatMessage({ id: "content_form.title", defaultMessage: "Titre" })}
          hint={intl.formatMessage({ id: "content_form.title_hint", defaultMessage: "Le titre de votre publication." })}
          error={errors?.content?.title?.message as string | undefined}
        >
          <input
            {...register("content.title", {
              required: intl.formatMessage({ id: "content_form.title_cant_be_blank", defaultMessage: "Le titre ne peut pas être vide" }),
            })}
            className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.content?.title
              ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
              }`}
            type="text"
          // id="content_content_attributes_title"
          />
        </FormField>
        <div>
          <ChoiceButton
            showInputMessage={showInputMessage}
            handleAiButtonClick={handleAIButtonClick}
            handleManualButtonClick={handleManualButtonClick}
          />
        </div>
        {
          showInputMessage === true ?
            (
              <div>
                <OpenAiForm
                  prompt={prompt}
                  message="Entrez le sujet de votre post pour générer du texte, donner du contexte sur le message que vous souhaitez générer (limite: 30 par mois)"
                  placeholder="Exemple: 'Rédigez un article sur les avantages de vivre à Paris 17ème.'"
                  setPrompt={setPrompt}
                  loading={disabledInput}
                  error={error}
                  Submit={SubmitOpenAI}
                />
              </div>
            ) : (
              <FormField
                title="Message"
                hint={intl.formatMessage({ id: "content_form.associated_text", defaultMessage: "Le texte associé à votre contenu." })}
                error={errors?.content?.teaser?.message as string | undefined}
              >
                <InsertableTextInput
                  {...register("content.teaser", {
                    required: intl.formatMessage({ id: "content_form.message_cant_be_blank", defaultMessage: "Le message ne peut pas être vide" }),
                  })}
                  onInsertionCallback={() =>
                    isSubmitted ? trigger("content.teaser") : null
                  }
                  rows={5}
                  inputType="textarea"
                  emojis={true}
                  setValue={setValue}
                  insertionVariables={[
                    "$NOM",
                    "$ENTREPRISE",
                    "$SITE",
                    "$MAIL",
                    "$TEL",
                    "$HASHTAG",
                    "$CODE",
                    "$SPONSORLINK",
                    "$PROMOCODE",
                  ]}
                  className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.content?.teaser
                    ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                    : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                    }`}
                />
              </FormField>
            )
        }
        <FormField
          title={intl.formatMessage({ id: "content_form.short_description", defaultMessage: "Courte description" })}
        >
          <textarea
            {...register("content.description")}
            className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.content?.description
              ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
              }`}
            rows={2}
          />
        </FormField>

        <FormField
          title={intl.formatMessage({ id: "content_form.media", defaultMessage: "Média" })}
          error={errors?.content?.focus?.message}
          hint={generateFocusHint()}
        >
          {/* On prend les erreurs de focus car elles concernent les media */}
          <ContentMediaUploader
            inputProps={{
              multiple: true,
              accept: focus === "video" ? "video/*" : "image/*",
            }}
            innerInfos={
              focus === "video" ? "MP4, WEBM" : "PNG, JPG jusqu'à 10MB"
            }
            setMedia={(m) => {
              setMedia(m);
              console.log("mais à la fin");
              // On trigger le focus pour revalider les changements
              trigger("content.focus");
            }}
            media={media}
            error={errors?.content?.focus?.message}
          />
        </FormField>

        <FormField
          title={intl.formatMessage({ id: "content_form.link", defaultMessage: "Lien" })}
          hint={intl.formatMessage({ id: "content_form.warning_google", defaultMessage: "⚠️ Impossible de publier un contenu sur Google si vous indiquez le numéro de téléphone" })}
          error={
            errors?.content?.reconstructed_url?.message as string | undefined
          }
        >
          <InsertableTextInput
            {...register("content.reconstructed_url", {
              validate: (value: string | undefined) => {
                if (!value && getValues("content.focus") === "link") {
                  return "En selectionnant l'expérience lien, vous devez indiquer un lien.";
                } else {
                  return true;
                }
              },
            })}
            onInsertionCallback={() =>
              isSubmitted ? trigger("content.reconstructed_url") : null
            }
            emojis={false}
            setValue={setValue}
            insertionVariables={["$SITE", "$CODE", " $TEL", "$SPONSORLINK"]}
            className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.content?.reconstructed_url
              ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
              }`}
          />
        </FormField>

        {props.tag_groups && props.tag_groups.length > 0 && (
          <FormField
            title="Tags"
          // hint="⚠️ Impossible de publier un contenu sur Google si vous indiquez le numéro de téléphone"
          >
            <Controller
              name="content.tag_ids"
              control={control}
              render={({ field }) => (
                <TagSelector
                  onChange={(option) => field.onChange(option)}
                  tagGroups={props.tag_groups}
                  selected={field.value}
                />
              )}
            />
          </FormField>
        )}
        <FormField
          title={intl.formatMessage({ id: "content_form.action_btn", defaultMessage: "Bouton d'action" })}
        >
          <Controller
            name="content.cta"
            control={control}
            // defaultValue={content&.content_attributes&.cta || null}
            render={({ field }) => (
              <Select
                onChange={(option) => field.onChange(option)}
                list={ctaOptions}
                value={field.value}
                disabled={focus !== "link"}
              />
            )}
          />
        </FormField>
        {props.content_dispenser && (
          <FormField
            title={intl.formatMessage({ id: "content_form.expiration", defaultMessage: "Expiration" })}
            hint={intl.formatMessage({ id: "content_form.keep_empty", defaultMessage: "Laisser vide si le contenu ne périme pas." })}
          >
            <Controller
              name="content.expires_at"
              control={control}
              render={({ field: { onChange } }) => (
                <DatePicker
                  onChange={onChange}
                  error={errors?.content?.expires_at?.message}
                  options={{ minDate }}
                  defaultValue={
                    content.expires_at
                      ? new Date(content.expires_at).toLocaleString("fr")
                      : undefined
                  }
                />
              )}
            />
          </FormField>
        )}
      </FormLayout>

      <div className="flex justify-end pb-16">
        <button
          disabled={submitted}
          className="relative btn-brand-primary"
          type="submit"
        >
          <Spinner className={`absolute ${!submitted && "invisible"} w-5 h-5 mb-1 ml-4 text-white animate-spin`} />
          <span className={` ${submitted && "invisible"}`}>
            <FormattedMessage id="content_form.save" defaultMessage="Enregistrer" />
          </span>
        </button>
      </div>
    </form>
  );
}
