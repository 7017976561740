// Vite exécute ce fichier, qui appelle tous les autres

console.log('Vite ⚡️ Rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
//


import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
// Stimulus controllers
import PasswordVisibility from 'stimulus-password-visibility'
import Popover from 'stimulus-popover'
import Reveal from 'stimulus-reveal-controller'
import Sortable from 'stimulus-sortable'
// Alpine
import Alpine from 'alpinejs'
// Shepherd
import Shepherd from 'shepherd.js'
import Abraham from '../components/abraham'
import Cookies from 'js-cookie';
// Turbo
import * as Turbo from '@hotwired/turbo'
// Turbo Mount
import "../turbo-mount"

Turbo.start()

window.Cookies = Cookies;
window.Abraham = new Abraham();
window.Shepherd = Shepherd;

const application = Application.start()
const controllers = import.meta.glob([
	'./../controllers/*.ts',
	'./../controllers/*.js',
	'./../controllers/*.tsx',
	'./../controllers/*.jsx'
], { eager: true })

registerControllers(application, controllers)
application.register('sortable', Sortable)
application.register('password-visibility', PasswordVisibility)
application.register('popover', Popover)
application.register('reveal', Reveal)


window.Alpine = Alpine
Alpine.start()
