import React from "react";

type Props = {
  leftSideElements?: React.ReactNode;
  children?: React.ReactNode;
  title?: string;
  description?: string;
};
function FormLayout({ leftSideElements, children, title, description }: Props) {
  return (
    <div className="px-4 py-5 mb-8 bg-white shadow sm:rounded-lg sm:p-6 md:grid md:grid-cols-3 md:gap-16">
      <div id="left-0" className= "md:col-span-1 space-y-8">
        <div>
          {title && (
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
          )}
          {description && (
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          )}
        </div>
        {leftSideElements}
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2 space-y-8">{children}</div>
    </div>
  );
}

export default FormLayout;
