import { EyeDropperIcon } from "@heroicons/react/24/solid";
import React from "react";
import * as CSS from "csstype";

type Props = {
  onChange: (color: CSS.Property.BackgroundColor) => void;
  value: string | null;
  error?: string;
  name: string;
};

export default function ColorPicker({ value, onChange, name }: Props) {
  const color = value || undefined;

  const rgbaToHex = (color: string): string => {
    if (/^rgb/.test(color)) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  
      // rgb to hex
      // eslint-disable-next-line no-bitwise
      let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
        .toString(16)
        .slice(1)}`;
  
      // added alpha param if exists
      if (rgba[4]) {
        const alpha = Math.round(0o1 * 255);
        const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
        hex += hexAlpha;
      }
  
      return hex;
    }
    return color;
  };

  return (
    <div className="mt-2 relative h-10 w-fit ">
      <div
        style={{ backgroundColor: color }}
        className="absolute top-0 left-0 m-2 w-5 h-5 rounded-sm"
      ></div>
      <input
        value={color}
        type="color"
        onChange={(e) => onChange(rgbaToHex(e.target.value))}
        className="sr-only peer "
        placeholder="#ffffff"
        id={`colorpicker-${name}`}
      />

      <div className="pl-10 pr-12 w-full h-full text-sm form-input flex flex-1 rounded-md border-gray-300 shadow-sm peer-focus:border-brand_focus peer-focus:ring peer- peer-focus:ring-opacity-20 ">
        {color || " "}
      </div>

      <label
        htmlFor={`colorpicker-${name}`}
        className="group/button h-full cursor-pointer absolute top-0 right-0 p-2 rounded-r-md border  border-gray-300 bg-white py-1.5 px-2.5 text-sm text-gray-700 shadow-sm   hover:bg-gray-50  peer-focus:border-brand_focus focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20 "
      >
        <span className="mb-2">
          <EyeDropperIcon className="group-focus/button:fill-brand_main inline h-4 w-4" />
        </span>
      </label>
    </div>
  );
}
