import React, { useState } from "react";
import { UseFormRegister } from "react-hook-form/dist/types";
import {
  GoogleLogo,
  FacebookLogo,
  LinkedinLogo,
  TikTokLogo,
  TwitterLogo,
  PinterestLogo,
  InstagramLogo,
} from "./channelIcons";

type Props = {
  selected: Array<string>; // car l'input renvoie des strings de nombre
  channels: { identifier: string; id: number }[];
  register: UseFormRegister<any>;
  inputName: string;
};

function ChannelSelector({ register, channels, inputName, selected }: Props) {
  return (
    <div className="my-2 space-x-4 flex  flex-wrap">
      {channels.map(
        ({ identifier, id }: { identifier: string; id: number }) => {
          return (
            <label
              key={id}
              className={`flex items-center relative cursor-pointer`}
            >
              <ChannelLogo
                identifier={identifier}
                active={selected.includes(id.toString())}
              />
              <input
                {...register(inputName)}
                className="hidden"
                value={id}
                type="checkbox"
              />
            </label>
          );
        }
      )}
    </div>
  );
}

export const ChannelLogo = ({
  identifier,
  active,
  size,
  ...props
}: {
  identifier: string;
  active: boolean;
  size?: number;
  [key: string]: any;
}) => {
  const stringifySize = `w-${size} h-${size}`
  switch (identifier) {
    case "google_my_business":
      return (
        <GoogleLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-google"
              : "fill-gray-400 opacity-60 hover:fill-google"
          }`}
        />
      );
    case "facebook":
      return (
        <FacebookLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-facebook"
              : "fill-gray-400 opacity-60 hover:fill-facebook"
          }`}
        />
      );
    case "linkedin":
      return (
        <LinkedinLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-linkedin"
              : "fill-gray-400 opacity-60 hover:fill-linkedin"
          }`}
        />
      );
    case "instagram":
      return (
        <InstagramLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-instagram"
              : "fill-gray-400 opacity-60 hover:fill-instagram"
          }`}
        />
      );
    case "pinterest":
      return (
        <PinterestLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-pinterest"
              : "fill-gray-400 opacity-60 hover:fill-pinterest"
          }`}
        />
      );
    case "tiktok":
      return (
        <TikTokLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-tiktok"
              : "fill-gray-400 opacity-60 hover:fill-tiktok"
          }`}
        />
      );
    case "twitter":
      return (
        <TwitterLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-twitter"
              : "fill-gray-400 opacity-60"
          }`}
        />
      );
    default:
      return (
        <FacebookLogo
          className={`${size ? stringifySize : "w-6 h-6"} ${
            active
              ? "fill-facebook"
              : "fill-gray-400 opacity-60 hover:fill-facebook"
          }`}
        />
      );
  }
};

// Composant

export default ChannelSelector;
