import Popover from "stimulus-popover";
import { useClickOutside } from "stimulus-use";
import { computePosition, autoPlacement } from "@floating-ui/dom";
import { auto } from "@cloudinary/url-gen/qualifiers/quality";

export default class extends Popover {
  // static targets = this.targets.concat(['anchor'])
  static values = { placement: String, autoplacement: Boolean }

  connect() {
    super.connect();
  }

  disconnect() {
    if (this.hasCardTarget) {
      // Éviter que le truc reste là en cas de retour sur la page
      this.hide()
    }
    super.disconnect();
  }

  // On recopie la méthode show de Popover pour pouvoir l'adapter (ajout d'une anchor séparée)
  async show(event) {
    console.log(this.placementValue)
    event.preventDefault()
    // Temporally variable to prevent `event.currentTarget` to being null.
    let content = null;
    let anchor = this.element;

    if (this.hasContentTarget) {
      content = this.contentTarget.innerHTML;
    } else {
      content = await this.fetch();
    }

    if (!content) return;

    const fragment = document.createRange().createContextualFragment(content);

    anchor.appendChild(fragment);


    if (this.hasCardTarget) {
      // on utilise floating ui pour positionner le popover
      computePosition(anchor, this.cardTarget, {
        placement: "bottom-start" || "right", middleware: [this.autoplacementValue && autoPlacement()]
      }).then(({ x, y }) => {
        Object.assign(this.cardTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
      // On installe le clickOutside
      useClickOutside(this, { element: this.cardTarget });
    }
    console.log("show");
  }

  clickOutside() {
    console.log("clickOutside");
  }

  hide(e) {
    console.log("hide");
    super.hide()
  }

}
