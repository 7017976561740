import { Controller } from "@hotwired/stimulus"
import { getCSRFToken } from "../components/utils";

// TEMPORAIRE POUR GERER BUG TARGETS CONTENT

export default class extends Controller {
  static values = { contentId: String }
  static targets = ["toLoad", "loader"]

  createContent() {
    fetch(`/contents/${this.contentIdValue}/duplicate_with_ownership`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": getCSRFToken(),
      },
      body: JSON.stringify({
        content: {
          content_type: "post_generator",
          content_id: this.contentIdValue,
        },
      }),
    })
      .then(response => response.json())
      .then((data) => {
        if (data.id) {
          window.Turbo?.visit(
            `/post_generators/new?instantiate_from[type]=content&instantiate_from[id]=${data.id}`
          )
        };
      })
  }

  insertLoader() {
    const loader = `<svg class="animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
              <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-90" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>`
    this.loaderTarget.insertAdjacentHTML("afterbegin", loader)
  }
  // pendant que ça ce se fait il faut loader un truc histoire de dire
  // patienter un peu
  // pour ça on va targeter le bouton et lui mettre un loader à la place

  createNewPg() {
    this.toLoadTarget.classList.add("invisible")
    this.insertLoader()
    this.createContent()
  }
}
