import React, { useEffect, useMemo, useRef, useState } from "react";
import { ProvisionalContentMedium } from "../types";
import Modal from "./Modal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useIsMobile } from "../hook/useIsMobile";
import { ContentMediumIcon, FileIcon } from "./icons";
import CustomCloseButton from "./CustomCloseButton";
import useMultiCloudinaryUpload from "./useMultiCloudinaryUpload";
import { Cloudinary } from "@cloudinary/url-gen";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";
import { AdvancedImage } from "@cloudinary/react";
import { CloudinaryFile } from "../types/cloudinary_custom_types";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/16/solid";
import { Transition } from "@headlessui/react";
import ImagePlaceholder from "./ImagePlaceholder";

const cld = new Cloudinary({
  cloud: {
    cloudName: "dcmekntwa",
  },
});
type MediaProps = {
  cm: ProvisionalContentMedium;
  index: number;
  deleteContentMedia: (
    contentMediaId: ProvisionalContentMedium["provisional_id"]
  ) => void;
  changePosition: (cm: ProvisionalContentMedium, index: number) => void;
};
const DraggableMedia = ({
  cm,
  index,
  deleteContentMedia,
  changePosition,
}: MediaProps) => {
  const { resource_type, public_id, format } = cm.serialized_file;
  const ref = useRef<HTMLDivElement>(null);
  const [_, dragRef] = useDrag(
    () => ({
      type: "drag_item",
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: (monitor: any) => {
        return { sourceContentMedia: cm };
      },
    }),
    []
  );
  type DragItem = { sourceContentMedia: ProvisionalContentMedium };
  const [{ isDragging }, drop] = useDrop<
    DragItem,
    void,
    { isDragging: boolean }
  >({
    accept: "drag_item",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      changePosition(item.sourceContentMedia, index + 1);
    },
    drop(item: DragItem, monitor: any) {
      changePosition(item.sourceContentMedia, index + 1);
    },
  });
  dragRef(drop(ref));

  return (
    <div
      ref={ref}
      key={`media_${cm?.provisional_id}_${index}`}
      className={`relative flex justify-center items-center rounded-md border size-32 group`}
      data-provisional-id={cm?.provisional_id}
    >
      <button
        type="button"
        className={`absolute -top-1 -right-1 bg-gray-100 rounded-sm cursor-pointer hidden group-hover:flex duration-500`}
        onClick={() => deleteContentMedia(cm?.provisional_id)}
      >
        <XMarkIcon className="size-5" />
      </button>
      <AdvancedImage
        className={`object-center object-cover h-full w-full bg-white`}
        cldImg={cld
          .image(public_id)
          .setAssetType(resource_type)
          .format(resource_type === "video" ? "jpg" : format)
          .resize(thumbnail().width(320))}
      />
    </div>
  );
};

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  accept?: string;
  hContentMedia: Record<number, ProvisionalContentMedium>;
  setHContentMedia: React.Dispatch<
    React.SetStateAction<Record<number, ProvisionalContentMedium>>
  >;
  selectedContentMedias: ProvisionalContentMedium["provisional_id"][] | [];
  beforeRemoveFn: (
    contentMediaId: ProvisionalContentMedium["provisional_id"]
  ) => void;
  afterAddFn: (
    contentMediaIds: ProvisionalContentMedium["provisional_id"][]
  ) => void;
  afterMoveFn: (contentMediaId: number, newPosition: number) => void;
};

const ContentMediasUploader = ({
  open,
  setOpen,
  disabled,
  accept,
  hContentMedia,
  setHContentMedia,
  selectedContentMedias,
  beforeRemoveFn,
  afterAddFn,
  afterMoveFn,
}: Props) => {
  const addMedia = (files: CloudinaryFile[]) => {
    // Pourquoi seul un media est retenu ???
    const lastCMId =
      Math.max(...Object.keys(hContentMedia).map((k) => parseInt(k)), 0) + 1;
    const newCMs = files?.map((file, index) => {
      const newId = lastCMId + index;
      return [newId, { provisional_id: newId, serialized_file: file }];
    }) as [number, ProvisionalContentMedium][];
    setHContentMedia((prev) => ({ ...prev, ...Object.fromEntries(newCMs) }));
    afterAddFn(newCMs.map((c) => c[0]));
    // On initialise les fichiers pour ne plus afficher de Image Placeholder
    setFiles([]);
    // On reset l'animation progress pour éviter un effet visuel désagréable
    // des Image placeholder
    setAnimationProgress(null);
  };
  const deleteContentMedia = (contentMediaId: number) => {
    beforeRemoveFn(contentMediaId);
  };

  const changePosition = (
    contentMedia: ProvisionalContentMedium,
    newPosition: number
  ) => {
    afterMoveFn(contentMedia.provisional_id, newPosition);
  };

  const sortedCM: ProvisionalContentMedium[] = useMemo(
    () => selectedContentMedias?.map((cmId) => hContentMedia[cmId]),
    [selectedContentMedias, hContentMedia]
  );

  const sortedCmIds = useMemo(
    () => sortedCM?.map((cm) => cm.provisional_id),
    [sortedCM]
  );

  const addCmToBody = (cmId: ProvisionalContentMedium["provisional_id"]) => {
    const isAlreadyAdded = sortedCmIds.includes(cmId);
    if (!isAlreadyAdded) {
      afterAddFn([cmId]);
    } else {
      beforeRemoveFn(cmId);
    }
  };

  const isMobile = useIsMobile();

  // Real progress est l'état réel de l'upload
  const { totalProgress: realProgress, uploadFiles } = useMultiCloudinaryUpload(
    { addMedia }
  );

  const [isDragging, setIsDragging] = useState(false);

  // L'état de l'animation du placholder (état d'affichage) => source de vérité pour l'animation
  const [animationProgress, setAnimationProgress] = useState<number | null>(
    null
  );
  // L'état de l'upload faké (pour une animation plus fluide)
  const [fakeProgress, setFakeProgress] = useState<number | null>(null);
  // Ref pour mieux contrôler l'animation
  const requestRef = useRef<number | null>(null);

  useEffect(() => {
    // On initialise l'animation seulement si
    // fakeProgress est nul et
    // que realProgress n'est pas :
    // - NaN (cas où l'upload est finie à 100%)
    // - null (cas où l'upload n'a pas encore commencé)
    // - inferieur à 100 (cas ou l'upload est en cours et pas terminée)
    if (
      fakeProgress === null &&
      !isNaN(realProgress) &&
      realProgress !== null &&
      realProgress < 100
    ) {
      // Pour lancer l'animation, on installe fakeProgress à 0
      setFakeProgress(0);
    }

    const animate = () => {
      if (realProgress !== null && fakeProgress !== null && fakeProgress >= 0) {
        // On set l'état de l'animation en fonction de l'état réel
        if (realProgress > 0 && realProgress < 100) {
          // On prend toujours la valeur la plus faible
          // Pour éviter un effet de "retour en arrière"
          setAnimationProgress(Math.min(fakeProgress, realProgress));
        } else {
          // sinon on set la valeur de fakeProgress
          // Pour afficher une animation plus fluide
          setAnimationProgress(fakeProgress);
        }
        // On force le progression de l'animation
        setFakeProgress((prev) => (prev ?? 0) + 1);

        // Si l'animation progress (source de vérité de l'animation) est complète, on stop l'animation
        if (animationProgress !== null && animationProgress >= 100) {
          setFakeProgress(null);
          cancelAnimationFrame(requestRef.current as number);
        } else {
          requestRef.current = requestAnimationFrame(animate);
        }
      }
    };

    // On continue l'animation si realProgress (véritable upload) a commencé
    // OU quand on a lancé l'animation branchée sur fakeProgress
    if ((fakeProgress !== null && fakeProgress < 100) || realProgress < 100) {
      requestRef.current = requestAnimationFrame(animate);
    } else {
      // Sinon on stop l'animation
      setFakeProgress(null);
      cancelAnimationFrame(requestRef.current as number);
    }

    // Nettoyage de l'animation
    return () => cancelAnimationFrame(requestRef.current as number);
  }, [realProgress, fakeProgress]);

  const [files, setFiles] = useState<any[]>([]);

  return (
    <div
      className="flex w-full justify-start"
      // On désactive le comportement natif du navigateur sur les events de drag and drop
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDragging(false);
      }}
      onDrop={(e) => {
        e.preventDefault();
        setIsDragging(false);
        setFiles(Array.from(e.dataTransfer.files));
        uploadFiles(Array.from(e.dataTransfer.files));
      }}
    >
      {/* On affiche les content Medias affiliés au body */}
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <div className="w-full flex flex-wrap gap-2">
          {sortedCM?.map((cm, index) => (
            <DraggableMedia
              key={`cm_${cm?.provisional_id}_${index}`}
              cm={cm}
              index={index}
              deleteContentMedia={deleteContentMedia}
              changePosition={changePosition}
            />
          ))}
        </div>
      </DndProvider>
      <Modal open={open} setOpen={setOpen}>
        <div className="p-4 bg-white relative rounded-md w-80 sm:w-96 flex flex-col gap-3">
          <CustomCloseButton open={open} setOpen={setOpen} />
          {/* Suggestions des content medias existants */}
          <div className="text-center font-medium">
            {selectedContentMedias.length === 0
              ? "Ajoutez des médias"
              : `${selectedContentMedias.length} ${
                  selectedContentMedias.length === 1
                    ? "média sélectionné"
                    : "médias sélectionnés"
                }`}
          </div>
          <div className="flex gap-3 overflow-x-auto pt-2">
            {Object.values(hContentMedia)?.map((cm, index) => (
              <div
                className="relative"
                key={`cm_${cm?.provisional_id}_${index}`}
              >
                <AdvancedImage
                  className={`object-cover min-w-24 min-h-24 size-24 border rounded-md cursor-pointer transition-colors duration-300 ${
                    sortedCmIds?.includes(cm?.provisional_id)
                      ? "border-brand_main"
                      : "border-gray-300"
                  }`}
                  cldImg={cld
                    .image(cm.serialized_file.public_id)
                    .setAssetType(cm.serialized_file.resource_type)
                    .format(
                      cm.serialized_file.resource_type === "video"
                        ? "jpg"
                        : cm.serialized_file.format
                    )
                    .resize(thumbnail().width(320))}
                  onClick={() => addCmToBody(cm.provisional_id)}
                />
                <Transition
                  show={sortedCmIds.includes(cm?.provisional_id)}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <CheckIcon
                    style={{ top: "-8px", right: "-8px" }}
                    className="absolute size-6 text-green-500 bg-white rounded-full border border-green-500 p-1"
                  />
                </Transition>
              </div>
            ))}
            {/* Image Placeholder | On itère sur les files pour éviter les clignotements */}
            {/* N'apparait seulement lorsque l'upload commence */}
            {files?.map((_, i) => (
              <ImagePlaceholder
                key={i}
                animationProgress={animationProgress ?? 0}
                color="bg-brand_main"
              />
            ))}
          </div>
          <div
            className={`flex flex-col justify-center border-2 h-full min-h-32 border-dashed ${
              isDragging ? "border-brand_main" : ""
            }`}
          >
            <label className="clickable-text block">
              <FileIcon className="size-12 mx-auto" />
              <p className="text-center">Choisir des fichiers</p>
              <input
                accept={accept}
                disabled={disabled}
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  setFiles(
                    Array.from(e.target.files as FileList).map(
                      (file) => file.name
                    )
                  );
                  uploadFiles(Array.from(e.target.files as FileList));
                }}
                multiple={true}
              />
            </label>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContentMediasUploader;
