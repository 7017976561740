import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { gql, GraphQLClient } from "graphql-request";
import ChannelAccountSelector from "./ChannelAccountSelector";
import ContentMediaUploader from "./ContentMediaUploader";
import FacebookPreview from "./FacebookPreview";
import InsertableTextInput from "./InsertableTextInput";
import Select from "./Select";
import { getCSRFToken, formatMediaHolders } from "./utils";
import {
  BoltIcon,
  PaperAirplaneIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";

import {
  Channel,
  ContentMedium,
  isPersisted,
  ChannelAccount,
  CompanyContent,
  MediaHolder,
} from "../types";
import DatePicker from "./DatePicker";
import FormField from "./FormField";
import CharacterCountPost from "./CharacterCountPost";
import ChoiceButton from "./ChoiceButton";
import OpenAiForm from "./OpenAiForm";
import UrlScraper from "./UrlScraper";
import FormLayout from "./FormLayout";
import { useOpenAI } from "../hook/useOpenAI";
import { useUserChoice } from "../hook/useUserChoice";
import TextArea from "./TextArea";
import Spinner from "./Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import computeFocus from "../utils/computeFocus";

type Props = {
  company_content: CompanyContent;
  example_account: {
    displayed_name: string;
    picture_url: string;
    company: {
      email: string;
      company: string;
      phone: string;
      hashtag: string;
      website: string;
      displayed_name: string;
      client_code: string;
      last_landing_path: string;
      last_newsletter_path: string;
      last_apilink: string;
      promo_code: string;
      sponsor_link: string;
    };
  };
  channel_accounts: Array<ChannelAccount & { channel: Channel }>;
  periodicity_options: Record<string, string>;
  current_company: any;
};

export default function CompanyContentForm(props: Props) {
  const { current_company } = props;
  const [submitted, setSubmitted] = useState(false);
  const { body = { body_media: [] }, ...company_content } = props.company_content;
  console.log("company_content", company_content);
  const [prompt, setPrompt] = useState<string>("")
  const [loading, setLoading] = useState(false)
  const [showInputMessage, setShowInputMessage] = useState<boolean | null>(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      company_content: {
        ...company_content,
        body,
      },
    },
  });

  const [media, setMedia] = useState<MediaHolder[]>(
    body.body_media
      .filter((m: MediaHolder) => m.content_medium.serialized_file)
      .sort(
        (a: MediaHolder, b: MediaHolder) =>
          (a.position || 0) - (b.position || 0)
        // Si undefined on met la valeur max (media.length)
      ) || []
  );

  const ctaOptions = [
    { label: "Commander", value: "order_now" },
    { label: "Acheter", value: "shop_now" },
    { label: "En savoir plus", value: "learn_more" },
    { label: "S'inscrire", value: "sign_up" },
    { label: "Appeler", value: "call_now" },
    { label: "Nous contacter", value: "contact_us" },
  ];

  const graphQLClient = new GraphQLClient("/graphql/", {
    headers: { "X-CSRF-Token": getCSRFToken() },
  });

  const onSubmit = async (data: any) => {
    setSubmitted(true);

    const bodyMedia = formatMediaHolders(media);

    const mutation = gql`
      mutation ($companyContent: CompanyContent${company_content.id ? "Update" : ""
      }Input!) {
        ${company_content.id ? "update" : "create"
      }CompanyContent(input: { companyContent: $companyContent }) {
          companyContent {
            body {
              id
              message
              contentMedia {
                medium
              }
            }
          }
        }
      }
    `;

    const variables = {
      companyContent: {
        id: company_content.id || undefined,
        focus: data.company_content.focus,
        expiresAt: data.company_content.expires_at,
        title: data.company_content.title,
        body: {
          message: data.company_content.body.message,
          bodyMediaAttributes: bodyMedia,
          linkUrl: data.company_content.body?.link_url,
          linkDescription: data.company_content.body?.link_description,
          linkCta: data.company_content.body?.link_cta,
        },
      },
    };

    const response: any = await graphQLClient.request(mutation, variables);
    if (!response.errors) {
      window.Turbo?.visit(`/${intl.locale}/company_contents`);
    }
    setSubmitted(false);
  };

  useEffect(() => {
    console.log("errors", errors);
  });

  const message = watch("company_content.body.message");

  const link_description = watch("company_content.body.link_description");
  const previewCta = ctaOptions.find(
    (cta) => cta.value && cta.value === watch("company_content.body.link_cta")
  )?.label;

  const linkUrl = watch("company_content.body.link_url");

  const { focus, accept } = computeFocus(media.map(m => m.content_medium), linkUrl);

  const intl = useIntl();
  const { SubmitOpenAI, disabledInput, error } = useOpenAI(
    prompt,
    loading,
    setLoading,
    media.map((m) => m.content_medium),
    setValue,
    setShowInputMessage,
    getValues,
    "company_content.body.message",
    `/body/call_for_company_content`
  );

  const { handleAIButtonClick, handleManualButtonClick } = useUserChoice(setShowInputMessage);
  const [showIntegratedLink, setShowIntegratedLink] = useState(Boolean(linkUrl));
  const handleIntegratedLink = () => {
    if (["images", "video"].includes(focus) && !showIntegratedLink) return;
    if (showIntegratedLink) {
      setValue("company_content.body.link_url", null);
      setValue("company_content.body.link_description", null);
      setValue("company_content.body.link_cta", null);
    }
    setShowIntegratedLink(!showIntegratedLink);
  };
  useEffect(() => {
    if (["images", "video"].includes(focus)) {
      setShowIntegratedLink(false);
      setValue("company_content.body.link_url", null);
      setValue("company_content.body.link_description", null);
      setValue("company_content.body.link_cta", null);
    }
  }, [focus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        title={intl.formatMessage({
          id: "company_content_form.publication",
          defaultMessage: "Publication",
        })}
        description={intl.formatMessage({
          id: "company_content_form.edit_content",
          defaultMessage:
            "Éditez la forme et le contenu de votre nouvelle campagne",
        })}
        leftSideElements={
          <>
            <div>
              <FacebookPreview
                focus={focus}
                teaser={message}
                cta={previewCta}
                description={link_description || ""}
                reconstructed_url={linkUrl || ""}
                exampleAccount={props.example_account}
                media={media.filter((m: MediaHolder) => !(isPersisted(m) && m.destroy)).map((m) => m.content_medium)}
              />
            </div>
            <div>
              <UrlScraper
                creationUrl={"/contents"}
                redirectionCallback={(id) =>
                  `/company_contents/new?instantiate_from[type]=content&instantiate_from[id]=${id}`
                }
              />
            </div>
            <div className="hidden lg:block">
              <h3 className="flex items-baseline text-sm font-semibold leading-6 text-gray-700">
                <BoltIcon className="w-3 h-3 mr-1" />
                <FormattedMessage
                  id="company_content_form.tips"
                  defaultMessage="Astuce"
                />
              </h3>
              <p className="text-sm text-gray-500">
                <FormattedMessage
                  id="company_content_form.key_words"
                  defaultMessage="Utilisez les mots clés"
                />{" "}
                {[
                  "$NOM",
                  "$ENTREPRISE",
                  "$SITE",
                  "$MAIL",
                  "$TEL",
                  "$HASHTAG",
                  "$CODE",
                  "$LANDING",
                  "$NEWSLETTER",
                  "$APILINK",
                  "$PROMOCODE",
                  "$SPONSORLINK"
                ].map((v) => (
                  <span key={v}>
                    <span className="font-medium text-gray-700">{v}</span>
                    {", "}
                  </span>
                ))}
                <FormattedMessage
                  id="company_content_form.custom_your_publications"
                  defaultMessage="pour personnaliser vos publications avec les valeurs correspondantes chez chaque affilié."
                />
              </p>
            </div>
          </>
        }
      >
        <FormField
          title={intl.formatMessage({
            id: "company_content_form.media",
            defaultMessage: "Média",
          })}
          hint={intl.formatMessage({
            id: "company_content_form.media_hint",
            defaultMessage:
              "Ajoutez des images ou une vidéo à votre contenu. Le lien integré ne permet pas l'ajout de vidéo.",
          })}
        >
          <ContentMediaUploader
            inputProps={{
              multiple: true,
              accept,
            }}
            disabled={
              ["video", "link"].includes(focus) &&
              media.filter((m) => !m.destroy).length > 0
            }
            innerInfos={
              focus === "none" ? (
                <FormattedMessage
                  id="company_content_form.file_format_any"
                  defaultMessage="Insérez ici vos vidéos ou images"
                />
              ) : focus === "video" ? (
                <FormattedMessage
                  id="company_content_form.file_format_video"
                  defaultMessage="MP4, WEBM"
                />
              ) : (
                <FormattedMessage
                  id="company_content_form.file_format_image"
                  defaultMessage="JPG, PNG"
                />
              )
            }
            setMedia={(m) => {
              setMedia(m);
              setTimeout(() => {
                //trigger("company_content.focus");
              }, 500);
            }}
            media={media}
          />
        </FormField>
        <div>
          {
            !body?.message && current_company.plan !== "Ambassador" &&
            <div>
              <ChoiceButton
                showInputMessage={showInputMessage}
                handleAiButtonClick={handleAIButtonClick}
                handleManualButtonClick={handleManualButtonClick}
              />
            </div>
          }
        </div>
        {
          showInputMessage === true ?
            (
              <div>
                <OpenAiForm
                  prompt={prompt}
                  message="Entrez le sujet de votre article, donner des détails sur le contenu que vous souhaitez créer. (limite 30 par mois.)"
                  placeholder="Exemple: 'Rédigez un article sur les avantages de vivre à Paris 17ème.'"
                  setPrompt={setPrompt}
                  loading={disabledInput}
                  error={error}
                  Submit={SubmitOpenAI}
                />
              </div>
            ) : (
              <div>
                <FormField
                  title={intl.formatMessage({
                    id: "company_content_form.message",
                    defaultMessage: "Message",
                  })}
                  hint={intl.formatMessage({
                    id: "company_content_form.associated_text",
                    defaultMessage: "Le texte associé à votre contenu.",
                  })}
                  error={errors?.company_content?.body?.message as string | undefined}
                >
                  <InsertableTextInput
                    {...register("company_content.body.message", {
                      required: intl.formatMessage({
                        id: "company_content_form.message_cant_be_blank",
                        defaultMessage: "Le message ne peut pas être vide",
                      }),
                    })}
                    onInsertionCallback={() =>
                      isSubmitted ? trigger("company_content.body.message") : null
                    }
                    rows={5}
                    inputType="textarea"
                    emojis={true}
                    setValue={setValue}
                    insertionVariables={[
                      "$NOM",
                      "$ENTREPRISE",
                      "$SITE",
                      "$MAIL",
                      "$TEL",
                      "$HASHTAG",
                      "$CODE",
                      "$LANDING",
                      "$NEWSLETTER",
                      "$APILINK",
                      "$PROMOCODE",
                      "$SPONSORLINK"
                    ]}
                    className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.company_content?.body?.message
                      ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                      : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                      }`}
                  />
                </FormField>
              </div>
            )
        }
        <div className="relative flex items-start">
          <div className="flex items-center h-6">
            <input
              id="link"
              aria-describedby="link-toggle"
              name="link"
              type="checkbox"
              className="w-4 h-4 text-gray-600 border-gray-300 rounded focus:ring-gray-600"
              checked={showIntegratedLink}
              onChange={handleIntegratedLink}
              disabled={["video", "images"].includes(focus)}
            />
          </div>
          <div
            className={`w-full ml-3 text-sm leading-6 ${["video", "images"].includes(focus) && "opacity-50"
              }`}
          >
            <label className="block mb-1 text-base font-semibold text-gray-700">
              <FormattedMessage
                id="company_content_form.link"
                defaultMessage="Lien intégré"
              />
            </label>

            <div
              className={`w-full space-y-2 ${!showIntegratedLink && "hidden"}`}
            >
              <FormField
                disabled={["video", "images"].includes(focus)}
                error={
                  errors?.company_content?.body?.link_url?.message as
                  | string
                  | undefined
                }
              >
                <InsertableTextInput
                  {...register("company_content.body.link_url", {
                    validate: (value) => {
                      if (!value && showIntegratedLink) {
                        return intl.formatMessage({
                          id: "company_content_form.link_cant_be_blank",
                          defaultMessage:
                            "L'url est obligatoire pour le lien intégré.",
                        });
                      } else {
                        return true;
                      }
                    },
                  })}
                  disabled={["video", "images"].includes(focus)}
                  emojis={false}
                  setValue={setValue}
                  placeholder={intl.formatMessage({
                    id: "company_content_form.link_url",
                    defaultMessage: "Url du lien",
                  })}
                  insertionVariables={["$SITE", "$CODE", " $TEL", "$SPONSORLINK"]}
                  className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.company_content?.body?.link_url
                    ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                    : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                    }`}
                />
              </FormField>
              <div className="flex flex-row justify-between space-x-2">
                <div className="flex-1">
                  <TextArea
                    placeholder={intl.formatMessage({
                      id: "company_content_form.short_description",
                      defaultMessage: "Description du lien",
                    })}
                    rows={1}
                    {...register("company_content.body.link_description")}
                    error={errors?.company_content?.body?.link_description}
                  />
                </div>
                <FormField>
                  <Controller
                    name="company_content.body.link_cta"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeHolder={intl.formatMessage({
                          id: "company_content_form.action_btn",
                          defaultMessage: "Bouton d'action",
                        })}
                        onChange={(option) => field.onChange(option)}
                        list={ctaOptions}
                        value={field.value ? field.value : ""}
                      />
                    )}
                  />
                </FormField>
              </div>
            </div>

            <p id="link-hint" className="text-gray-500">
              <FormattedMessage
                id="company_content_form.link_hint"
                defaultMessage="Ajouter un lien décoré à votre contenu. Incompatible avec les vidéos ou images multiples."
              />
            </p>
          </div>
        </div>
      </FormLayout>

      <div className="flex justify-end mb-16">
        <button
          disabled={submitted}
          className="relative btn-brand-primary"
          type="submit"
        >
          <Spinner
            className={`absolute ${!submitted && "invisible"
              } w-5 h-5 mb-1 ml-4 text-white animate-spin`}
          />
          <span className={` ${submitted && "invisible"}`}>
            <FormattedMessage
              id="company_content_form.save"
              defaultMessage="Enregistrer"
            />
          </span>
        </button>
      </div>
    </form>
  );
}
