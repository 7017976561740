import baseFlatpickrController from "./flatpickr_controller";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect.js";

// Ce qu'on fait ici : on étend le contrôleur de base pour ajouter le plugin weekSelect

export default class extends baseFlatpickrController {
  initialize() {
    super.initialize();
    this.config.plugins = [new weekSelect({})];
    this.config.onChange = [
      function () {
        // on envoie le lundi de la semaine sélectionnée, la semaine commencera avec lui
        var date = this.selectedDates[0];
        date.setDate(
          date.getDate() - ((date.getDay() + 6) % 7)
        );
        console.log(date);
        this.setDate([date]);
      },
    ];
  }
}
