import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import Spinner from "./Spinner";
// import { FiX } from 'react-icons/fi';

interface PlaylistEnum {
  [key: string]: string;
}

const SoundSelect: React.FC = () => {
  const [selectedPlaylist, setSelectedPlaylist] = useState("9821");
  const [selectedSound, setSelectedSound] = useState({ title: "", url: "" });
  const [audioFilesWithTitles, setAudioFilesWithTitles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const playlistMap: PlaylistEnum = {
    "The Vocal Vault": "9821",
    "Epic Score": "97299",
    "Adrenaline Junkie": "97298",
    Quirky: "9",
    "Young and Free": "868",
    "Rock Rock Rock": "8006",
    "Holiday Vocals": "76589",
    "Fall Vibes": "75",
    Chill: "7457",
    "Indie Radio": "73",
  };

  const apiKey =
    "xzSFvjWiBLTL31hp48yeT0ccafhhDbuBHoREhUUzm7dX3w8nDjRylyT4N86Zc6mG";

  const fetchAudioFiles = async (playlistId: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.soundstripe.com/v1/playlists/${playlistId}?include=songs.audio_files&page[size]=100&page[number]=1`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch audio files");
      }

      const data = await response.json();
      const songsData = data.included.filter(
        (item: any) => item.type === "songs"
      );
      const audioFilesData = data.included.filter(
        (item: any) => item.type === "audio_files"
      );

      const audioFilesWithTitles = audioFilesData.map((audioFile: any) => {
        const song = songsData.find(
          (song: any) => song.id === audioFile.attributes.song_id
        );
        return {
          ...audioFile,
          songTitle: song ? song.attributes.title : "Unknown Title",
        };
      });

      setAudioFilesWithTitles(audioFilesWithTitles);
    } catch (error) {
      setError("Error loading audio files");
      console.error("Error fetching audio files:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAudioFiles("9821");
  }, []);

  const handlePlaylistSelect = (playlistName: string) => {
    const playlistId = playlistMap[playlistName];
    setSelectedPlaylist(playlistId);
    fetchAudioFiles(playlistId);
  };

  const handleAudioSelect = (audioFile: any) => {
    console.log("Selected audio file:", audioFile);
    setSelectedSound({
      title: audioFile.songTitle,
      url: audioFile.attributes.versions.mp3,
    });

    // Update the hidden field with the selected sound URL
    const hiddenField = document.getElementById(
      "songs-url-hidden-field"
    ) as HTMLInputElement;
    if (hiddenField) {
      hiddenField.value = audioFile.attributes.versions.mp3;
    }

    setOpen(false);
  };

  return (
    <div className="p-4 w-fit">
      <div className="mb-8 flex gap-16">
        <button
          type="button" // Prevent form submission
          onClick={() => setOpen(true)}
          className="px-4 py-2 bg-brand_main text-white rounded hover:bg-brand_focus transition duration-150">
          {selectedSound?.title !== ""
            ? "Changer de son"
            : "Sélectionner un son"}
        </button>
        {selectedSound.title !== "" && (
          <h1 className="my-4 text-xl font-medium text-gray-700 w-fit">
            Titre selectionnée: {selectedSound?.title}
          </h1>
        )}
      </div>

      <Modal open={open} setOpen={setOpen}>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-[1000px] h-[600px] relative">
          {/* Close Button */}
          <button
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            onClick={() => setOpen(false)}>
            {/* <FiX size={24} /> */}
          </button>

          <div className="flex h-full">
            <div className="w-1/3 p-4 bg-gray-100 overflow-y-auto">
              <h2 className="font-semibold mb-4">Playlists</h2>
              {Object.keys(playlistMap).map((playlistName) => (
                <button
                  key={playlistName}
                  onClick={() => handlePlaylistSelect(playlistName)}
                  className={`block px-4 py-2 rounded hover:bg-gray-200 ${
                    selectedPlaylist === playlistMap[playlistName]
                      ? "bg-gray-300"
                      : ""
                  }`}>
                  {playlistName}
                </button>
              ))}
            </div>
            <div className="w-2/3 p-4 overflow-y-auto flex items-center justify-center">
              {loading ? (
                <Spinner className="w-20 text-brand_main animate-spin" />
              ) : error ? (
                <p>{error}</p>
              ) : audioFilesWithTitles.length > 0 ? (
                <ul className="space-y-2 max-h-[500px] overflow-y-auto w-full">
                  {audioFilesWithTitles.map((audioFile) => (
                    <li
                      key={audioFile.id}
                      className="flex flex-col justify-between gap-8 w-full">
                      <div className="flex justify-start items-center gap-4 w-[300px]">
                        <p className="my-4 text-left text-xl font-medium text-gray-700">
                          {audioFile.songTitle}
                        </p>{" "}
                        {/* Song Title */}
                        <button
                          type="button" // Prevent form submission
                          onClick={() => handleAudioSelect(audioFile)}
                          className="btn-brand-primary inline-block bg-indigo-600 text-white font-medium rounded hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300">
                          Sélectionner
                        </button>
                      </div>
                      <audio controls className="mt-2 w-full">
                        <source
                          src={audioFile.attributes.versions.mp3}
                          type="audio/mp3"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No audio files available for this playlist.</p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SoundSelect;
