import { Controller } from "@hotwired/stimulus"
import CSVBoxImporter from '@csvbox/csvboxjs';
import { getCSRFToken } from "../components/utils";


export default class CsvBox extends Controller {

  static values = {
    formatterFn: String,
    endpoint: String,
    key: String,
    userId: String,
    dynamicColumns: Array,
    method: String
  }

  connect() {
    this.importer = new CSVBoxImporter(this.keyValue, {}, this.callback.bind(this))
    this.importer.setUser({ user_id: this.userId })
    if (this.dynamicColumnsValue) this.importer.setDynamicColumns(this.dynamicColumnsValue)
    this.element.onclick = () => { console.log("click", this.importer.openModal()) }
  }

  get formatter() {
    return window[this.formatterFnValue]
  }


  get csrfToken() {
    return getCSRFToken()
  }

  get request() {
    const xhr = new XMLHttpRequest();
    xhr.open(this.methodValue || "POST", this.endpointValue, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("X-CSRF-Token", this.csrfToken);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    xhr.onreadystatechange = (e) => (xhr.readyState == 4 && xhr.status == 200) ? location.reload() : console.log(e)
    return xhr
  }

  callback(result, data) {
    if (!result) return
    const content = JSON.stringify(this.format(data))
    this.request.send(content)
  }

  format(data) {
    if (this.formatter && typeof (this.formatter) == "function") {
      return this.formatter(data)
    } else {
      return data
    }
  }


}
