import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["hidden", "visible", "formField"]
  connect() {
    console.log("HEYY")
    this.synchronize()
  }

  wrapLogicalSyntax(text) {
    const wrapLogic = (text) => `<span class=" text-blue-400">${text}</span>`
    const wrapVariable = (variable) => `<span class=" text-emerald-400">${variable}</span>`
    const logicRegex = /\?\{\((.*?)\)(.*?)\}/g;
    const variableRegex = /\$(\b[A-Z_]+\b)/g;
    const aleaRegex = /ALEA\{(.*?)\}/g;
    let wrappedText = text.replace(logicRegex, (match, condition, normalText) => {
      return wrapLogic(`?{(${condition})`) + normalText + wrapLogic('}');
    });
    wrappedText = wrappedText.replace(variableRegex, (match, varName) => {
      return wrapVariable(`$${varName}`);
    });
    wrappedText = wrappedText.replace(aleaRegex, (match, content) => {
      const wrappedContent = content.replace(/\|/g, wrapLogic('|'));
      return wrapLogic('ALEA{') + wrappedContent + wrapLogic('}');
    });
    return wrappedText;
  }

  synchronize() {
    console.log("Sync")
    this.visibleTarget.innerHTML = this.wrapLogicalSyntax(this.hiddenTarget.innerHTML);
  };

  decodeHTML() {
    console.log("Correct")
    let content = this.hiddenTarget.innerHTML
      .replace(/<div>/g, '\n')
      .replace(/<br>/g, '\n')
      .replace(/<[^>]*>?/gm, '')
      .replace('\n\n\n', '\n\n');
    var tempElement = document.createElement('textarea');
    tempElement.innerHTML = content;
    this.formFieldTarget.value = tempElement.value;
  }

  scrollMirror() {
    this.visibleTarget.scrollTo(this.hiddenTarget.scrollLeft, this.hiddenTarget.scrollTop);
  };

  handleKey(ev) {
    if (ev.key === "Enter" && !ev.shiftKey) {
      // prevent Enter key behavior (new-line)
      ev.preventDefault();

      // Check message to send...
      const message = this.hiddenTarget.innerHTML;
      if (!message.trim()) {
        return; // Nothing to submit. Message is empty
      }

      // YOUR SUBMIT LOGIC GOES HERE:
      console.log(message);

      // Clear fields:
      this.hiddenTarget.innerHTML = "";
      this.visibleTarget.innerHTML = "";
    } else {
      // Any other key: (scroll areas is necessary)
      scrollMirror(this.hiddenTarget, this.visibleTarget);
    }
  };
}
