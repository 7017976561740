import React, { ReactNode } from "react";

type FieldProps = {
  title?: string;
  hint?: string;
  children: ReactNode;
  disabled?: boolean;
  error?: string;
};
const FormField = ({
  title,
  hint,
  children,
  disabled = false,
  error,
}: FieldProps) => (
  <div
    className={`input-root col-span-3 sm:col-span-2 ${disabled ? "opacity-50" : ""
      }`}
  >
    {title && (
      <label className="block mb-1 text-base font-semibold text-gray-700">
        {title}
      </label>
    )}
    <div className="relative">{children}</div>
    {typeof error === "string" ? (
      <small className="mt-2 text-sm text-red-500">{error}</small>
    ) : (
      hint && <small className="mt-2 text-sm text-gray-500">{hint}</small>
    )}
  </div>
);

export default FormField;
