import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { Dialog } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from "react-image-crop";

import {
  CloudinaryFile,
  NewCloudinaryFile,
} from "../types/cloudinary_custom_types";
import Modal from "./Modal";
import useCloudinaryUpload from "./useCloudinaryUpload";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { crop as cropFn, scale } from "@cloudinary/url-gen/actions/resize";
import { Company } from "../types";
import { FormattedMessage } from "react-intl";

type Props = {
  profilePicture: Company["profile_picture"];
  setProfilePicture: (pp: Company["profile_picture"]) => void;
};
function ProfilePictureEditor({ profilePicture, setProfilePicture }: Props) {
  const [open, setOpen] = useState(false);
  const didMountRef = useRef(false); // pour garder le first render de côté ( reset du crop )
  const imgRef = useRef<HTMLImageElement>();
  const [workingCopy, setWorkingCopy] = useState<CloudinaryFile | null>(
    profilePicture?.serialized_file || null
  );

  // On initie avec un crop en px, on le convertira en % dès que l'image sera chargé
  // Mais ça nous sert à différentier le premier rendu des reset
  const [crop, setCrop] = useState<Crop | undefined>(
    profilePicture
      ? {
          unit: "px",
          width: profilePicture.crop_width,
          height: profilePicture.crop_height,
          x: profilePicture.crop_x,
          y: profilePicture.crop_y,
        }
      : undefined
  );

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dcmekntwa",
    },
  });
  const addMedia = (file: NewCloudinaryFile) => setWorkingCopy(file);
  const { uploadFile, statusText } = useCloudinaryUpload({ cld, addMedia });

  const handleFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      for (var i = 0; i < files.length; i++) {
        uploadFile(files[i]); // call the function to upload the file
      }
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (crop && imgRef?.current && profilePicture) {
      // Première apparition : crop existe (en px)
      const { naturalWidth: width, naturalHeight: height } = imgRef.current;
      const percentCrop = pxToPercent({ crop: crop as Crop, height, width });
      setCrop(percentCrop);
    } else if (!crop) {
      // Réapparition : crop a été reset
      resetCropMask();
    }
  }

  const resetCropMask = () => {
    // On se fait un petit crop lambda pour la nouvelle image
    if (imgRef?.current) {
      const { naturalWidth: width, naturalHeight: height } = imgRef.current;
      const defaultCrop = centerCrop(
        makeAspectCrop({ unit: "%", width: 90 }, 1, width, height),
        width,
        height
      );
      setCrop(defaultCrop);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      // On a déjà rendu une fois, quand on change d'image, le crop se reset
      setCrop(undefined);
    }
    // premier rendu : on le signale
    didMountRef.current = true;
  }, [workingCopy]);

  const validateCrop = () => {
    if (imgRef?.current && crop) {
      const { naturalWidth: width, naturalHeight: height } = imgRef.current;
      const pxCrop = percentToPx({ crop, height, width });
      if (pxCrop && workingCopy) {
        setProfilePicture({
          ...profilePicture,
          serialized_file: workingCopy,
          crop_width: Math.floor(pxCrop.width),
          crop_height: Math.floor(pxCrop.height),
          crop_x: Math.floor(pxCrop?.x),
          crop_y: Math.floor(pxCrop?.y),
        });
      }
    }
  };

  const percentToPx = ({
    crop,
    height,
    width,
  }: {
    crop: Crop;
    height: number;
    width: number;
  }): Crop => {
    if (crop.unit === "%") {
      return {
        unit: "px",
        width: Math.floor((crop.width / 100) * width),
        height: Math.floor((crop.height / 100) * height),
        x: Math.floor((crop.x / 100) * width),
        y: Math.floor((crop.y / 100) * height),
      };
    }
    return crop;
  };

  const pxToPercent = ({
    crop,
    height,
    width,
  }: {
    crop: Crop;
    height: number;
    width: number;
  }): Crop => {
    if (crop.unit === "px") {
      return {
        unit: "%",
        width: Math.floor((crop.width / width) * 100),
        height: Math.floor((crop.height / height) * 100),
        x: Math.floor((crop.x / width) * 100),
        y: Math.floor((crop.y / height) * 100),
      };
    }
    return crop;
  };

  return (
    <div className="col-span-full">
      <div className="mt-2 flex items-center flex-col gap-x-3">
        {profilePicture ? (
          <AdvancedImage
            className="object-cover h-40 w-40 rounded-full"
            cldImg={cld
              .image(profilePicture?.serialized_file?.public_id)
              .resize(
                cropFn()
                  .width(profilePicture?.crop_width)
                  .height(profilePicture?.crop_height)
                  .x(profilePicture?.crop_x)
                  .y(profilePicture?.crop_y)
              ).resize(scale().width(160).height(160))}
          />
        ) : (
          <UserCircleIcon
            className="h-40 w-40 text-gray-300"
            aria-hidden="true"
          />
        )}

        <button
          type="button"
          onClick={() => setOpen(true)}
          className="rounded-md mt-2 bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FormattedMessage id="porfile_picture_editor.modify" defaultMessage="Modifier" />
        </button>
        <Modal open={open} setOpen={setOpen}>
          <Dialog.Panel className="space-y-5 relative transform overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full h-3/5 sm:max-w-3xl sm:p-6">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              <FormattedMessage id="porfile_picture_editor.profile_picture" defaultMessage="Photo de profil" />
            </Dialog.Title>
            <div className="text-center overflow-scroll max-h-96 rounded-md bg-gray-300">
              {workingCopy && (
                <ReactCrop
                  aspect={1}
                  circularCrop={true}
                  crop={crop}
                  onChange={(px, perc) => setCrop(perc)}
                  keepSelection
                >
                  <img
                    src={cld.image(workingCopy.public_id).toURL()}
                    onLoad={onImageLoad}
                    ref={imgRef}
                  />
                </ReactCrop>
              )}
            </div>
            {statusText && (
              <div className="mt-2 text-center">
                <p className="text-sm text-gray-500">{statusText}</p>
              </div>
            )}
            <div className="flex flex-row justify-between">
              <label className="cursor-pointer inline-flex  justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                <PhotoIcon className="h-6 w-6 mr-1" />
                  <FormattedMessage id="porfile_picture_editor.import_picture" defaultMessage="Importer une image" />
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFiles}
                  multiple={true}
                />
              </label>
              <button
                type="button"
                className="btn-brand-primary"
                onClick={() => {
                  validateCrop();
                  setOpen(false);
                }}
              >
                <FormattedMessage id="porfile_picture_editor.validate" defaultMessage="Valider" />
              </button>
            </div>
          </Dialog.Panel>
        </Modal>
      </div>
    </div>
  );
}

export default ProfilePictureEditor;
