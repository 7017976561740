import React from "react";
import DisplayMedia from "../_DisplayMedia";
import CancelSave from "../_CancelSave";
import LogoSettings from "../_LogoSettings";
import { LibraryImage, PixabayImage } from "../../types";

interface MediaLibraryTabProps {
  mediaLibrary: LibraryImage[] | null;
  loading: boolean;
  selectedImage: PixabayImage | LibraryImage | null;
  setSelectedImage: React.Dispatch<
    React.SetStateAction<PixabayImage | LibraryImage | null>
  >;
  insertLogo: boolean;
  setInsertLogo: React.Dispatch<React.SetStateAction<boolean>>;
  logoPosition: string;
  setLogoPosition: React.Dispatch<React.SetStateAction<string>>;
  logoShape: "rounded-square" | "circle";
  setLogoShape: React.Dispatch<
    React.SetStateAction<"rounded-square" | "circle">
  >;
  selectedLogo: string;
  setSelectedLogo: React.Dispatch<React.SetStateAction<string>>;
  isSaving: boolean;
  uploadSelectedImage: () => Promise<void>;
  onImageSelect: React.Dispatch<React.SetStateAction<boolean>>;
  profile_picture: string;
  getPositionClasses: (position: string) => string;
  getShapeClasses: (shape: string) => string;
}

const MediaLibraryTab: React.FC<MediaLibraryTabProps> = ({
  mediaLibrary,
  loading,
  selectedImage,
  setSelectedImage,
  insertLogo,
  setInsertLogo,
  logoPosition,
  setLogoPosition,
  logoShape,
  setLogoShape,
  selectedLogo,
  setSelectedLogo,
  isSaving,
  uploadSelectedImage,
  onImageSelect,
  profile_picture,
  getPositionClasses,
  getShapeClasses,
}) => {
  return (
    <>
      <div className="flex">
        <LogoSettings
          profile_picture={profile_picture}
          insertLogo={insertLogo}
          setInsertLogo={setInsertLogo}
          logoPosition={logoPosition}
          setLogoPosition={setLogoPosition}
          selectedLogo={selectedLogo}
          setSelectedLogo={setSelectedLogo}
          logoShape={logoShape}
          setLogoShape={setLogoShape}
          isSaving={isSaving}
        />
      </div>
      {mediaLibrary ? (
        <div className="relative flex-1 overflow-y-auto">
          {loading && (
            <p className="absolute text-center">Chargement en cours</p>
          )}
          <div className="my-4 columns-2 sm:columns-3 md:columns-4 space-y-3 gap-3">
            {mediaLibrary.map((imageLibrairy, idx) => (
              <DisplayMedia
                key={idx}
                image={imageLibrairy}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                insertLogo={insertLogo}
                profile_picture={profile_picture}
                logoPosition={logoPosition}
                logoShape={logoShape}
                getPositionClasses={getPositionClasses}
                getShapeClasses={getShapeClasses}
                pixabay={false}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex overflow-y-auto w-full h-96 min-h-96 max-h-96">
          <div className="p-12">
            <span>Vous n'avez pas de média dans votre bibliothèque</span>
          </div>
        </div>
      )}
      {mediaLibrary && mediaLibrary.length > 0 && (
        <CancelSave
          isSaving={isSaving}
          selectedImage={selectedImage}
          onImageSelect={onImageSelect}
          uploadSelectedImage={uploadSelectedImage}
        />
      )}
    </>
  );
};

export default MediaLibraryTab;
