import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["results", "loadMoreButton", "step1", "step2"];

  connect() {
    this.selectedContentIds = JSON.parse(this.data.get("selectedIds")) || [];
    console.log(this.selectedContentIds);
    console.log('Mode:', this.data.get('mode'));
  }

  goToStep2(event) {
    event.preventDefault();
    this.step1Target.style.display = 'none';
    this.step2Target.style.display = 'block';
    this.updateCheckboxStates();
  }

  returnStep1(event) {
    event.preventDefault();
    this.step1Target.style.display = 'block';
    this.step2Target.style.display = 'none';
  }

  loadMore(event) {
    event.preventDefault();
    const nextPage = this.loadMoreButtonTarget.dataset.nextPage;
    if (!nextPage) return;

    const mode = this.data.get('mode');
    const companyId = this.data.get('companyId');
    const newsletterId = this.data.get('newsletterId');

    let url;
    if (mode === 'new') {
      url = `/dashboard/companies/${companyId}/newsletters/new_infinite_scroll_suggested_contents?page=${nextPage}`;
    } else {
      url = `/dashboard/companies/${companyId}/newsletters/${newsletterId}/infinite_scroll_suggested_contents?page=${nextPage}`;
    }

    fetch(url, { headers: { 'Accept': 'text/vnd.turbo-stream.html' } })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.insertAdjacentHTML('beforeend', html);
        this.updateCheckboxStates();
        this.loadMoreButtonTarget.dataset.nextPage = parseInt(nextPage, 10) + 1;
      })
      .catch(error => console.error('Error:', error));
  }

  updateSelectedContentIds(event) {
    const checkbox = event.target;
    const contentId = parseInt(checkbox.value, 10);

    if (isNaN(contentId) || contentId <= 0) {
      return;
    }

    if (checkbox.checked) {
      if (this.selectedContentIds.length >= 3) {
        alert('Vous ne pouvez pas sélectionner plus de trois contenus');
        checkbox.checked = false;
        return;
      }
      this.selectedContentIds.push(contentId);
    } else {
      const index = this.selectedContentIds.indexOf(contentId);
      if (index > -1) {
        this.selectedContentIds.splice(index, 1);
      }
    }
    this.updateCheckboxStates();
  }

  updateCheckboxStates() {
    this.resultsTarget.querySelectorAll('input[name="newsletter[company_content_ids][]"]').forEach(checkbox => {
      const contentId = parseInt(checkbox.value, 10);
      const parentDiv = checkbox.closest('div');
      if (this.selectedContentIds.includes(contentId)) {
        checkbox.checked = true;
        parentDiv.classList.remove('border-border-brand_focus');
        parentDiv.classList.add('border-gray-500', 'shadow-2xl');
      } else {
        checkbox.checked = false;
        parentDiv.classList.remove('border-gray-500', 'shadow-2xl');
        parentDiv.classList.add('border-border-brand_focus');
      }
    });
  }

  submitForm(event) {
    event.preventDefault();
    const form = document.getElementById('form');
    const existingHiddenInputs = document.querySelectorAll('input[type="hidden"][name="newsletter[company_content_ids][]"]');
    existingHiddenInputs.forEach(input => input.remove());

    this.selectedContentIds.forEach(id => {
      if (id) {
        const hiddenInput = document.createElement('input');
        hiddenInput.type = 'hidden';
        hiddenInput.name = 'newsletter[company_content_ids][]';
        hiddenInput.value = id;
        form.appendChild(hiddenInput);
      }
    });

    form.submit();
  }

  async performSearch(event) {
    event.preventDefault();
    const query = document.getElementById('searchQuery').value;
    const token = document.querySelector('meta[name="csrf-token"]').content;
    if (!query.trim()) return;
    const url = `/newsletters/search`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ query })
    });

    if (response.ok) {
      const html = await response.text();
      this.resultsTarget.innerHTML = html;
      this.updateCheckboxStates();
    } else {
      console.error('Search request failed', response);
    }
  }
}
