import React from "react";
import Spinner from "./Spinner"; // Assurez-vous que vous avez un composant Spinner pour l'animation

const CancelSave = ({
  isSaving,
  selectedImage,
  onImageSelect,
  uploadSelectedImage,
}) => {
  return (
    <div className="flex justify-between gap-4 w-full py-4">
      <button
        onClick={() => !isSaving && onImageSelect(false)}
        className="btn-neutral"
      >
        Annuler
      </button>

      <button
        onClick={uploadSelectedImage}
        className={`py-2 rounded w-1/4 justify-center items-center
          ${
            selectedImage
              ? "btn-brand-primary"
              : "bg-gray-200 text-black font-medium text-sm shadow-sm"
          } 
          ${isSaving ? "cursor-not-allowed opacity-50" : ""} w-auto px-5`}
        disabled={isSaving || !selectedImage}
      >
        Enregistrer
      </button>
    </div>
  );
};

export default CancelSave;
