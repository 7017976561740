import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "targetList", 
    "previewObject", 
    "previewBody", 
    "recipientsInput", 
    "targetEmail", 
    "targetName", 
    "addTargetForm", 
    "sendMailForm"
  ]

  connect() {
    this.recipients = []
    this.resetPreview()
  }

  addRecipient(event) {
    event.preventDefault()
    const email = this.targetEmailTarget.value.trim()
    const name = this.targetNameTarget.value.trim()

    if (email && name) {
      this.recipients.push({ email: email, nom: name })

      const listItem = document.createElement('li')
      listItem.classList.add('flex', 'justify-between', 'items-center', 'p-2', 'bg-white', 'shadow', 'rounded')
      listItem.innerHTML = `
        <span>${name} &lt;${email}&gt;</span>
        <button type="button" data-action="click->mail-templates#removeTarget" class="remove-target text-red-500">Supprimer</button>
      `
      this.targetListTarget.appendChild(listItem)

      this.addTargetFormTarget.reset()
      this.resetPreview()
    }
  }

  removeTarget(event) {
    const listItem = event.target.closest('li')
    const index = Array.from(this.targetListTarget.children).indexOf(listItem)
    this.recipients.splice(index, 1)
    listItem.remove()
    this.resetPreview()
  }

  sendMail(event) {
    if (this.recipients.length === 0) {
      event.preventDefault()
      alert('Veuillez ajouter au moins un destinataire.')
      return
    }
    this.recipientsInputTarget.value = JSON.stringify(this.recipients)
  }

  resetPreview() {
    const objectTemplate = JSON.parse(this.data.get("objectTemplate"))
    const bodyTemplate = JSON.parse(this.data.get("bodyTemplate"))

    this.previewObjectTarget.textContent = objectTemplate.replace(/\$NOM/g, '$NOM')
    this.previewBodyTarget.textContent = bodyTemplate.replace(/\$NOM/g, '$NOM')
  }
}