import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    linkColor: String,
    creationEndpoint: String,
  };

  connect() {
    this.initializeWidget();
  }

  async initializeWidget() {
    const timestamp = Math.floor(Date.now() / 1000);
    const signature = await this.generateSignature(timestamp);
    this.createWidget(timestamp, signature);
  }

  async generateSignature(timestamp) {
    try {
      const response = await fetch(
        `/api/sign_cloudinary_upload?timestamp=${timestamp}&source=uw`
      );
      const { signature } = await response.json();
      return signature;
    } catch (err) {
      console.error("Failed to generate signature:", err);
    }
  }

  createWidget(timestamp, signature) {
    const myWidget = cloudinary.createUploadWidget(
      {
        cloudName: "dcmekntwa",
        apiKey: "883898914556696",
        uploadSignatureTimestamp: timestamp,
        uploadSignature: signature,
        resourceType: "auto",
        multiple: true,
        maxFileSize: 10485760, // 10 MB
        cropping: false,
        showPoweredBy: false,
        clientAllowedFormats: ["jpg", "png", "mp4"],
        theme: "minimal",
        autoMinimize: true,
        sources: ["local"],
        language: "en",
        text: {
          en: {
            menu: {
              files: "Mes fichiers",
            },
            or: "Glisser/déposer vos fichiers ici",
            back: "Retour",
            advanced: "Avancé",
            close: "Fermer",
            no_results: "No results",
            search_placeholder: "Search files",
            about_uw: "About the Upload Widget",
            search: {
              placeholder: "Search...",
              reset: "Reset search",
            },
            local: {
              browse: "Télécharger",
              dd_title_single: "",
              dd_title_multi: "",
              drop_title_single: "Drop a file to upload",
              drop_title_multiple: "Drop files to upload",
            },
            queue: {
              title: "File de téléchargement",
              title_upload: "Téléchargement en cours...",
              title_uploading_with_counter: "Chargement de {{num}} fichiers",
              title_processing_with_counter: "Chargement de {{num}} fichiers",
              title_uploading_processing_with_counters:
                "Chargement {{uploading}} fichiers, processing {{processing}} fichiers",
              title_failed: "Échec du téléchargement",
              upload_more: "Télécharger plus",
              done: "Enregistrer",
              abort_all: "Annuler tout",
              mini_title: "Téléchargés",
              mini_title_uploading: "Chargement",
              mini_title_processing: "Chargement...",
              mini_title: "Fichier téléchargé",
              mini_upload_count: "{{num}} fichiers téléchargés",
              statuses: {
                uploading: "Chargement",
                processing: "Chargement",
                timeout:
                  "Fichier volumineux en cours de chargement. Veuillez patienter.",
                error: "Erreur de chargement",
                uploaded: "Téléchargé",
                aborted: "Chargement annulé",
              },
            },
          },
        },
        styles: {
          palette: {
            window: "#f9fafb",
            windowBorder: "#e5e7eb",
            sourceBg: "#FFFFFF",
            tabIcon: "#334155",
            menuIcons: "#5A616A",
            textDark: "#334155",
            textLight: "#FFFFFF",
            link: this.linkColorValue,
            action: this.linkColorValue,
            inactiveTabIcon: "#334155",
            error: "#ef4444",
            inProgress: this.linkColorValue,
            complete: this.linkColorValue,
            sourceBg: "#FFF",
          },
          frame: {
            background: "#33415590",
          },
        },
      },
      (error, result) => {
        if (error) {
          console.error("Upload Error:", error);
          return;
        }
        if (result && result.event === "success") {
          this.handleUploadSuccess(result);
        }
      }
    );
    this.widget = myWidget;
  }

  open() {
    this.widget.open();
  }

  async handleUploadSuccess(result) {
    const { resource_type, type, version, public_id, format, signature } =
      result.info;
    const medium = `${resource_type}/${type}/v${version}/${public_id}.${format}#${signature}`;

    try {
      const response = await fetch(this.creationEndpointValue, {
        method: "POST",
        headers: {
          Accept: "text/vnd.turbo-stream.html, application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
        },
        body: JSON.stringify({ medium }),
      });

      if (
        response.headers
          .get("Content-Type")
          .includes("text/vnd.turbo-stream.html")
      ) {
        const html = await response.text();
        Turbo.renderStreamMessage(html);
      } else {
        await response.json();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
}
